import { ReactComponent as InfoIcon } from '@icons/info.svg'
import PropTypes from 'prop-types'

const RevenueComparisonCard = ({ title, totalValue, totalLabel, bsrValue, bsrLabel }) => {
  return (
    <div className='h-full py-4 px-6 bg-white rounded-lg border border-gray-200 relative flex flex-col justify-between'>
      <span className='cursor-pointer absolute top-4 right-6'><InfoIcon className='size-4'/></span>
      <div className='flex justify-between items-center'>
        <p className='text-sm text-gray-500 stretch-extra-expanded mb-5'>{title}</p>
      </div>

      <div className='flex justify-between items-center gap'>
        <div>
          <div className='bg-gray-100 py-1 px-6 border rounded-lg min-w-[134px] text-center'>
            <p className='text-2xl font-medium stretch-extra-expanded '>{totalValue}</p>
          </div>
          <p className='text-sm mt-1'>{totalLabel}</p>
        </div>

        <div>
          <div className='bg-gray-100 py-1 px-6 border rounded-lg min-w-[134px] text-center'>
            <p className='text-2xl font-medium stretch-extra-expanded'>{bsrValue}</p>
          </div>
          <p className='text-sm mt-1'>{bsrLabel}</p>
        </div>
      </div>
    </div>
  )
}

RevenueComparisonCard.propTypes = {
  title: PropTypes.string.isRequired,
  totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, 
  totalLabel: PropTypes.string.isRequired, 
  bsrValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, 
  bsrLabel: PropTypes.string.isRequired, 
}

export default RevenueComparisonCard
