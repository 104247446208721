import { useEffect, useState, useCallback } from 'react'

import { ReactComponent as IconAdd } from '@icons/plus-square.svg'
import axios from 'axios'
import { Button } from 'primereact/button'

import TableProjects from '../../table/Table'

const ListProjects = () => {
  const [projects, setProjects] = useState([])

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get('http://localhost:8000/wp-json/custom/v1/projects', {
        headers: {
          'X-WP-Nonce': window.wpApiSettings.nonce,
        },
        withCredentials: true,
      })

      setProjects(response.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const exampleProjects = [
    {
      id: 1,  
      name: 'EcoTrend_Fashion 2024',
      country: 'France',
      category: 'Skin Treatment',
      client: 'Loreal',
      brand: 'Loreal',
      platform: ['Amazon', 'Target', 'Carrefour'],
      social: ['Facebook', 'Instagram', 'Snapchat'],
    },
    {
      id: 2,
      name: 'PureSkin_Revive 2025',
      country: 'Germany',
      category: 'Hair Care',
      client: 'Pantene',
      brand: 'Pantene',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram'],
    },
    {
      id: 3,
      name: 'BeautyPlus_Makeup 2023',
      country: 'Spain',
      category: 'Makeup',
      client: 'NYX Cosmetic',
      brand: 'NYX Cosmetic',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram', 'Snapchat', 'Pinterest', 'TikTok'],
    },
    {
      id: 4,
      name: 'Fragrance_Trend 2022',
      country: 'United States',
      category: 'Perfume',
      client: 'Dior',
      brand: 'Dior',
      platform: ['Target', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Pinterest', 'TikTok'],
    },
    {
      id: 5,
      name: 'OralHealth_Care 2022',
      country: 'Canada',
      category: 'Oral Care',
      client: 'Lacer',
      brand: 'Lacer',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram', 'Snapchat', 'Pinterest', 'TikTok'],
    },
    {
      id: 6,
      name: 'EcoTrend_Fashion 2024',
      country: 'France',
      category: 'Skin Treatment',
      client: 'Loreal',
      brand: 'Loreal',
      platform: ['Amazon', 'Target', 'Carrefour'],
      social: ['Pinterest', 'TikTok'],
    },
    {
      id: 7,
      name: 'PureSkin_Revive 2025',
      country: 'Germany',
      category: 'Hair Care',
      client: 'Pantene',
      brand: 'Pantene',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Instagram', 'Snapchat', 'Pinterest', 'TikTok'],
    },
    {
      id: 8,
      name: 'BeautyPlus_Makeup 2023',
      country: 'Spain',
      category: 'Makeup',
      client: 'NYX Cosmetic',
      brand: 'NYX Cosmetic',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram', 'Snapchat', 'Pinterest'],
    },
    {
      id: 9,
      name: 'EcoTrend_Fashion 2024',
      country: 'France',
      category: 'Skin Treatment',
      client: 'Loreal',
      brand: 'Loreal',
      platform: ['Amazon', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram', 'Snapchat', 'Pinterest', 'TikTok'],
    },
    {
      id: 10,
      name: 'PureSkin_Revive 2025',
      country: 'Germany',
      category: 'Hair Care',
      client: 'Pantene',
      brand: 'Pantene',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram'],
    },
    {
      id: 11,
      name: 'BeautyPlus_Makeup 2023',
      country: 'Spain',
      category: 'Makeup',
      client: 'NYX Cosmetic',
      brand: 'NYX Cosmetic',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Instagram', 'Pinterest', 'TikTok'],
    },
    {
      id: 12,
      name: 'Fragrance_Trend 2022',
      country: 'United States',
      category: 'Perfume',
      client: 'Dior',
      brand: 'Dior',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram', 'Snapchat', 'Pinterest', 'TikTok'],
    },
    {
      id: 13,
      name: 'OralHealth_Care 2022',
      country: 'Canada',
      category: 'Oral Care',
      client: 'Lacer',
      brand: 'Lacer',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram'],
    },
    {
      id: 14,
      name: 'EcoTrend_Fashion 2024',
      country: 'France',
      category: 'Skin Treatment',
      client: 'Loreal',
      brand: 'Loreal',
      platform: ['Amazon', 'Target', 'Carrefour'],
      social: ['Pinterest', 'TikTok'],
    },
    {
      id: 15,
      name: 'PureSkin_Revive 2025',
      country: 'Germany',
      category: 'Hair Care',
      client: 'Pantene',
      brand: 'Pantene',
      platform: ['Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram', 'Snapchat', 'Pinterest', 'TikTok'],
    },
    {
      id: 16,
      name: 'BeautyPlus_Makeup 2023',
      country: 'Spain',
      category: 'Makeup',
      client: 'NYX Cosmetic',
      brand: 'NYX Cosmetic',
      platform: ['Amazon', 'Target', 'Walmart', 'Carrefour'],
      social: ['Facebook', 'Instagram', 'Snapchat'],
    }
  ]

  const handleDelete = useCallback(id => {
    setProjects(prevProjects => {
      const projectId = typeof id === 'string' ? parseInt(id, 10) : id
      
      return prevProjects.filter(project => project.id !== projectId)
    })
  }, [setProjects])

  return (
    <>
      <div className='container pt-6'>
        <div className='flex items-center justify-between mb-3'>
          <h1 className='text-3xl xl:text-[2rem] font-light'>Havas Market Forecast</h1>
          <Button
            icon={<IconAdd className='icon size-4' />}
            iconPos='right'
            label='Create new Project'
          />
        </div>
      </div>
      <TableProjects 
        projects={projects.length > 0 ? projects : exampleProjects} 
        onDelete={handleDelete}
      />
    </>
  )
}

export default ListProjects
