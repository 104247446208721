import { ReactComponent as Arrow } from '@icons/arrowUp.svg'

import RetailTable from '../commons/retailTable'
import PerformanceTurnoverChart from '../graphics/bars'
import CircleChart from '../graphics/circle'
import RadarChart from '../graphics/radar'

const Hide = () => {
  return (
    <div>
      <div className='flex flex-row'>
        <div className='flex flex-col'>
          <div className='flex flex-row gap-2 mr-2'>
            <div className='flex border border-gray-200 rounded-lg w-1/2 items-center'>
              <CircleChart scoreColor='#673ab7' scoreMax={10} scoreValue={3.2} />
            </div>
            <div className='border border-gray-200 rounded-lg h-[154px] w-1/2 p-5'>
              <h4 className='text-sm'>Estimated incremental Turn over</h4>
              <h3 className='text-5.5xl text-gold stretch-extra-expanded mt-2'> 13.7%</h3>
              <div className='flex flex-row mt-2 items-center'>
                <span className='flex items-center'>
                  <Arrow className='size-4'/>
                </span>
                <div className='bg-tourquise rounded-full px-2 text-xs align-center'>23.5%</div>
                <span className='flex text-xs font-light ml-1 items-center'>from last year</span>
              </div>
            </div>
          </div>
          <div className='border border-gray-200 rounded-lg mt-2 mr-2 p-4'>
            <h3 className='text-sm mb-3'> Weight por Platform in Overall growth Score</h3>
            <RetailTable />
          </div>
        </div>
        <div className='flex border border-gray-200 rounded-lg w-1/2 items-center'>
          <RadarChart />
        </div>
      </div>
      <div className='border border-gray-200 rounded-lg px-11 pt-8 pb-2 mt-2'>
        <PerformanceTurnoverChart />
      </div>
    </div>

  )
}

export default Hide