
import SectionIconSide from '@commons/section-side-icon'
import MenuResultsSettings from '@components/settings/layout/MenuResSet'
import DesignIcon from '@images/design.svg'
import { Link } from 'react-router-dom'

import BudgetExercise from './split/budgetExercise'
import RetailMediaBudget from './split/retailMediaBudget'

const RetailMediaBudgetSplit = id => {
  return (
    <div className='pb-20'>
      <MenuResultsSettings />
      <div className='container '>
        <div className='my-3'>
          <Link className='text-xs font-light' to={`/detail/${id}/results`}>Results <span className='mx-2'>/</span></Link><span className='text-xs font-medium'> Retail Media Budget Split</span>
        </div>
        <SectionIconSide
          description='Manage advertising campaigns in retail ecosystems to maximise media spend impact.'
          icon={DesignIcon}
          title='Retail Media Budget Split'
        >

          <RetailMediaBudget />



        </SectionIconSide>
        <div className='mt-4'>
          <BudgetExercise />
        </div>
      </div>
    </div>
  )
}

export default RetailMediaBudgetSplit
