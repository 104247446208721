import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import PropTypes from 'prop-types'
import 'primeicons/primeicons.css' 
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.css'

const PerformanceTable = ({ data, onPartnershipChange }) => {
  const partnershipOptions = [
    { label: 'Total Partnership', value: 'Total Partnership' },
    { label: 'Performance Partnership', value: 'Performance Partnership' },
    { label: 'Opportunity Partnership', value: 'Opportunity Partnership' }
  ]

  const partnershipBodyTemplate = rowData => {
    return (
      <Dropdown
        className='w-[9.75rem]'
        options={partnershipOptions}
        placeholder='Select a Partnership'
        size='xsmall'
        value={rowData.partnership}
        onChange={e => onPartnershipChange(rowData, e.value)}
      />
    )
  }

  const retailerBodyTemplate = rowData => {
    const colors = {
      Amazon: '#f28e2c',   
      Auchan: '#4c9f70',     
      Carrefour: '#4e79a7', 
      Eleclerc: '#76b7b2',  
    }

    return (
      <div className='flex items-center gap-2'>
        <span
          className='w-3 h-3 rounded-full'
          style={{ backgroundColor: colors[rowData.retailer] }}
        ></span>
        <span>{rowData.retailer}</span>
      </div>
    )
  }

  const weightedGradeBodyTemplate = rowData => {
    const grade = rowData.weightedGrade
    let bgColor = ''
    let textColor = 'text-black'

    if (grade >= 4) {
      bgColor = 'bg-lime'
    } else if (grade >= 2) {
      bgColor = 'bg-orange-palide'
    } else {
      bgColor = 'bg-red-palide' 
    }

    return (
      <span className={`px-3 py-2 rounded ${bgColor} ${textColor}`}>
        {grade}
      </span>
    )
  }

  return (
    <div>
      <DataTable 
        className='p-datatable-sm border-collapse border-none' 
        responsiveLayout='scroll' 
        size='small' 
        value={data}
      >
        <Column 
          body={retailerBodyTemplate} 
          bodyClassName='text-gray-900 p-3 rounded-s-lg' 
          field='retailer' 
          header='Retailer' 
          headerClassName='border-0 p-3' 
          style={{ width: '15%' }} 
        />

        <Column 
          body={weightedGradeBodyTemplate} 
          bodyClassName='p-3' 
          field='weightedGrade' 
          header='Weighted Grade'
          headerClassName='border-0 p-3'
          style={{ width: '10%' }}
        />

        <Column 
          body={partnershipBodyTemplate} 
          bodyClassName='p-3' 
          field='partnership'
          header='Partnership'
          headerClassName='border-0 p-3'
          style={{ width: '20%' }}
        />

        <Column bodyClassName='text-primary p-3' field='tierMultiplier' header='Tier Multiplier' headerClassName='border-0 p-3' style={{ width: '15%' }} />

        <Column bodyClassName='text-primary p-3' field='allocation' header='Allocation' headerClassName='border-0 p-3' style={{ width: '10%' }} />

        <Column bodyClassName='text-primary p-3' field='budget' header='Budget' headerClassName='border-0 p-3' style={{ width: '15%' }} />

        <Column 
          bodyClassName='text-primary p-3 rounded-e-lg' 
          field='proposed' 
          header='Proposed' 
          headerClassName='border-0 p-3' 
          style={{ width: '15%' }} 
        />
      </DataTable>
    </div>
  )
}

PerformanceTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      retailer: PropTypes.string.isRequired,       
      weightedGrade: PropTypes.number.isRequired,  
      partnership: PropTypes.string.isRequired,    
      tierMultiplier: PropTypes.oneOfType([        
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      allocation: PropTypes.string.isRequired,     
      budget: PropTypes.string.isRequired,        
      proposed: PropTypes.string.isRequired,        
    })
  ).isRequired,
  onPartnershipChange: PropTypes.func.isRequired,
}

export default PerformanceTable
