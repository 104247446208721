import CardButton from '@commons/CardButton'
import SectionIcon from '@commons/section-icon'
import MenuResultsSettings from '@components/settings/layout/MenuResSet'
import FileLineIcon from '@icons/file-line-chart.svg'
import ListIcon from '@icons/list-video.svg'
import MapIcon from '@icons/map.svg'
import PieChartIcon from '@icons/pie-chart.svg'
import PodcasIcon from '@icons/podcast.svg'
import SearchDocIcon from '@icons/search-doc.svg'
import ActiveIcon from '@images/activate.svg'
import DasboardImg from '@images/dashboard.jpg'
import DesignIcon from '@images/design.svg'
import IntelligenceIcon from '@images/intelligence.svg'
import MeasureIcon from '@images/measure.svg'

import KeywordRelevancyIndexResults from './results/intelligence/KeywordRelevancyIndexResults'


const Results = () => {

  return (
    <form>
      <MenuResultsSettings />
      <div className='container py-4'>
        <div className='flex flex-row gap-4 my-4 '>
          <SectionIcon
            description='Use cutting-edge tools and insights to monitor performance, refine, strategies, and drive continuous improvement, ensuring you stay ahead in a competitive market.'
            icon={IntelligenceIcon}
            title='Laverage data-driven Insights to optimize your performance.'
          >
            <div className='flex flex-row gap-2'>
              <CardButton
                date=' 10-04-2014'
                description='Optimize your keywords by evaluating their relevance across platforms to boost visibility.'
                hoverBgColor='pink-card'
                icon={PieChartIcon}
                title='Keyword Relevancy Index' 
                url='/detail/:id/results/intelligence/keyword-relevancy-index'/>
              <CardButton
                date=' 10-04-2014'
                description='Gain Insights into consumer behaviour and retail trends to improve your sales strategy.'
                hoverBgColor='pink-card'
                icon={FileLineIcon}
                title='Retail Insights' 
                url='/detail/:id/results/intelligence/retail-insights' />
            </div>
          </SectionIcon>
          <SectionIcon
            description='Access powerful analytics and reports to uncover key performance metrics and areas for improvementr, enabling smarter and better outcomes.'
            icon={MeasureIcon}
            title='Track,analyze, and refine your strategies with comprehesive metrics.'
          >
            <div className='flex flex-row gap-2'>
              <CardButton
                date=' 10-04-2014'
                description='Optimize your keywords by evaluating their relevance across platforms to boost visibility.'
                hoverBgColor='green-card'
                icon={SearchDocIcon}
                title='External link'
                url={KeywordRelevancyIndexResults} />
              <CardButton
                date=' 10-04-2014'
                description='Gain Insights into consumer behaviour and retail trends to improve your sales strategy'
                hoverBgColor='green-card'
                icon={PieChartIcon}
                title='PowerBI'
                url={KeywordRelevancyIndexResults}/>
            </div>
          </SectionIcon>
        </div>
        <div className='flex flex-row gap-4 my-4 '>
          <SectionIcon
            description='Craft intutive and engaging designs that prioritize user needs, enhancing costumer satisfaction and boosting conversion rates through seamless interactions.'
            icon={DesignIcon}
            title='Create user-centric shopping experiences to drive conversions.'
          >
            <div className='flex flex-row gap-2'>
              <CardButton
                date=' 10-04-2014'
                description='Identify areas for steady growth through optimisation and controlled expansion.'
                hoverBgColor='blue-card'
                icon={FileLineIcon}
                title='Incremental growth potential'
                url='/detail/:id/results/design/incremental-growth-potential'/>
              <CardButton
                date=' 10-04-2014'
                description='Plan and execute an efficient expansion strategy.'
                hoverBgColor='blue-card'
                icon={ListIcon}
                title='Category Revenue Benchmark'
                url='/detail/:id/results/design/market-place-expansion' />
            </div>
            <div className='flex flex-row gap-2 pt-4'>
              <CardButton
                date=' 10-04-2014'
                description='Create a plan to optimise processes for greater efficiency.'
                hoverBgColor='blue-card'
                icon={MapIcon}
                title='Non-Media optimisation roadmap'
                url='/detail/:id/results/design/non-media-optimisation-roadmap'/>
              <CardButton
                date=' 10-04-2014'
                description='Identify areas for steady growth thriugh optimisation and controlled expansion.'
                hoverBgColor='blue-card'
                icon={PodcasIcon}
                title='Retail Media Budget Split'
                url='/detail/:id/results/design/retail-media-budget-split'/>
            </div>
          </SectionIcon>
          <SectionIcon
            description='Deploy coordinated marketing and sales efforts across multiple platforms to maximize reach, engage costumers, and drive faster revenue growth.'
            icon={ActiveIcon}
            title='Implement strategic actions across platforms to accelerate sales.'
          >
            <div className='w-full rounded hover:border hover:border-black'>
              <img alt='Dashboard example img' className='rounded' src={DasboardImg} />
            </div>
          </SectionIcon>
        </div>
      </div>
    </form>
  )
}

export default Results
