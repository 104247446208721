import clsx from 'clsx'

const tree = {
  root: {
    className: clsx('w-full', 'text-gray-700'),
  },
  wrapper: 'overflow-auto',
  container: 'm-0 p-2 list-none overflow-auto',
  node: 'py-0.5 outline-none',
  content: ({ context, props }) => ({
    className: clsx(
      'flex items-center',
      'transition-shadow duration-200 text-sm',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      { 'bg-blue-50 text-blue-600': context.selected },
      { 'cursor-pointer select-none': props.selectionMode === 'single' || props.selectionMode === 'multiple' }
    ),
  }),
  toggler: ({ context }) => ({
    className: clsx(
      'cursor-pointer select-none inline-flex items-center justify-center overflow-hidden relative shrink-0',
      'mr-1 size-[18px] border-0 bg-transparent rounded-full transition duration-200 text-gray-500',
      'focus:outline-none focus:outline-offset-0',
      {
        hidden: context.leaf,
      }
    ),
  }),
  togglerIcon: 'size-3',
  checkboxcontainer: 'flex items-center justify-center size-6',
  checkbox: ({ context, props }) => ({
    className: clsx(
      'cursor-pointer inline-flex relative select-none align-bottom',
      'size-[18px]',
      'flex items-center justify-center',
      'border size-[18px] rounded transition-colors duration-200 text-gray-800 text-sm',
      {
        'border-gray-300 bg-white': !context.checked,
        'border-amber-200 text-gray-800 bg-amber-200': context.checked,
      },
      {
        'hover:border-amber-200 focus:outline-none focus:outline-offset-0': !props.disabled,
        'cursor-default opacity-60': props.disabled,
      }
    ),
  }),
  checkboxIcon: 'size-3',
  nodeicon: 'mr-2 text-gray-600',
  subgroup: {
    className: clsx('m-0 list-none', 'p-0 pl-4'),
  },
  filtercontainer: {
    className: clsx('mb-2', 'relative block w-full'),
  },
  input: {
    className: clsx(
      'm-0 px-3 py-2 text-base w-full pr-7',
      'font-sans text-sm text-gray-600 bg-white border border-gray-300 transition-colors duration-200 appearance-none rounded',
      'hover:border-gray-800 focus:outline-none focus:outline-offset-0'
    ),
  },
  searchicon: 'absolute top-1/2 -mt-2 right-3 text-gray-600',
}

export default tree
