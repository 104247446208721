
import clsx from 'clsx'

const TRANSITIONS = {
  overlay: {
    timeout: 150,
    classNames: {
      enter: 'opacity-0 scale-95',
      enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
      exit: 'opacity-100',
      exitActive: '!opacity-0 transition-opacity duration-150 ease-linear',
    },
  },
}

const multiselect = {
  root: ({ props }) => ({
    className: clsx(
      'items-center cursor-pointer inline-flex relative select-none',
      'transition-colors duration-200 ease-in-out',
      'w-full',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
      {
        'border border-custom-shadow-gray bg-transparent': !props.transparent && !props.invested,
        'border border-gray-800 bg-gray-800 hover:border-gray-800': !props.transparent && props.invested,
      },
      { 'h-9 xl:h-10 rounded-md': !props.size, 'h-7 rounded-sm text-primary': props.size === 'xsmall', 'h-8 rounded-md': props.size === 'small', 'h-14 rounded-md': props.size === 'large' }

    ),
  }),
  labelContainer: 'overflow-hidden flex flex-auto cursor-pointer',
  label: ({ props }) => ({
    className: clsx(
      'mx-3 overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis grow', 'transition duration-200',
      { 'text-sm px-3 py-0 h-10 leading-10': !props.size, 'text-xs py-0 px-3 h-8 leading-8': props.size === 'small', 'text-base px-8 py-0 h-14 leading-14': props.size === 'large' },
      { 'text-custom-ash-gray text-sm': !props.invested, 'text-white': props.invested }

      /*
       * {
       *   '!p-3': props.display !== 'chip' && (props.value === null || !props.value),
       *   '!py-1.5 px-3': props.display === 'chip' && props.value !== null,
       * }
       */
    ),
  }),
  token: {
    className: clsx('py-1 px-2 mr-2 bg-gray-300 text-gray-700 rounded-full', 'cursor-default inline-flex items-center'),
  },
  removeTokenIcon: 'ml-2',
  trigger: ({ props }) => ({
    className: clsx(
      'flex items-center justify-center shrink-0', 'bg-transparent text-custom-ash-gray rounded-tr rounded-br mr-3',
      { 'size-3': !props.size || props.size === 'xsmall' || props.size === 'small', 'size-6': props.size === 'large' },
      { 'text-custom-ash-gray': !props.invested, 'text-white': props.invested }
    ),
  }),
  panel: ({ props }) => ({
    className: clsx(
      // 'max-h-[200px] overflow-auto',
      'border rounded shadow-lg overflow-hidden',
      // 'scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100',
      { 'bg-white border-gray-200 text-gray-700': !props.invested, 'bg-gray-800 border-gray-600 text-white': props.invested },
      { 'text-sm': !props.size, 'text-xs': props.size === 'small', 'text-base': props.size === 'large' }
    ),
  }),
  header: ({ props }) => ({
    className: clsx(
      'border-b', 'flex items-center justify-between gap-2',
      { 'px-3 py-2': !props.size, 'py-2 px-3': props.size === 'small', 'pl-8 pr-3 py-3': props.size === 'large' },
      { 'text-gray-700 border-gray-200 bg-white': !props.invested, 'text-white border-gray-600 bg-gray-800': props.invested }
    ),
  }),
  headerCheckboxContainer: {
    root: ({ props }) => ({
      className: clsx(
        'inline-flex cursor-pointer select-none align-bottom relative',
        'mr-2',
        { 'size-5': !props.size, '!size-4': props.size === 'small', '!size-6': props.size === 'large' }
      ),
    }),
  },
  headerCheckbox: {
    root: ({ props }) => ({
      className: clsx(
        'flex items-center justify-center',
        'border-0 bg-transparent text-gray-600 rounded transition-colors duration-200',
        { 'size-5': !props.size, '!size-4': props.size === 'small', '!size-6': props.size === 'large' }
      ),
    }),
  },
  headerCheckboxIcon: ({ props }) => ({
    className: clsx(
      'transition-all duration-200 text-base',
      { 'text-gray-700': props.invested, 'text-white': !props.invested },
      { 'size-4': !props.size, 'size-3': props.size === 'small', 'size-5': props.size === 'large' }
    ),
  }),
  closeButton: {
    className: clsx('hidden'),
  },
  wrapper: {
    className: clsx(
      'max-h-[12.5rem] overflow-auto',
      'scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100'
    ),
  },
  list: ({ props }) => ({
    className: clsx(
      'flex flex-col gap-y-0.5 list-none m-0',
      { 'p-1': !props.size || props.size === 'small',
        'p-4': props.size === 'large' }
    ),
  }),
  item: ({ context, props }) => ({
    className: clsx(
      'cursor-pointer overflow-hidden relative whitespace-nowrap flex gap-2 items-center !border-none',
      'transition-colors duration-200 rounded-sm',
      {
        'text-primary hover:bg-gray-100': !props.invested,
        'text-white hover:bg-gray-700': props.invested,
        'bg-gray-200 ': context.focused && context.selected && !props.invested,
        'bg-gray-600': context.focused && context.selected && props.invested,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
      },
      { 'py-1.5 px-2 text-sm font-light': !props.size,
        'py-1 px-2 text-xs font-light': props.size === 'small',
        'py-2 px-4': props.size === 'large' }
    ),
  }),
  checkboxContainer: {
    root: ({ props }) => ({
      className: clsx(
        'flex items-center cursor-pointer inline-flex relative select-none align-bottom',
        'mr-2',
        { 'size-5': !props.size, 'size-4': props.size === 'small', 'size-6': props.size === 'large' }
      ),
    }),
  },
  checkbox: ({ props }) => ({
    className: clsx(
      'flex items-center justify-center',
      'border text-gray-600 rounded transition-colors duration-200',
      'mr-2',
      { 'size-5': !props.size, 'size-4': props.size === 'small', 'size-6': props.size === 'large' },
      {
        'cursor-default opacity-60': props.disabled,
      }
    ),
  }),
  checkboxIcon: ({ props }) => ({
    className: clsx(
      'transition-all duration-200 text-base text-white',
      { 'size-4': !props.size, 'size-3': props.size === 'small', 'size-5': props.size === 'large' }
    ),
  }),
  itemgroup: ({ props }) => ({
    className: clsx(
      'm-0', 'cursor-auto',
      { 'text-xs px-2 pt-2 pb-0.5': !props.size || props.size === 'small',
        'text-sm px-4 pt-3 pb-1.5': props.size === 'large' },
      { 'text-white': !props.invested,
        'text-neutral-400': props.invested }
    ),
  }),
  emptyMessage: ({ props }) => ({
    className: clsx({ 'py-1.5 px-2': !props.size,
      'py-1 px-2': props.size === 'small',
      'py-2 px-4': props.size === 'large' }),
  }),
  filterContainer: 'relative w-full',
  filterInput: ({ props }) => ({
    root: {
      className: clsx(
        'pr-7 -mr-7',
        'w-full',
        'font-sans border appearance-none',
        'transition-colors duration-200 ease-in-out',
        {
          '!text-white !border-neutral-500 !bg-gray-800': props.invested,
        },
        { '!text-sm !py-2 !pl-2 !h-10': !props.size,
          '!text-xs !py-1 !pl-2 !h-8': props.size === 'small',
          '!text-base !py-2 !pl-4 !h-14': props.size === 'large' }
      ),
    },
  }),
  filterIcon: ({ props }) => ({
    className: clsx(
      'absolute top-1/2 -mt-2 pointer-events-none',
      { 'text-gray-500': !props.invested,
        'text-neutral-300': props.invested }
    ),
  }),
  clearicon: ({ props }) => ({
    className: clsx(
      'text-gray-500 -mt-1.5 absolute top-1/2',
      { 'right-8 size-3': !props.size, 'right-6 size-3': props.size === 'small', 'right-10 size-6': props.size === 'large' }
    ),
  }),
  transition: TRANSITIONS.overlay,
}

export default multiselect
