import { useState } from 'react'

import SectionIconSide from '@commons/section-side-icon'
import MenuResultsSettings from '@components/settings/layout/MenuResSet'
import DesignIcon from '@images/design.svg'
import { Link } from 'react-router-dom'

import IconBar from '../components/MenuCheck'
import Hide from './Incremental/hide'
import Show from './Incremental/show'

const IncrementalGrowthPotential = id => {
  const [isBudgetSplitHidden, setIsBudgetSplitHidden] = useState(false)

  return (
    <div className='pb-20'>
      <MenuResultsSettings />
      <div className='container'>
        <div className='my-3'>
          <Link className='text-xs font-light hover:font-semibold' to={`/detail/${id}/results`}>
            Results <span className='mx-2'>/</span>
          </Link>
          <span className='text-xs font-medium'> Incremental growth potential</span>
        </div>
        <SectionIconSide
          className='w-full'
          description='Identify areas for steady growth through optimisation and controlled expansion'
          icon={DesignIcon}
          title='Incremental growth potential'
        >
          <IconBar
            isBudgetSplitHidden={isBudgetSplitHidden}
            setIsBudgetSplitHidden={setIsBudgetSplitHidden}
          />

          {isBudgetSplitHidden ? <Hide /> : <Show />}
        </SectionIconSide>
      </div>
    </div>
  )
}

export default IncrementalGrowthPotential

