import { useState } from 'react'

import CriteriaRow from '@commons/CriteriaRow'
import SectionMedium from '@commons/section-medium'

const CategoryPotentialPage = () => {
  const [formData, setFormData] = useState({
    categoryEcommerceSales: '82.2 USD',
    ecommerceShareOfRetail: '2 CW',
    brandEcommerceShare: '',
    ecommerceSalesEvolution: '36.6%',
    shareOfRetailEvolution: '2.8%',
    brandShareOfRetailEvolution: '',
  })

  const handleInputChange = (value, field) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const volumeData = [
    {
      criteria: 'Category Ecommerce Sales in USD',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      description: 'What is the total revenue generated by Ecommerce for this specific category in the selected country?',
      answerValue: formData.categoryEcommerceSales,
      answerKey: 'categoryEcommerceSales',
      performance: '2',
      growthPotential: '10',
    },
    {
      criteria: 'Category Ecommerce Share of Retail',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      description: 'What is the share of retail revenue generated by Ecommerce for this specific category in the selected country?',
      answerValue: formData.ecommerceShareOfRetail,
      answerKey: 'ecommerceShareOfRetail',
      performance: '8',
      growthPotential: '8',
    },
    {
      criteria: 'Brand\'s Ecommerce Share of Retail',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      description: 'What is the share of retail revenue generated by brand\'s Ecommerce?',
      answerValue: formData.brandEcommerceShare,
      answerKey: 'brandEcommerceShare',
      performance: '2',
      growthPotential: '14',
    }
  ]

  const trendData = [
    {
      criteria: 'Category Ecommerce Sales Evolution YoY',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      description: 'What is Ecommerce revenue growth compared to last year for this specific category in the selected country?',
      answerValue: formData.ecommerceSalesEvolution,
      answerKey: 'ecommerceSalesEvolution',
      performance: '50',
      growthPotential: '10',
    },
    {
      criteria: 'Category Ecommerce Share of Retail Evolution YoY',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      description: 'What is Ecommerce share of retail compared to last year for this specific category in the selected country?',
      answerValue: formData.shareOfRetailEvolution,
      answerKey: 'shareOfRetailEvolution',
      performance: '3',
      growthPotential: '15',
    },
    {
      criteria: 'Brand\'s share of Retail Evolution YoY',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      description: 'What is the growth of the brand\'s ecommerce share within retail YoY?',
      answerValue: formData.brandShareOfRetailEvolution,
      answerKey: 'brandShareOfRetailEvolution',
      performance: '15',
      growthPotential: '35',
    }
  ]

  return (
    <div>
      <SectionMedium title='Category Potential'>
        <div className='flex flex-col gap-8'>
          <div>
            <h2 className='font-medium mb-4 text-sm'>Volume</h2>
            <div className='px-3 xl:px-4 pb-3 xl:pb-4 pt-2 border border-gray-border rounded'>
              <CriteriaRow
                criteria='Criterias'
                growthPotentialTitle='Growth Potential'
                isHeader={true}
                isInputOnly={true}
                performanceTitle='Performance'
                valueTitle='Value' 
              />
              {volumeData.map((item, index) => (
                <CriteriaRow
                  key={index}
                  answerKey={item.answerKey}
                  answerValue={item.answerValue}
                  criteria={item.criteria}
                  description={item.description}
                  growthPotential={item.growthPotential}
                  isInputOnly={true}
                  isOdd={index % 2 === 0}
                  performance={item.performance}
                  tooltipHelp={item.tooltipHelp}
                  tooltipInfo={item.tooltipInfo}
                  onAnswerChange={value => handleInputChange(value, item.answerKey)}
                />
              ))}
            </div>
          </div>
          <div>
            <h2 className='font-medium mb-4 text-sm'>Trend</h2>
            <div className='px-3 xl:px-4 pb-3 xl:pb-4 pt-2 border border-gray-border rounded'>
              <CriteriaRow
                criteria='Criterias'
                growthPotentialTitle='Growth Potential'
                isHeader={true}
                isInputOnly={true}
                performanceTitle='Performance'
                valueTitle='Value' 
              />
              {trendData.map((item, index) => (
                <CriteriaRow
                  key={index}
                  answerKey={item.answerKey}
                  answerValue={item.answerValue}
                  criteria={item.criteria}
                  description={item.description}
                  growthPotential={item.growthPotential}
                  isInputOnly={true}
                  isOdd={index % 2 === 0}
                  performance={item.performance}
                  tooltipHelp={item.tooltipHelp}
                  tooltipInfo={item.tooltipInfo}
                  onAnswerChange={value => handleInputChange(value, item.answerKey)}
                />
              ))}
            </div>
          </div>
        </div>
      </SectionMedium>
    </div>
  )
}

export default CategoryPotentialPage
