/* eslint-disable no-empty-pattern */
/* eslint-disable max-lines */
import clsx from 'clsx'

const datatable = {
  root: ({ props }) => ({
    className: clsx('relative', {
      'flex flex-col w-full h-full': props.scrollable && props.scrollHeight === 'flex',
    }),
  }),
  loadingoverlay: {
    className: clsx(
      'fixed w-full h-full t-0 l-0 bg-gray-100/40',
      'transition duration-200',
      'absolute flex items-center justify-center z-2'
    ),
  },
  loadingicon: 'w-8 h-8',
  wrapper: ({ props }) => ({
    className: clsx({
      relative: props.scrollable,
      'flex flex-col grow w-full h-full':
        props.scrollable && props.scrollHeight === 'flex',
    }),
  }),
  header: ({ props }) => ({
    className: clsx(
      'bg-white text-black border-gray-300 font-bold mr-8',
      props.showGridlines
        ? 'border-x border-t border-b-0'
        : 'border-y border-x-0'
    ),
  }),
  table: 'bg-white w-full border-spacing-0',
  thead: ({ context }) => ({
    className: clsx({
      'bg-white top-0 z-[1]': context.scrollable,
    }),
  }),
  tbody: ({ props, context }) => ({
    className: clsx({
      'z-[1]': props.frozenRow && context.scrollable,
    }),
  }),
  tfoot: ({ context }) => ({
    className: clsx({
      'bg-white bottom-0 z-[1]': context.scrollable,
    }),
  }),
  footer: {
    className: clsx('bg-white text-slate-700 border-t-0 border-b border-x-0 border-gray-300 font-bold p-2'),
  },
  column: {
    headercell: ({ context }) => ({
      className: clsx(
        'bg-white text-left text-xs font-normal',
        'transition duration-200',
        context.index >= 1 ? 'px-2' : 'px-4'
      ),
    }),
    headercontent: ({ }) => ({
      className: clsx('flex items-center gap-4 flex-nowrap whitespace-nowrap'),
    }),
    bodycell: ({ props, context }) => ({
      className: clsx(
        'text-left text-xs font-normal text-black',
        context?.size === 'small'
          ? 'p-3'
          : context?.size === 'large'
            ? 'p-5'
            : 'py-3 px-2',
        {
          'sticky bg-inherit': props && (props.frozen || props.frozen === ''),
          'border-x border-y': context.showGridlines,
          // 'pr-0': context.index === 0,
        }
      ),
    }),
    headerrow: ({ context }) => ({
      className: clsx(
        'ml-5 bg-white text-left text-xs font-medium',
        'transition duration-200',
        context.index === 0 ? 'pl-0' : ''
      ),
    }),
    footercell: ({ context }) => ({
      className: clsx(
        'text-left border-0 border-b border-solid border-gray-300 font-bold',
        'bg-slate-50 text-slate-700',
        'transition duration-200',
        context?.size === 'small'
          ? 'p-2'
          : context?.size === 'large'
            ? 'p-5'
            : 'p-4',
        {
          'border-x border-y': context.showGridlines,
        }
      ),
    }),
    sorticon: () => ({
      className: clsx('p-[3px] rounded-[2px] size-4 border border-gray-300 cursor-pointer  bg-gray-100'),
    }),
    sortbadge: {
      className: clsx(
        'flex items-center justify-center align-middle',
        'rounded w-[1.143rem] leading-[1.143rem]',
        'text-blue-700 bg-blue-50'
      ),
    },
    columnfilter: 'inline-flex items-center ml-auto',
    filteroverlay: {
      className: clsx('bg-white text-gray-600 border-0 rounded-md min-w-[12.5rem]'),
    },
    filtermatchmodedropdown: {
      root: 'min-[0px]:flex mb-2',
    },
    filterrowitems: 'm-0 p-0 py-3 list-none ',
    filterrowitem: ({ context }) => ({
      className: clsx(
        'm-0 py-3 px-5 bg-transparent',
        'transition duration-200',
        context?.highlighted
          ? 'text-blue-700 bg-blue-100'
          : 'text-gray-600 bg-transparent'
      ),
    }),
    filteroperator: {
      className: clsx('px-5 py-3 border-b border-solid border-gray-300 text-slate-700 bg-slate-50 rounded-t-md'),
    },
    filteroperatordropdown: {
      root: 'min-[0px]:flex',
    },
    filterconstraint:
      'p-5 border-b border-solid border-gray-300',
    filteraddrule: 'py-3 px-5',
    filteraddrulebutton: {
      root: 'justify-center w-full min-[0px]:text-sm',
      label: 'flex-auto grow-0',
      icon: 'mr-2',
    },
    filterremovebutton: {
      root: 'ml-2',
      label: 'grow-0',
    },
    filterbuttonbar: 'flex items-center justify-between p-5',
    filterclearbutton: {
      root: 'w-auto min-[0px]:text-sm border-blue-500 text-blue-500 px-4 py-3',
    },
    filterapplybutton: {
      root: 'w-auto min-[0px]:text-sm px-4 py-3',
    },
    // eslint-disable-next-line no-empty-pattern
    filtermenubutton: ({ }) => ({
      className: clsx('hidden'),
    }),
    headerfilterclearbutton: ({ context }) => ({
      className: clsx(
        'hidden',
        {
          invisible: !context.hidden,
        }
      ),
    }),
    columnresizer:
      'block absolute top-0 right-0 m-0 w-2 h-full p-0 cursor-col-resize border border-transparent',
    rowreordericon: 'cursor-move',
    roweditorinitbutton: {
      className: clsx(
        'inline-flex items-center justify-center overflow-hidden relative',
        'text-left cursor-pointer select-none',
        'w-8 h-8 border-0 rounded-[50%]',
        'transition duration-200',
        'text-slate-700 border-transparent',
        'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
        'hover:text-slate-700 hover:bg-gray-300/20'
      ),
    },
    roweditorsavebutton: {
      className: clsx(
        'inline-flex items-center justify-center overflow-hidden relative',
        'text-left cursor-pointer select-none',
        'w-8 h-8 border-0 rounded-[50%]',
        'transition duration-200',
        'text-slate-700 border-transparent',
        'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
        'hover:text-slate-700 hover:bg-gray-300/20'
      ),
    },
    roweditorcancelbutton: {
      className: clsx(
        'inline-flex items-center justify-center overflow-hidden relative',
        'text-left cursor-pointer select-none',
        'w-8 h-8 border-0 rounded-[50%]',
        'transition duration-200',
        'text-slate-700 border-transparent',
        'focus:outline-0 focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
        'hover:text-slate-700 hover:bg-gray-300/20'
      ),
    },
    radiobuttonwrapper: {
      className: clsx(
        'relative inline-flex cursor-pointer select-none align-bottom',
        'size-4'
      ),
    },
    radiobutton: ({ context }) => ({
      className: clsx(
        'flex justify-center items-center',
        'border-2 size-4 text-gray-700 rounded-full transition duration-200 ease-in-out',
        context.checked
          ? 'border-blue-500 bg-blue-500'
          : 'border-gray-300 bg-white'
      ),
    }),
    radiobuttonicon: ({ context }) => ({
      className: clsx(
        'transform rounded-full',
        'block size-4 transition duration-200 bg-white',
        {
          'scale-0 invisible': context.checked === false,
          'scale-100 visible': context.checked === true,
        }
      ),
    }),
    headercheckboxwrapper: {
      className: clsx(
        'cursor-pointer inline-flex relative select-none align-bottom',
        'size-4'
      ),
    },
  },
  bodyrow: ({ context }) => ({
    className: clsx(
      context.index % 2 === 0
        ? 'bg-[#F7F7F7] text-black'
        : 'bg-white text-black',
      'transition duration-200',
      {
        'cursor-pointer': context.selectable,
      },
      {
        'border-l-4 border-black': context.disabled,
      },
      {
        'pending-authorization': context.data && !context.data.authorization,
      },
      context.index === 0 ? 'pl-0' : ''
    ),
  }),

  rowexpansion: 'bg-white text-gray-600',
  rowgroupheader: {
    className: clsx(
      'sticky z-[1]',
      'bg-white text-gray-600',
      'transition duration-200'
    ),
  },
  rowgroupfooter: {
    className: clsx(
      'sticky z-[1]',
      'bg-white text-gray-600',
      'transition duration-200'
    ),
  },
  rowgrouptoggler: {
    className: clsx(
      'text-left m-0 p-0 cursor-pointer select-none',
      'inline-flex items-center justify-center overflow-hidden relative',
      'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-[50%]',
      'transition duration-200'
    ),
  },
  rowgrouptogglericon: 'inline-block w-4 h-4',
  resizehelper: 'absolute hidden w-px z-10 bg-blue-500',
}

export default datatable
