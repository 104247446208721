import clsx from 'clsx'

const inputtext = {
  root: ({ props, context }) => ({
    className: clsx(
      'm-0',
      'w-full focus:outline-none text-custom-ash-gray text-sm border border-neutral-200 font-light',
      {
        'focus:outline-none focus:outline-offset-0': !context.disabled,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
      },
      {
        'text-lg px-4 py-4 h-14 rounded-md': props.size === 'large',
        'text-xs px-2 py-2 h-8 rounded-md': props.size === 'small',
        'text-xs px-2 py-1 h-7 rounded-md': props.size === 'xsmall',
        'px-3 py-2 text-base h-9 xl:h-10 rounded-md': !props.size || props.size === null,
      }

    ),
  }),
}

export default inputtext
