import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const CardButton = ({ title, description, date, icon, url, hoverBgColor }) => {
  const bgHoverClass = hoverBgColor ? `bg-${hoverBgColor}` : ''


  return (
    <Link className='w-1/2' to={`${url}`}>
      <div className={`group h-full w-full bg-neutral-100 border border-neutral-100 rounded-lg px-4 pt-4 pb-6 w-1/2 transition-all cursor-pointer hover:text-primary hover:border-primary hover:${bgHoverClass}`}>
        <div className='flex flex-col gap-1 h-full'>
          <div className='flex items-center gap-2'>
            {icon && <img alt={title} className='size-6' src={icon} />}
            <h4 className='text-sm font-light stretch-extra-expanded leading-tight'>{title}</h4>
          </div>
          <div className='flex flex-col gap-2 pl-8 grow'>
            <p className='text-gray-text text-xs font-light leading-tight group-hover:text-primary'>{description}</p>
            <div className='mt-auto'>
              <span className='text-gray-text text-2xs font-light bg-gray-500/10 py-1 px-2 rounded group-hover:text-primary'>
                Updated {date}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

CardButton.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  hoverBgColor: PropTypes.string.isRequired,
}

export default CardButton