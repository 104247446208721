import { useCallback } from 'react'

import { ReactComponent as Uploadicon } from '@icons/upload.svg'
import { useDropzone } from 'react-dropzone'

const CSVUpload = () => {
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const binaryStr = reader.result

        console.log(binaryStr)
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} className='flex flex-col border border-dashed border-blue-300 text-gray-400 rounded-md min-h-[6.25rem] justify-center text-center cursor-pointer'>
      <input {...getInputProps()} accept='.csv' />

      <Uploadicon alt='Upload file' className='size-6 mx-auto '/> 
      
      <p className='text-sm'>
        {isDragActive ? 'Drop your file here' : 'Drag and Drop your .csv here'}
      </p>
    </div>
  )
}

export default CSVUpload
