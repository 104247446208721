import { useEffect } from 'react'

import Highcharts from 'highcharts'

const BarsShow = () => {
  useEffect(() => {
    Highcharts.chart('container', {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
        height: 310,
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: [''],
        lineWidth: 0,
      },
      yAxis: {
        min: 0,
        max: 125,
        tickInterval: 25,
        title: {
          text: null,
        },
        labels: {
          enabled: true,
          align: 'center',
          style: {
            color: '#9CA3AF',
          },
        },
        gridLineWidth: 0.5,
        opposite: true,
      },
      plotOptions: {
        bar: {
          pointWidth: 17,
          borderRadius: 8,
          dataLabels: {
            enabled: false,
          },
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'bottom',
        symbolRadius: 6,
        itemStyle: {
          fontSize: '11px',
          color: '#9CA3AF',
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Ecommerce Turnover',
          data: [100],
          color: '#DC3545',
        },
        {
          name: 'Potential Ecommerce Turnover',
          data: [80],
          color: '#538D9A',
        },
        {
          name: 'Estimated Incremental Turnover',
          data: [115],
          color: '#06AED2',
        }
      ],
    })
  }, [])

  return (
    <div className='w-full bg-white'>
      <div id='container'></div>
    </div>
  )
}

export default BarsShow
