import clsx from 'clsx'


const avatar = {
  root: ({ props, state }) => ({
    className: clsx(
      'flex items-center justify-center',
      'bg-slate-600 overflow-hidden',
      {
        'rounded-lg': props.shape === 'square',
        'rounded-full': props.shape === 'circle',
      },
      {
        'text-sm size-7': props.size === 'small',
        'text-xl size-9': !props.size || props.size === 'normal',
        'size-12 text-xl': props.size === 'large',
        'size-16 text-2xl': props.size === 'xlarge',
      },
      {
        '-ml-4 border-2 border-white': state.isNestedInAvatarGroup,
      }
    ),
  }),
  image: 'h-full w-full',
}


export default avatar
