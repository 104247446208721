import AmazonIcon from '@images/tools/amazon.svg'
import AuchanIcon from '@images/tools/auchan.svg'
import CarrefourIcon from '@images/tools/carrefour.svg'
import EleclercIcon from '@images/tools/eleclerc.svg'

const PerformanceTurnoverChart = () => {
  const data = [
    {
      name: 'Amazon',
      icon: AmazonIcon,
      performanceScore: 2.1,
      turnover: 12.7,
    },
    {
      name: 'Auchan',
      icon: AuchanIcon,
      performanceScore: 2.8,
      turnover: 14.5,
    },
    {
      name: 'Carrefour',
      icon: CarrefourIcon,
      performanceScore: 2.1,
      turnover: 12.7,
    },
    {
      name: 'Eleclerc',
      icon: EleclercIcon,
      performanceScore: 2.1,
      turnover: 13.7,
    }
  ]

  return (
    <div>
      {data.map((item, index) => (
        <div key={index} className='flex items-center justify-between mb-5'>
          <div className='flex items-center space-x-2 w-[10%] mr-8'>
            <img alt={item.name} className='size-4' src={item.icon} />
            <span className='text-sm'>{item.name}</span>
          </div>
          <div className='w-[45%] mb-5 mr-4'>
            <p className='text-xs text-gray-400'>Current performance score</p>
            <div className='relative w-full h-2 bg-gray-200 rounded-full'>
              <div
                className='absolute top-0 h-2 bg-purple-bar rounded-full'
                style={{
                  width: `${item.performanceScore / 5 * 100}%`,
                  transition: 'width 1s ease-in-out',
                }}
              ></div>
            </div>
          </div>
          <div className='w-10 text-xl text-right text-purple-bar mr-16'>
            {item.performanceScore}
          </div>

          <div className='w-[45%] mb-5 mr-4'>
            <p className='text-xs text-gray-400'>Incremental Turnover</p>
            <div className='relative w-full h-2 bg-gray-200 rounded-full'>
              <div
                className='absolute top-0 h-2 bg-gold rounded-full'
                style={{
                  width: `${item.turnover}%`,
                  transition: 'width 1s ease-in-out',
                }}
              ></div>
            </div>
          </div>
          <div className='w-10 text-xl text-right text-gold'>{item.turnover}%</div>
        </div>
      ))}
    </div>
  )
}

export default PerformanceTurnoverChart

