import ReusableTable from '../components/tableNonMedia'

const Overview = () => {
  const data = [
    {
      type: 'Strengths',
      criteria: 'Share of best seller rank',
      platforms: ['eLeclerc', 'amazon', 'carrefour', 'auchan'],
      answers: {
        eLeclerc: '-',
        auchan: '-',
        carrefour: '-',
        amazon: 24,
      },
    },
    {
      type: 'Pain Points',
      criteria: 'New reviews in last 30 days (in top SKUs)',
      platforms: ['eLeclerc', 'amazon', 'carrefour', 'auchan'],
      answers: {
        eLeclerc: '-',
        auchan: '-',
        carrefour: '-',
        amazon: 26,
      },
    },
    {
      type: 'Strengths',
      criteria: 'Product range depth',
      platforms: ['eLeclerc', 'auchan'],
      answers: {
        eLeclerc: '-',
        auchan: '-',
        carrefour: '-',
        amazon: 321,
      },
    },
    {
      type: 'Strengths',
      criteria: 'Buy box with rate',
      platforms: ['auchan', 'carrefour', 'amazon'],
      answers: {
        eLeclerc: '-',
        auchan: '-',
        carrefour: '-',
        amazon: 3,
      },
    },
    {
      type: 'Average',
      criteria: 'Product range depth',
      platforms: ['eLeclerc', 'auchan', 'amazon'],
      answers: {
        eLeclerc: '-',
        auchan: '-',
        carrefour: '-',
        amazon: 24,
      },
    },
    {
      type: 'Average',
      criteria: 'Share of best seller rank',
      platforms: ['eLeclerc', 'carrefour', 'amazon'],
      answers: {
        eLeclerc: '-',
        auchan: '-',
        carrefour: '-',
        amazon: 346,
      },
    },
    {
      type: 'Pain Points',
      criteria: 'Product range depth',
      platforms: ['eLeclerc'],
      answers: {
        eLeclerc: '-',
        auchan: '-',
        carrefour: '-',
        amazon: 601,
      },
    },
    {
      type: 'Strengths',
      criteria: 'Brand Organic Share of Shelf Marketplace',
      platforms: ['auchan', 'carrefour'],
      answers: {
        eLeclerc: '-',
        auchan: '-',
        carrefour: '-',
        amazon: 300,
      },
    }
  ]


  return (
    <div>
      <ReusableTable data={data}
        title='Overview' />
    </div>
  )
}

export default Overview