import clsx from 'clsx'

const message = {
  root: ({ props }) => ({
    className: clsx(
      'inline-flex items-center align-top text-[10px]', 'py-1 px-2 m-0 rounded',
      {
        'bg-blue-100 border-0 text-blue-700': props.severity === 'info',
        'bg-green-100 border-0 text-green-700': props.severity === 'success',
        'bg-amber-100 border-0 text-yellow-700': props.severity === 'warn',
        'bg-red-100 border-0 text-red-700': props.severity === 'error',
      }
    ),
  }),
  icon: 'size-5 mr-1',
}

export default message
