import SectionIconSide from '@commons/section-side-icon'
import MenuResultsSettings from '@components/settings/layout/MenuResSet'
import IntelligenceIcon from '@images/intelligence.svg'
import PowerBiIcon from '@images/tools/powerbi.svg'
import { Link } from 'react-router-dom'

const KeywordRelevancyIndexResults = id => {
  return (
    <div className='h-full mb-7'>
      <MenuResultsSettings />
      <div className='container'>
        <div className='my-3'>
          <Link className='text-xs font-light hover:font-semibold' to={`/detail/${id}/results`}>Results <span className='mx-2'>/</span></Link><span className='text-xs font-medium'> Keyword relevancy index</span>

        </div>
        <SectionIconSide
          description='Optimise your keywords by evaluating their relevance across platforms to boost visibility.'
          icon={IntelligenceIcon}
          title='Keyword relevancy Index'
        >
          <div className='w-full h-[580px] bg-gray-100 mt-4 rounded-md flex justify-center'>
            <div className='flex items-center'>
              <img alt='PowerBI Icon' className='size-12' src={PowerBiIcon} />
            </div>

          </div>
        </SectionIconSide>
      </div>
    </div>
  )
}

export default KeywordRelevancyIndexResults
