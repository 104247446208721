import React, { useState } from 'react'

import { ReactComponent as EditIcon } from '@icons/edit.svg'
import { ReactComponent as AmazonLogo } from '@images/tools/amazon.svg'
import { ReactComponent as CarrefourLogo } from '@images/tools/carrefour.svg'
import { ReactComponent as DTCLogo } from '@images/tools/dtc.svg'
import { ReactComponent as WallmartLogo } from '@images/tools/wallmart.svg'
import { InputText } from 'primereact/inputtext'

import RetailModal from './RetailModal'

const TableRetailMedia = () => {
  const [headerInputs, setHeaderInputs] = useState({
    CategoryShare: '10%',
    ForecastGrowth: '10%',
    Sov: '10%',
    Relevancy: '10%',
    RetailMediaMaturity: '10%',
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState(null)

  const handleOpenModal = type => {
    const content = {
      relevancy: {
        title: 'Relevancy question',
        question: 'How relevant is the platform to you?',
        options: [
          { label: 'Highly relevant', value: 'Highly relevant' },
          { label: 'Relevant', value: 'Relevant' },
          { label: 'Not that relevant', value: 'Not that relevant' },
          { label: 'Useless', value: 'Useless' }
        ],
      },
      retailMediaMaturity: {
        title: 'Retail Media Maturity question',
        question: 'Does the platform have a lot of retail media assets to propose?',
        options: [
          { label: 'Yes, quite a bit', value: 'Yes, quite a bit' },
          { label: 'Yes, but average', value: 'Yes, but average' },
          { label: 'Not really', value: 'Not really' }
        ],
      },
    }

    setModalContent(content[type])
    setModalVisible(true)
  }

  const handleInputChange = (e, key) => {
    setHeaderInputs({ ...headerInputs, [key]: e.target.value })
  }

  const tableData = [
    {
      logo: <DTCLogo className='size-5' />,
      retailer: 'Retailer 1',
      categoryShare: { value: 2, grade: 2, weightedGrade: 1 },
      forecastGrowth: { value: 2, grade: 2, weightedGrade: 1 },
      sov: { value: 2, grade: 2, weightedGrade: 1 },
      relevancy: { value: 2, grade: 2, weightedGrade: 1 },
      retailMediaMaturity: { value: 2, grade: 2, weightedGrade: 1 },
      total: { weightedGrade: 2, mediaAllocation: '21%' },
    },
    {
      logo: <CarrefourLogo className='size-5' />,
      retailer: 'Retailer 2',
      categoryShare: { value: 2, grade: 1, weightedGrade: 2 },
      forecastGrowth: { value: 2, grade: 2, weightedGrade: 1 },
      sov: { value: 2, grade: 2, weightedGrade: 1 },
      relevancy: { value: 2, grade: 2, weightedGrade: 1 },
      retailMediaMaturity: { value: 2, grade: 2, weightedGrade: 1 },
      total: { weightedGrade: 2, mediaAllocation: '21%' },
    },
    {
      logo: <AmazonLogo className='size-5' />,
      retailer: 'Retailer 3',
      categoryShare: { value: 2, grade: 2, weightedGrade: 1 },
      forecastGrowth: { value: 2, grade: 2, weightedGrade: 1 },
      sov: { value: 2, grade: 2, weightedGrade: 1 },
      relevancy: { value: 2, grade: 2, weightedGrade: 1 },
      retailMediaMaturity: { value: 2, grade: 2, weightedGrade: 1 },
      total: { weightedGrade: 2, mediaAllocation: '21%' },
    },
    {
      logo: <WallmartLogo className='size-5' />,
      retailer: 'Retailer 4',
      categoryShare: { value: 2, grade: 1, weightedGrade: 2 },
      forecastGrowth: { value: 2, grade: 2, weightedGrade: 1 },
      sov: { value: 2, grade: 2, weightedGrade: 1 },
      relevancy: { value: 2, grade: 2, weightedGrade: 1 },
      retailMediaMaturity: { value: 2, grade: 2, weightedGrade: 1 },
      total: { weightedGrade: 2, mediaAllocation: '21%' },
    }
  ]

  return (
    <div className='relative w-full overflow-x-auto'>
      <table className='table-auto w-full border-separate border-spacing-y-2'>
        <thead>
          <tr>
            <th className='w-14 sticky left-0 bg-white'></th>
            <th className='w-[4.5rem] sticky left-14 bg-white'></th>
            {Object.keys(headerInputs).map(key => (
              <th key={key} className='p-0' colSpan={3}>
                <div className={`bg-table-gray py-2 text-center font-normal text-xs ${key === Object.keys(headerInputs)[0] ? 'rounded-s-lg' : ''} ${key === Object.keys(headerInputs)[Object.keys(headerInputs).length - 1] ? 'rounded-e-lg' : ''}`}>
                  <div className={`pb-1 ${key === Object.keys(headerInputs)[0] ? '' : 'border-l border-gray-200'}`}>
                    <div className='uppercase stretch-extra-expanded mb-1'>
                      <span>{key.split(/(?=[A-Z])/).join(' ')}</span>
                    </div>
                    <div className='flex justify-center items-center gap-2 font-normal text-xs'>
                      Value
                      <InputText 
                        className='!w-14 text-center'
                        placeholder='Value'
                        size='small'
                        value={headerInputs[key]}
                        onChange={e => handleInputChange(e, key)}
                      />
                    </div>
                  </div>
                </div>
              </th>
            ))}
            <th className="w-[4.75rem] sticky -right-px bg-white pl-2 py-0 before:content-[''] before:absolute before:-left-3 before:w-3 before:h-[calc(100%+0.325rem)] before:bg-gradient-to-r before:from-transparent before:to-neutral-500/10">
              <div className='bg-table-green text-center py-2 h-[4.625rem] font-normal text-xs text-white rounded-lg'>
                <div className='uppercase stretch-extra-expanded mb-2'>
                  Total
                </div>
                <span className='flex justify-center mt-2 font-normal text-xs'>
                  Value 100%
                </span>
              </div>
            </th>
          </tr>
          <tr>
            <th className='w-14 sticky left-0 bg-white'></th>
            <th className='w-[4.5rem] sticky left-14 bg-white'></th>
            {Object.keys(headerInputs).map((key, index) => (
              <React.Fragment key={key}>
                <th className='py-2 px-0 text-center'>
                  <div className='px-4 font-normal text-sm'>
                    Value
                  </div>
                </th>
                <th className='py-2 px-0 text-center'>
                  <div className='px-4 font-normal text-sm'>
                    Grade
                  </div>
                </th>
                <th className='py-2 px-0 text-center'>
                  <div className={`px-4 font-normal text-sm ${
                    index !== Object.keys(headerInputs).length - 1
                      ? 'border-r border-gray-200'
                      : ''
                  }`}
                  >
                    Weighted Grade
                  </div>
                </th>
              </React.Fragment>
            ))}
            <th className="w-[4.75rem] sticky -right-px bg-white pl-2 before:content-[''] before:absolute before:-left-3 before:w-3 before:-translate-y-1 before:h-[calc(100%+0.5rem)] before:bg-gradient-to-r before:from-transparent before:to-neutral-500/10">
              <div className='bg-table-green flex justify-between py-2 text-white font-normal text-sm rounded-lg text-center'>
                <span className='px-3'>Weighted Grade</span>
                <span className='px-3'>Media Allocation</span>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {tableData.map((row, idx) => (
            <tr key={idx}>
              {idx === 0 && (
                <td 
                  className='sticky w-14 left-0 px-0 rounded-lg bg-table-gray' 
                  rowSpan={tableData.length}
                >
                  <div className='flex items-center justify-end h-full w-14'>
                    <span className='w-full transform -rotate-90 translate-y-1/2 stretch-extra-expanded text-xs'>
                      RETAILERS
                    </span>
                  </div>
                </td>
              )}
              <td className='sticky w-[4.5rem] left-14 bg-white px-2'>
                <div className={`w-14 h-14 flex items-center justify-center rounded-lg ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  {row.logo}
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`rounded-s-lg h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.categoryShare.grade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.categoryShare.weightedGrade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.forecastGrowth.value}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.forecastGrowth.grade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.forecastGrowth.weightedGrade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.sov.value}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.sov.grade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.sov.weightedGrade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.sov.weightedGrade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 flex gap-5 justify-center items-center ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div
                    className='p-1 bg-[#E5E5E5] rounded-md cursor-pointer'
                    onClick={() => handleOpenModal('retailMediaMaturity')}
                  >
                    <EditIcon className='size-4' />
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.relevancy.grade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.relevancy.weightedGrade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 flex gap-5 justify-center items-center ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div
                    className='p-1 bg-[#E5E5E5] rounded-md cursor-pointer'
                    onClick={() => handleOpenModal('relevancy')}
                  >
                    <EditIcon className='size-4' />
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2 border-r border-gray-200'>
                    {row.retailMediaMaturity.grade}
                  </div>
                </div>
              </td>
              <td className='text-center p-0 text-sm'>
                <div className={`rounded-e-lg h-14 py-4 ${idx % 2 === 0 ? 'bg-table-gray' : 'bg-white'}`}>
                  <div className='px-2'>
                    {row.retailMediaMaturity.weightedGrade}
                  </div>
                </div>
              </td>
              <td className="sticky -right-px bg-white pl-2 before:content-[''] before:absolute before:-left-3 before:w-3 before:-translate-y-1 before:h-[calc(100%+0.5rem)] before:bg-gradient-to-r before:from-transparent before:to-neutral-500/10">
                <div className='bg-table-green flex items-center justify-between px-3 py-4 text-white font-normal text-sm rounded-lg relative h-14'>
                  <span className='pl-7 pr-5'>{row.total.weightedGrade}</span>
                  <div className='absolute left-1/2 transform -translate-x-1/2 h-[30px] w-[1px] bg-white'></div>
                  <span className='pl-5 pr-5'>{row.total.mediaAllocation}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <RetailModal
        modalContent={modalContent}
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </div>
  )
}

export default TableRetailMedia
