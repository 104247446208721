import { useState } from 'react'

import CriteriaRow from '@commons/CriteriaRow'
import Separator from '@commons/Separator'
import { Button } from 'primereact/button'
import PropTypes from 'prop-types' 

const ForecastModal = () => {
  const [formData, setFormData] = useState({
    pdpContent: '',
    reviews: '',
    gradingScore: '',
    avgPrice: '',
    avgDiscount: '',
    organicSOV: '',
    organicSOVCompetitive: '',
    paidSOV: '',
    paidSOVCompetitive: '',

    performance: {
      pdpContent: '',
      reviews: '',
      gradingScore: '',
      avgPrice: '',
      avgDiscount: '',
      organicSOV: '',
      organicSOVCompetitive: '',
      paidSOV: '',
      paidSOVCompetitive: '',
    },
    growthPotential: {
      pdpContent: '',
      reviews: '',
      gradingScore: '',
      avgPrice: '',
      avgDiscount: '',
      organicSOV: '',
      organicSOVCompetitive: '',
      paidSOV: '',
      paidSOVCompetitive: '',
    },
  })

  const handleInputChange = (event, key) => {
    const { name, value } = event.target

    setFormData(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        [name]: value,
      },
    }))
  }

  const handleSubmit = event => {
    event.preventDefault()
    console.log('Form Data Saved:', formData)
  }

  const rowsData = [
    {
      criteria: <span className='flex flex-row gap-1'>How is your PDP content</span>,
      tooltipInfo: 'This is a tooltip info',       
      answerOptions: ['Very bad', 'Only some SKUs optimized', 'Average', 'Good', 'Excellent and all SKUs optimezed'],
      answerValue: formData.pdpContent,
      answerKey: 'pdpContent',
    },
    {
      criteria: <span>
        How many Reviews does the brand have on average for all SKUs on page 1  
        <span className='text-gray-400 mt-1'>
          (Optional)
        </span>
      </span>,
      tooltipInfo: 'This is a tooltip info',
      answerOptions: ['0-20', '20-100', '100-500', '500-1000', '1000 +'],
      answerValue: formData.reviews,
      answerKey: 'reviews',
    },
    { 
      criteria: 
  <span>Grading score for Reviews does the brand in average for all SKUs in page 1
    <span className='text-gray-400 mx-1'>(Optional)</span> 
  </span>,
      tooltipInfo: 'This is a tooltip info',
      answerOptions: ['0-50', '51-200', '201-600', '601-1500', '1500 +'],
      answerValue: formData.gradingScore,
      answerKey: 'gradingScore',
    },
    { 
      criteria: 
  <span>Average price of your brand&apos; product 
    <span className='text-gray-400 mx-1'>(Excluding packs and bundles)
    </span> </span>,
      tooltipInfo: 'This is a tooltip info',
      answerOptions: ['Cheaper than competition', 'Same price as competition', 'More expensive than competition', 'Premium Price'],
      answerValue: formData.avgPrice,
      answerKey: 'avgPrice',
    },
    {
      criteria: 
  <span>Average discount given by your brand&apos; product 
    <span className='text-gray-400 mx-1'>(Excluding packs)</span> </span>,
      tooltipInfo: 'This is a tooltip info',
      answerOptions: ['No discount', '1%-10%', '10%-20%', '20%-30%', '30% +'],
      answerValue: formData.avgDiscount,
      answerKey: 'avgDiscount',
    },
    {
      criteria: <span>ORGANIC Share of voice of your brand&apos; product on generic Keywords<span></span></span>,
      answerOptions: ['0-15%', '15%-20%', '20%-30%', '30%-50%', '50% +'],
      answerValue: formData.organicSOV,
      answerKey: 'organicSOV',
    },
    {
      criteria: <span>ORGANIC Share of voice of your brand&apos; product on competitive Keywords</span>,
      tooltipInfo: 'This is a tooltip info',
      answerOptions: ['0-15%', '15%-20%', '20%-30%', '30%-50%', '50% +'],
      answerValue: formData.organicSOVCompetitive,
      answerKey: 'organicSOVCompetitive',
    },
    {
      criteria: 
  <span>PAID Share of voice of your brand&apos; product on generic Keywords
    <span className='text-gray-400 mx-1'>(Optional)
    </span> </span>,
      tooltipInfo: 'This is a tooltip info',
      answerOptions: ['0-15%', '15%-20%', '20%-30%', '30%-50%', '50% +'],
      answerValue: formData.paidSOV,
      answerKey: 'paidSOV',
    },
    {
      criteria: 
  <span>PAID Share of voice of your brand&apos; product on competitive Keywords
    <span className='text-gray-400 mx-1'>(Optional)</span> </span>,
      tooltipInfo: 'This is a tooltip info',
      answerOptions: ['0-15%', '15%-20%', '20%-30%', '30%-50%', '50% +'],
      answerValue: formData.paidSOVCompetitive,
      answerKey: 'paidSOVCompetitive',
    }
  ]
 
  return (
    <form>
      <Separator className='my-3' />
      <div className='overflow-y-auto max-h-[315px]'>
        <h3 className='text-sm text my-4 font-medium'>Forecast Lite questions</h3>
        <div className='m-2 p-3 border border-gray-100 rounded'>
          <CriteriaRow
            criteria='Criterias'
            growthPotentialTitle='Growth Potential'
            isHeader={true}
            isSelectOnly={true} 
            performanceTitle='Performance'
            valueTitle='Answer'
          />
          {rowsData.map((item, index) => (
            <CriteriaRow
              key={index}
              answerOptions={item.answerOptions}
              answerValue={item.answerValue}
              criteria={item.criteria}
              growthPotential={formData.growthPotential[item.answerKey]}
              isInputOnly={false}
              isOdd={index % 2 === 0}
              isSelectOnly={true}
              performance={formData.performance[item.answerKey]}
              tooltipInfo={item.tooltipInfo}
              onAnswerChange={e => handleInputChange(e, item.answerKey)}
              onGrowthPotentialChange={e => handleInputChange(e, item.answerKey, 'growthPotential')}
              onPerformanceChange={e => handleInputChange(e, item.answerKey, 'performance')}
            />
          ))}
        </div>
      </div>

      <div className='flex justify-end mt-4'>
        <Button
          className='bg-primary text-white py-2 px-4 rounded'
          label='Save Forecast Lite'
          type='submit'
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

ForecastModal.propTypes = {
  onClose: PropTypes.func.isRequired, 
}

export default ForecastModal