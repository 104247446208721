/* eslint-disable no-empty-pattern */
import clsx from 'clsx'


const TRANSITIONS = {
  overlay: {
    timeout: 150,
    classNames: {
      enter: 'opacity-0 scale-75',
      enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
      exit: 'opacity-100',
      exitActive: '!opacity-0 transition-opacity duration-150 ease-linear',
    },
  },
}

const autocomplete = {
  root: ({ props }) => ({
    className: clsx(
      'cursor-pointer inline-flex relative select-none',
      'transition-colors duration-200 ease-in-out rounded',
      'w-full',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
      {
        'border border-neutral-100 bg-neutral-100 hover:border-neutral-200': !props.transparent && !props.invested,
        'border border-gray-800 bg-gray-800 hover:border-gray-800': !props.transparent && props.invested,
      }
    ),
  }),

  /*
   * container: {
   *   className: clsx(
   *     'm-0 list-none cursor-text overflow-hidden flex items-center flex-wrap w-full',
   *     'px-3 py-2 gap-2',
   *     'font-sans text-base text-gray-700 bg-white border border-gray-300 transition duration-200 ease-in-out appearance-none rounded-md',
   *     'focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] hover:border-blue-500 focus:outline-none'
   *   ),
   * },
   */
  inputToken: {
    className: clsx('py-0.375rem px-0', 'flex-1 inline-flex'),
  },
  input: ({ props }) => ({
    root: {
      className: clsx(
        'cursor-pointer block flex items-center flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative',
        'bg-transparent border-0',
        'transition duration-200 bg-transparent rounded appearance-none font-sans',
        'focus:outline-none focus:shadow-none',
        { 'text-sm px-3 py-0 h-10': !props.size, 'text-xs py-0 px-3 h-8': props.size === 'small', 'text-base px-8 py-0 h-14': props.size === 'large' },
        { 'rounded-tr-none rounded-br-none': props.dropdown },
        { 'text-gray-700': !props.invested, 'text-white': props.invested }
      ),
    },
  }),
  trigger: ({ props }) => ({
    className: clsx(
      'flex items-center justify-center shrink-0', 'bg-transparent text-gray-700 rounded-tr rounded-br',
      { 'w-8': !props.size, 'w-6': props.size === 'small', 'w-10': props.size === 'large' },
      { 'text-gray-700': !props.invested, 'text-white': props.invested }
    ),
  }),
  token: {
    className: clsx('py-1 px-2 mr-2 bg-gray-300 text-gray-700 rounded-full', 'cursor-default inline-flex items-center'),
  },
  dropdownButton: ({ props }) => ({
    root: {
      className: clsx(
        'rounded-tl-none rounded-bl-none',
        { 'h-10 w-8': !props.size, 'h-8 w-6': props.size === 'small', 'h-14 w-10': props.size === 'large' },
        { '!text-gray-700 border border-neutral-100 bg-neutral-100 hover:border-neutral-100 hover:bg-neutral-100': !props.transparent && !props.invested },
        { 'text-white border border-gray-800 bg-gray-800 hover:border-gray-800 hover:bg-gray-800': !props.transparent && props.invested },
        { 'text-gray-700 border border-transparent bg-transparent hover:border-transparent hover:bg-transparent': props.transparent && !props.invested },
        { 'text-white border border-transparent bg-transparent hover:border-transparent hover:bg-transparent': props.transparent && props.invested }
      ),
    },
  }),
  panel: ({ props }) => ({
    className: clsx(
      'max-h-[200px] overflow-auto',
      'border-0 rounded shadow-lg',
      'scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100',
      { 'bg-white text-gray-700': !props.invested, 'bg-gray-800 text-white': props.invested },
      { 'text-sm': !props.size, 'text-xs': props.size === 'small', 'text-base': props.size === 'large' }
    ),
  }),
  list: ({ props }) => ({
    className: clsx(
      'list-none m-0',
      { 'p-1': !props.size || props.size === 'small',
        'p-4': props.size === 'large' }
    ),
  }),
  item: ({ context, props }) => ({
    className: clsx(
      'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
      'my-0.5 border-0 transition-shadow duration-200 rounded',
      {
        'text-gray-700 hover:bg-gray-100': !props.invested,
        'text-white hover:bg-gray-700': props.invested,
        'bg-gray-200': context.focused && context.selected && !props.invested,
        'bg-gray-600': context.focused && context.selected && props.invested,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
      },
      { 'text-gray-800 hover:bg-gray-100': !props.invested, 'text-white hover:bg-gray-600': props.invested },
      { 'p-2': !props.size,
        'py-1 px-2': props.size === 'small',
        'py-2 px-4': props.size === 'large' }
    ),
  }),
  itemGroup: {
    className: clsx('m-0 p-3 text-gray-800 bg-white font-bold', 'cursor-auto'),
  },
  transition: TRANSITIONS.overlay,
}


export default autocomplete
