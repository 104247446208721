import RevenueComparisonCard from './averageMonthly'
import RevenueCard from './averageMontly'
import Table from './tableCategory'
import KeywordCoverageGauge from './topKeyword'

const DetailedResults = () => {
  // Datos para la tabla
  const headers = [
    'Sub-sub category', 'Brand', 'Total Number of ASINs', 'No. of ASINs in BSR (Top 100)',
    'No. of ASINs in BSR (Top 10)', 'AVR Price',
    'Est. Volume', 'Market Share (volume)', 'Estimated Revenue', 'Market Share', 
    'Keyword Coverage (Organic)', 'Keyword Coverage (Paid)', 'Keyword Coverage (Paid)', 
    'Total Review Count', 'Average Rating', 'Average Review Velocity'
  ]

  const data = [
    ['Body Care','Cicabiafine','3','2','1','11.00 EUR','144', '2.6%', '528.56 EUR', '5.7%', '52.2%', '18.6%', '19.6%', '600', '4.4', '4'],
    ['Body Care','Cicabiafine','3','2','1','11.00 EUR','144', '2.6%', '528.56 EUR', '5.7%', '52.2%', '18.6%', '19.6%', '600', '4.4', '4'],
    ['Body Care','Cicabiafine','3','2','1','11.00 EUR','144', '2.6%', '528.56 EUR', '5.7%', '52.2%', '18.6%', '19.6%', '600', '4.4', '4'],
    ['Body Care','Cicabiafine','3','2','1','11.00 EUR','144', '2.6%', '528.56 EUR', '5.7%', '52.2%', '18.6%', '19.6%', '600', '4.4', '4'],
    ['Body Care','Cicabiafine','3','2','1','11.00 EUR','144', '2.6%', '528.56 EUR', '5.7%', '52.2%', '18.6%', '19.6%', '600', '4.4', '4']
  ]

  return (
    <div className='space-y-4'>
      {/* Primera fila de tarjetas */}
      <div className='flex gap-4'>
        <div className='w-1/3'>
          <RevenueCard
            color='#10AC94'
            currency='EUR'
            progress={85}
            title='Average Monthly Revenue' 
            value='2,350,208.09'
          />
        </div>
        <div className='w-1/3'>
          <KeywordCoverageGauge
            organicValue={20.3}
            paidValue={9.1}
            title='Top Keyword Coverage'
          />
        </div>
        <div className='w-1/3'>
          <RevenueComparisonCard
            bsrLabel='In BSR (top 100)'
            bsrValue={7.6}
            title='Average Monthly Revenue'
            totalLabel='Total'
            totalValue={98.6}
          />
        </div>
      </div>

      {/* Tabla */}
      <div className='relative-container bg-white overflow-x-auto shadow-right rounded-lg'>
        <Table data={data} headers={headers} />
      </div>
    </div>
  )
}

export default DetailedResults
