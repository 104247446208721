import clsx from 'clsx'

const fileupload = {
  input: 'hidden',
  buttonbar: {
    className: clsx('flex flex-wrap', 'bg-gray-50 p-5 border border-solid border-gray-300 text-gray-700 rounded-tr-lg rounded-tl-lg gap-2 border-b-0'),
  },
  basicButton: {
    className: clsx('text-white bg-blue-500 border border-blue-500 p-3 px-5 rounded-md text-base', 'overflow-hidden relative'),
  },
  chooseButton: {
    className: clsx('text-blue-600 border border-blue-600 p-1 rounded-full text-2xs', 'overflow-hidden relative'),
  },
  chooseIcon: 'inline-block',
  chooseButtonLabel: 'hidden',
  uploadButton: {
    className: clsx('text-green-500 border border-green-500 p-1 rounded-full text-2xs', 'overflow-hidden relative'),
  },
  cancelButton: {
    className: clsx('text-red-400 border border-red-400 p-1 rounded-full text-2xs', 'overflow-hidden relative'),
  },
  icon: 'mr-2 text-green-500',
  content: {
    className: clsx('relative', 'bg-white p-8 border border-custom-shadow-gray text-custom-medium-100-gray rounded-b-lg'),
  },
  file: {
    className: clsx('flex items-center flex-wrap', 'p-4 border border-gray-300 rounded gap-2 mb-2', 'last:mb-0'),
  },
  thumbnail: 'shrink-0',
  fileName: 'mb-2',
  fileSize: 'mr-2',
  uploadIcon: '',
}

export default fileupload
