import clsx from 'clsx'

const steps = {
  root: 'relative',
  menu: 'p-0 m-0 list-none flex gap-4',
  menuitem: {
    className: clsx(
      'relative flex flex-1 overflow-hidden pr-8',
      'before:border-t before:border-gray-300 before:w-full before:absolute before:top-1/2 before:left-0 before:transform before:-translate-y-1/2 last:before:border-0'
    ),
  },
  action: {
    className: clsx(
      'inline-flex items-center overflow-hidden group',
      'transition-shadow rounded-md'
    ),
  },
  step: {
    className: clsx(
      'flex items-center justify-center flex-none',
      'w-[2rem] h-[1.5rem] leading-2rem text-xs z-10 rounded stretch-expanded text-gray-800 bg-white group-focus:bg-black group-focus:text-white group-active:bg-black group-active:text-white'
    ),
  },
  label: {
    className: clsx('block relative', 'whitespace-nowrap overflow-hidden overflow-ellipsis max-w-full text-sm', 'py-1 px-3 text-gray-500 bg-neutral-100'),
  },
}

export default steps
