/* eslint-disable no-empty-pattern */
import clsx from 'clsx'

const paginator = {
  root: {
    className: clsx(
      'inline-flex border items-center justify-center',
      'bg-white text-shadow-gray text-dark-gray gap-3 mt-4 p-2 rounded-xl'
    ),
  },
  pages: ({ }) => ({
    className: clsx('flex gap-2'),
  }),
  firstpagebutton: ({ context }) => ({
    className: clsx(
      'hidden',
      'border-0 text-dark-gray size-8 m-[0.143rem] rounded-md cursor-default',
      'transition duration-200',
      {
        'cursor-default pointer-events-none opacity-60': context.disabled,
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]': !context.disabled,
      }
    ),
  }),
  previouspagebutton: ({ context }) => ({
    className: clsx(
      'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
      'border-0 text-dark-gray size-8 rounded-md',
      'transition duration-200',
      {
        'cursor-default pointer-events-none opacity-60': context.disabled,
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]': !context.disabled,
      }
    ),
  }),
  nextpagebutton: ({ context }) => ({
    className: clsx(
      'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
      'border text-gray-500 size-8 rounded-md',
      'transition duration-200',
      {
        'cursor-default pointer-events-none opacity-60': context.disabled,
        'focus:none focus:nonefocus:none': !context.disabled,
      }
    ),
  }),

  prevpagebutton: ({ context }) => ({
    className: clsx(
      'relative inline-flex items-center justify-center user-none overflow-hidden leading-none cursor-pointer',
      'border text-dark-gray size-8 rounded-md',
      'transition duration-200',
      {
        'cursor-default pointer-events-none opacity-60': context.disabled,
        'focus:none focus:nonefocus:none': !context.disabled,
      }
    ),
  }),
  lastpagebutton: ({ context }) => ({
    className: clsx(
      'hidden',
      'border-0 text-gray-500 size-8 rounded-md',
      'transition duration-200',
      {
        'cursor-default pointer-events-none opacity-60': context.disabled,
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]': !context.disabled,
      }
    ),
  }),
  pagebutton: ({ context }) => ({
    className: clsx(
      'relative inline-flex items-center justify-center user-none overflow-hidden leading-none',
      'border text-gray-500 size-8 gap-3 rounded-md',
      'transition duration-200',
      {
        'bg-black text-white': context.active,
      }
    ),
  }),
  nextpageicon: ({ }) => ({
    className: clsx('text-dark-gray'),
  }),
  prevpageicon: ({ }) => ({
    className: clsx('text-dark-gray'),
  }),
  rowperpagedropdown: {
    root: ({ props, state }) => ({
      className: clsx(
        'inline-flex relative cursor-pointer user-none',
        'bg-white border rounded-md',
        'transition duration-200',
        'h-12 mx-2',
        {
          'outline-none outline-offset-0 shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] border-blue-500': state.focused && !props.disabled,
          'border-gray-300': !state.focused,
          'hover:border-blue-500': !props.disabled,
        }
      ),
    }),
    input: {
      className: clsx(
        'font-sans text-base text-gray-600 p-3 m-0 rounded-md appearance-none',
        'block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border-0 pr-0',
        'focus:outline-none focus:outline-offset-0'
      ),
    },
    trigger: {
      className: clsx('flex items-center justify-center shrink-0', 'text-gray-500 w-12 rounded-r-md'),
    },
    panel: {
      className: clsx('bg-white text-gray-600 border-0 rounded-md shadow-[0_2px_12px_rgba(0,0,0,0.1)]'),
    },
    wrapper: 'overflow-auto',
    list: 'm-0 p-0 py-3 list-none',
    item: ({ context }) => ({
      className: clsx(
        'relative font-normal cursor-pointer whitespace-nowrap overflow-hidden',
        'm-0 py-3 px-5 border-none text-gray-600 rounded-none',
        'transition duration-200',
        {
          'text-blue-700 bg-blue-50 dark:text-white/80 dark:bg-blue-300': !context.focused && context.selected,
          'bg-blue-300/40': context.focused && context.selected,
          'text-gray-600 bg-gray-300 dark:text-white/80 dark:bg-blue-900/40': context.focused && !context.selected,
        }
      ),
    }),
  },
  jumptopageinput: {
    root: 'inline-flex mx-2',
    input: {
      className: clsx(
        'font-sans text-base text-gray-600 p-3 m-0 rounded-md appearance-none',
        'block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border border-gray-300 pr-0',
        'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] focus:border-blue-300',
        'm-0 flex-auto max-w-[3rem]'
      ),
    },
  },
  jumptopagedropdown: {
    root: ({ props, state }) => ({
      className: clsx(
        'inline-flex relative cursor-pointer user-none',
        'bg-white border rounded-md',
        'transition duration-200',
        'h-12 mx-2',
        {
          'outline-none outline-offset-0 shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] border-blue-500': state.focused && !props.disabled,
          'border-gray-300': !state.focused,
          'hover:border-blue-500': !props.disabled,
        }
      ),
    }),
    input: {
      className: clsx(
        'font-sans text-base text-gray-600 p-3 m-0 rounded-md appearance-none',
        'block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border-0 pr-0',
        'focus:outline-none focus:outline-offset-0'
      ),
    },
    trigger: {
      className: clsx('flex items-center justify-center shrink-0', 'text-gray-500 w-12 rounded-r-md'),
    },
    panel: {
      className: clsx('bg-white text-gray-600 border-0 rounded-md shadow-[0_2px_12px_rgba(0,0,0,0.1)]'),
    },
    wrapper: 'overflow-auto',
    list: 'm-0 p-0 py-3 list-none',
    item: ({ context }) => ({
      className: clsx(
        'relative font-normal cursor-pointer whitespace-nowrap overflow-hidden',
        'm-0 py-3 px-5 border-none text-gray-600 rounded-none',
        'transition duration-200',
        {
          'text-blue-700 bg-blue-50 dark:text-white/80 dark:bg-blue-300': !context.focused && context.selected,
          'bg-blue-300/40': context.focused && context.selected,
          'text-gray-600 bg-gray-300 dark:text-white/80 dark:bg-blue-900/40': context.focused && !context.selected,
        }
      ),
    }),
  },
}

export default paginator
