import PropTypes from 'prop-types'

const Separator = ({ className }) => {
  return (
    <hr className={`border-t border-gray-border ${className}`} />
  )
}
  
Separator.propTypes = {
  className: PropTypes.string, 
}
Separator.defaultProps = {
  className: '', 
}

export default Separator