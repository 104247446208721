import SectionMedium from '@commons/section-medium'
import { ReactComponent as PowerBiIcon } from '@images/tools/powerbi.svg'
import { InputText } from 'primereact/inputtext'

import CSVUpload from './components/CSVUpload'

const KeywordRelevancyIndex = () => {
  return (
    <div className=' flex flex-col'>
      <form className='space-y-2 flex-grow'>
        <SectionMedium
          description='You can include all or only some of the selected platforms.'
          title='Use a CSV template to upload your data'>
          <p className='text-xs mb-1'>
            Upload data from a .csv file for the selected marketplaces
          </p>
          <CSVUpload className='w-[5.25rem]'/>
          <p className='text-xs pt-4 pb-1'>
            Or use a link to a PowerBi instead.
          </p>
          <div className='relative'>
            <PowerBiIcon alt='PowerBI Icon'
              className='absolute left-3 top-1/2 transform -translate-y-1/2 size-4'/>
                        
            <InputText
              className='w-full border border-gray-200 p-2 pl-8 rounded-lg text-sm'
              placeholder='http://...'
            />
          </div>
        </SectionMedium>
      </form>
    </div>
  )
}

export default KeywordRelevancyIndex
