import clsx from 'clsx'

const progressspinner = {
  root: {
    className: clsx('relative mx-auto w-28 h-28 inline-block', 'before:block before:pt-full'),
  },
  spinner: 'absolute top-0 bottom-0 left-0 right-0 m-auto w-full h-full transform origin-center animate-spin',
  circle: 'text-red-500 progress-spinner-circle',
}

export default progressspinner
