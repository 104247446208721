import { useState } from 'react'

import SectionMedium from '@commons/section-medium'
import FileLineChart from '@icons/file-line-chart.svg'
import Map from '@icons/map.svg'
import PieChart from '@icons/pie-chart.svg'
import Podcast from '@icons/podcast.svg'
import amazonIcon from '@images/tools/amazon.svg'
import carrefourIcon from '@images/tools/carrefour.svg'
import dtcIcon from '@images/tools/dtcWhite.svg'
import facebookIcon from '@images/tools/facebook.svg'
import instagramIcon from '@images/tools/instagram.svg'
import pinterestIcon from '@images/tools/pinterest.svg'
import snapchatIcon from '@images/tools/snapchat.svg'
import targetIcon from '@images/tools/target.svg'
import wallmartIcon from '@images/tools/wallmart.svg'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'

const modulesData = [
  {
    name: 'Forecast',
    description: 'Optimise your keywords by evaluating their relevance across platforms to boost visibility.',
    note: 'Upload csv file &/or PBI link',
    icon: <img alt='Carrefour' className='size-6' src={Podcast} />,
  },
  {
    name: 'Retail Media Planning',
    description: 'Manage advertising campaigns in retail ecosystems to maximise media spend impact.',
    note: 'Needs Forecast Module',
    icon: <img alt='Carrefour' className='size-6' src={Map} />,
  },
  {
    name: 'Keyword Relevancy Index',
    description: 'Optimise your keywords by evaluating their relevance across platforms to boost visibility.',
    note: 'Upload csv file &/or PBI link',
    icon: <img alt='Carrefour' className='size-6' src={PieChart} />,
  },
  {
    name: 'Retail Insights & Analytics',
    description: 'Gain insights into consumer behaviour and retail trends to improve your sales strategy.',
    note: 'Upload csv file &/or PBI link',
    icon: <img alt='Carrefour' className='size-6' src={FileLineChart} />,
  }
]

const countryOptions = [
  { label: 'France', value: 'France' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'Argentina', value: 'Argentina' }
]

const categoryOptions = [
  { label: 'Skin Treatment', value: 'Skin Treatment' },
  { label: 'Hair Care', value: 'Hair Care' },
  { label: 'Makeup', value: 'Makeup' },
  { label: 'Perfume', value: 'Perfume' },
  { label: 'Oral Care', value: 'Oral Care' },
  { label: 'Men\'s Grooming', value: 'Mens Grooming' },
  { label: 'Wellness', value: 'Wellness' },
  { label: 'Dietary Supplements', value: 'Dietary Supplements' },
  { label: 'Baby Care', value: 'Baby Care' },
  { label: 'Pet Products', value: 'Pet Products' }
]

const clientOptions = [
  { label: 'Loreal', value: 'Loreal' },
  { label: 'Pantene', value: 'Pantene' },
  { label: 'NYX Cosmetic', value: 'NYX Cosmetic' },
  { label: 'Dior', value: 'Dior' },
  { label: 'Lacer', value: 'Lacer' },
  { label: 'Gillete', value: 'Gillete' },
  { label: 'Basic Fit', value: 'Basic Fit' },
  { label: 'Nature House', value: 'Nature House' },
  { label: 'Dodoot', value: 'Dodoot' },
  { label: 'Wixcas', value: 'Wixcas' }
]

const brandOptions = [
  { label: 'Loreal', value: 'Loreal' },
  { label: 'Pantene', value: 'Pantene' },
  { label: 'NYX Cosmetic', value: 'NYX Cosmetic' },
  { label: 'Dior', value: 'Dior' },
  { label: 'Lacer', value: 'Lacer' },
  { label: 'Gillete', value: 'Gillete' },
  { label: 'Basic Fit', value: 'Basic Fit' },
  { label: 'Nature House', value: 'Nature House' },
  { label: 'Dodoot', value: 'Dodoot' },
  { label: 'Wixcas', value: 'Wixcas' }
]

const platformsData = [
  {
    id: 1,
    name: 'DTC',
    icon: dtcIcon,
  },
  {
    id: 2,
    name: 'Amazon',
    icon: amazonIcon,
  },
  {
    id: 3,
    name: 'Carrefour',
    icon: carrefourIcon,
  },
  {
    id: 4,
    name: 'Target',
    icon: targetIcon,
  },
  {
    id: 5,
    name: 'Walmart',
    icon: wallmartIcon,
  },
  {
    id: 6,
    name: 'Facebook',
    icon: facebookIcon,
  },
  {
    id: 7,
    name: 'Instagram',
    icon: instagramIcon,
  },
  {
    id: 8,
    name: 'Snapchat',
    icon: snapchatIcon,
  },
  {
    id: 9,
    name: 'Pinterest',
    icon: pinterestIcon,
  },
  {
    id: 10,
    name: 'Amazon',
    icon: amazonIcon,
  },
  {
    id: 11,
    name: 'Carrefour',
    icon: carrefourIcon,
  }
]

const ProjectSetup = () => {
  const [selectedPlatforms, setSelectedPlatforms] = useState([])
  const [selectedModules, setSelectedModules] = useState([])
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedClient, setSelectedClient] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const togglePlatform = platformId => {
    if (selectedPlatforms.includes(platformId)) {
      setSelectedPlatforms(selectedPlatforms.filter(id => id !== platformId))
    } else {
      setSelectedPlatforms([...selectedPlatforms, platformId])
    }
  }

  const toggleModule = moduleName => {
    if (selectedModules.includes(moduleName)) {
      setSelectedModules(selectedModules.filter(name => name !== moduleName))
    } else {
      setSelectedModules([...selectedModules, moduleName])
    }
  }

  const renderItems = (category, items, toggleFunction, selectedItems) => (
    <div>
      <h3 className='text-sm font-light mb-1'>{category}</h3>
      <div className='grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-2'>
        {items.map(item => (
          <div
            key={item.name}
            className={`min-h-9 flex items-center gap-3 cursor-pointer px-3 rounded-md shadow-sm text-sm font-light leading-none border ${selectedItems.includes(item.id) ? 'border-blue-active bg-blue-50' : 'border-neutral-50 bg-neutral-50'
            }`}
            onClick={() => toggleFunction(item.id)}
          >
            <div className='size-5 flex-none'>
              <img alt={item.name} className='w-full object-cover h-100' src={item.icon} />
            </div>
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  )


  return (
    <div className='flex flex-col'>
      <form className='space-y-2 flex-grow'>
        <SectionMedium
          description='Configure essential project settings to ensure proper structure and smooth collaboration.'
          title='General project setup'
        >
          <div className='mb-4 space-y-2'>
            <p className='text-xs '>Project</p>
            <InputText
              className='w-full'
              placeholder='Title...'
            />
          </div>
          <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2'>
            <div>
              <p className='flex flex-col text-xs mb-1'>Brand</p>
              <Dropdown
                options={brandOptions}
                placeholder='Select an option'
                value={selectedBrand}
                onChange={e => setSelectedBrand(e.value)}
              />
            </div>
            <div>
              <p className='flex flex-col text-xs mb-1'>Client</p>
              <Dropdown
                options={clientOptions}
                placeholder='Select an option'
                value={selectedClient}
                onChange={e => setSelectedClient(e.value)}
              />
            </div>
            <div>
              <p className='flex flex-col text-xs mb-1'>Country</p>
              <Dropdown
                options={countryOptions}
                placeholder='Select an option'
                value={selectedCountry}
                onChange={e => setSelectedCountry(e.value)}
              />
            </div>
            <div className='col-span-2'>
              <p className='flex flex-col text-xs mb-1'>Category</p>
              <Dropdown
                options={categoryOptions}
                placeholder='Select an option'
                value={selectedCategory}
                onChange={e => setSelectedCategory(e.value)}
              />
            </div>
          </div>
        </SectionMedium>
        <SectionMedium
          description='Choose the market place platforms to integrate with your store.'
          title='Select your platforms'
        >
          <div className='flex flex-col gap-4'>
            {renderItems('DTC', platformsData.slice(0, 1), togglePlatform, selectedPlatforms)}
            {renderItems('Market Place', platformsData.slice(1, 5), togglePlatform, selectedPlatforms)}
            {renderItems('Social Commerce', platformsData.slice(5, 9), togglePlatform, selectedPlatforms)}
            {renderItems('Category Revenue Benchmark', platformsData.slice(9, 11), togglePlatform, selectedPlatforms)}
          </div>
        </SectionMedium>
        <SectionMedium
          description='Explore and choose the modules that will help you optimise and improve your performance across selected platforms.'
          title='Select your Modules'
        >
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2'>
            {modulesData.map(module => (
              <div
                key={module.name}
                className={`h-[12.5rem] cursor-pointer px-4 py-2 rounded-md flex flex-col border ${selectedModules.includes(module.name) ? 'border-blue-active bg-blue-50' : 'border-neutral-50 bg-neutral-50'
                }`}
                onClick={() => toggleModule(module.name)}
              >
                <div className='flex items-center space-x-2 mb-2 h-14'>
                  <span className='size-6 flex-shrink-0'>{module.icon}</span>
                  <h3 className=' text-sm flex-1 leading-tight'>{module.name}</h3>
                </div>
                <p className='text-xs text-neutral-400 font-light line-clamp-4'>{module.description}</p>
                <div className='flex flex-1 items-end'>
                  <p className='text-xs text-neutral-400 font-light'>{module.note}</p>
                </div>
              </div>
            ))}
          </div>
        </SectionMedium>
      </form>
    </div>
  )
}

export default ProjectSetup
