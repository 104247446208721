
import ContentSettings from './layout/ContentSettings'
import MenuResultsSettings from './layout/MenuResSet'


const Settings = () => {
  return (
    <form className=' h-full flex-col flex w-full'>
      <MenuResultsSettings/>
      <div className=' container my-8'>
        <ContentSettings/>
      </div>
    </form>
  )
}

export default Settings
