import clsx from 'clsx'
import 'primereact/resources/themes/saga-blue/theme.css' 
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'


const dialog = {
  root: ({ state }) => ({

    /* Default width was w-[50vw] */
    className: clsx('rounded-lg shadow-lg border-0 top-4 bg-white', 'max-h-[90%] transform scale-100', 'm-0 px-6 py-4', {
      'transition-none transform-none !w-screen !h-screen !max-h-full !top0 !left-0': state.maximized,
    }),
  }),
  header: {
    className: clsx('flex items-center justify-between shrink-0', 'bg-white text-gray-800 border-t-0  rounded-tl rounded-tr'),
  },
  headerTitle: 'flex-grow justify-between',
  headerIcons: 'flex items-center',
  closeButton: ({ props }) => ({
    className: clsx(
      'flex items-end justify-center overflow-hidden relative',
      'size-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      { 'hidden': props.closeOnEscape }
    ),
  }),
  closeButtonIcon: 'size-4 inline-block',
  content: ({ state }) => ({
    className: clsx('overflow-y-auto', 'bg-white text-gray-700 pb-4 pt-0', 'rounded-bl rounded-br', {
      grow: state.maximized,
    }),
  }),
  footer: {
    className: clsx('shrink-0 ', 'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b'),
  },
  mask: ({ state }) => ({
    className: clsx('transition duration-200', { 'bg-black/40': state.containerVisible }),
  }),
  transition: ({ props }) => props.position === 'top'
    ? {
      enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-3/4 translate-z-0',
      enterActiveClass: 'transition-all duration-200 ease-out',
      leaveActiveClass: 'transition-all duration-200 ease-out',
      leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
    }
    : props.position === 'bottom'
      ? {
        enterFromClass: 'opacity-0 scale-75 translate-y-full',
        enterActiveClass: 'transition-all duration-200 ease-out',
        leaveActiveClass: 'transition-all duration-200 ease-out',
        leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0',
      }
      : props.position === 'left' || props.position === 'top-left' || props.position === 'bottom-left'
        ? {
          enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0',
        }
        : props.position === 'right' || props.position === 'top-right' || props.position === 'bottom-right'
          ? {
            enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
            enterActiveClass: 'transition-all duration-200 ease-out',
            leaveActiveClass: 'transition-all duration-200 ease-out',
            leaveToClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
          }
          : {
            enterFromClass: 'opacity-0 scale-75',
            enterActiveClass: 'transition-all duration-200 ease-out',
            leaveActiveClass: 'transition-all duration-200 ease-out',
            leaveToClass: 'opacity-0 scale-75',
          },
}


export default dialog
