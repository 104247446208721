import clsx from 'clsx'

const tag = {
  root: ({ props }) => ({
    className: clsx(
      'inline-flex items-center justify-center',
      'text-gray-800 text-xs px-2 py-0 h-6',
      {
        'bg-gray-300': props.severity === null,
        'bg-gray-500': props.severity === 'secondary',
        'bg-green-500': props.severity === 'success',
        'bg-blue-500': props.severity === 'info',
        'bg-yellow-500': props.severity === 'warning',
        'bg-purple-500': props.severity === 'help',
        'bg-orange-500': props.severity === 'danger',
        'bg-mb': props.severity === 'mb',
        'bg-connect': props.severity === 'connect',
        'bg-content': props.severity === 'content',
      },
      {
        'rounded': !props.rounded,
        'rounded-full': props.rounded,
      }
    ),
  }),
  value: 'leading-6 whitespace-nowrap',
  icon: 'mr-1 text-sm',
}

export default tag
