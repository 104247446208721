import clsx from 'clsx'

const selectbutton = {
  root: ({ props }) => ({
    className: clsx(
      'px-1 py-1 border rounded-3xl border-gray-600',
      { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
    ),
  }),
  button: ({ context }) => ({
    className: clsx(
      'inline-flex cursor-pointer select-none items-center align-bottom text-center overflow-hidden relative',
      'px-3 py-1',
      'transition duration-200',
      'focus:outline-none',
      {
        'bg-white text-gray-700 rounded-3xl': !context.selected,
        'bg-black text-white hover:bg-gray-800 rounded-3xl': context.selected,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
      }
    ),
  }),
  label: 'font-normal text-xs',
}

export default selectbutton
