import { useState } from 'react'

import { ReactComponent as AmazonIcon } from '@images/tools/amazon.svg'
import { ReactComponent as AuchanIcon } from '@images/tools/auchan.svg'
import { ReactComponent as CarrefourIcon } from '@images/tools/carrefour.svg'
import { ReactComponent as EleclercIcon } from '@images/tools/eleclerc.svg'
import { Dropdown } from 'primereact/dropdown'

import DetailedResults from './detailed1'
import DetailedResults2 from './detailed2'
import DetailedResults3 from './detailed3'
import OverviewResults from './overview'

const TabSwitcher = () => {
  const [activeTab, setActiveTab] = useState('overview')
  const [activeTier, setActiveTier] = useState('tier1')
  const [selectedMarketplace, setSelectedMarketplace] = useState(null) 

  const marketplaces = [
    { value: 'amazon', label: 'Amazon', icon: <AmazonIcon className='inline-block w-4 h-4 mr-1' /> },
    { value: 'auchan', label: 'Auchan', icon: <AuchanIcon className='inline-block w-4 h-4 mr-1' /> },
    { value: 'carrefour', label: 'Carrefour', icon: <CarrefourIcon className='inline-block w-4 h-4 mr-1' /> },
    { value: 'eleclerc', label: 'E.Leclerc', icon: <EleclercIcon className='inline-block w-4 h-4 mr-1' /> }
  ]

  const marketplaceOptionTemplate = option => {
    if (!option) {
      return <span>Select Marketplace</span>
    }

    return (
      <div className='flex items-center gap-2'>
        {option.icon}
        <span>{option.label}</span>
      </div>
    )
  }

  const selectedMarketplaceTemplate = option => {
    if (!option) {
      return <div className='flex items-center gap-2'>
        <AmazonIcon className='inline-block w-4 h-4 mr-1'/>
        <span>Amazon</span>
      </div>
    }

    return (
      <div className='flex items-center gap-2'>
        {option.icon}
        <span>{option.label}</span>
      </div>
    )
  }

  return (
    <div className='space-y-4'>
      {activeTab === 'detailed' && (
        <div className='flex mb-4 border-b border-gray-300 '>
          <button
            className={`text-sm px-3 ${activeTier === 'tier1' ? 'font-semibold border-b-2 border-black' : 'text-gray-500'}`}
            onClick={() => setActiveTier('tier1')}
          >
            Tier 1
          </button>
          <button
            className={`text-sm px-3 ${activeTier === 'tier2' ? 'font-semibold border-b-2 border-black' : 'text-gray-500'}`}
            onClick={() => setActiveTier('tier2')}
          >
            Tier 2
          </button>
          <button
            className={`text-sm px-3 ${activeTier === 'tier3' ? 'font-semibold border-b-2 border-black' : 'text-gray-500'}`}
            onClick={() => setActiveTier('tier3')}
          >
            Tier 3
          </button>
        </div>
      )}

      <div className='flex flex-row justify-between'>
        <div className='bg-white border border-gray-200 rounded-md p-1 space-x-4 text-xs items-center flex'>
          <button className={`px-2 py-1 rounded ${activeTab === 'overview' ? 'bg-gray-100 font-semibold' : ''}`} onClick={() => setActiveTab('overview')}>
            Overview Results
          </button>
          <button className={`px-2 py-1 rounded ${activeTab === 'detailed' ? 'bg-gray-100 font-semibold' : ''}`} onClick={() => setActiveTab('detailed')}>
            Detailed Results
          </button>
        </div>

        {activeTab === 'detailed' && (
          <div className='w-40'>
            <Dropdown
              className='h-9'
              itemTemplate={marketplaceOptionTemplate}
              optionLabel='label'
              options={marketplaces}
              placeholder='Select'
              size='small'
              value={selectedMarketplace} 
              valueTemplate={selectedMarketplaceTemplate} 
              onChange={e => setSelectedMarketplace(e.value)}
            />
          </div>
        )}
      </div>
      {activeTab === 'overview' && <OverviewResults />}
      {activeTab === 'detailed' && (
        <>
          {activeTier === 'tier1' && <DetailedResults />}
          {activeTier === 'tier2' && <DetailedResults2 />}
          {activeTier === 'tier3' && <DetailedResults3 />}
        </>
      )}
    </div>
  )
}

export default TabSwitcher
