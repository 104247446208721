import { useState } from 'react'

import StepsForecast from '../sections/StepsForecast'

const ForecastPage = () => {
  const [activeStep, setActiveStep] = useState('1') // Controla el paso activo

  return (
    <div>
      <StepsForecast activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )
}

export default ForecastPage
