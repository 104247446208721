/* eslint-disable eqeqeq */
/* eslint-disable no-eq-null */
/* eslint-disable complexity */
/* eslint-disable no-undef */
/*
 * Design System with Tailwind for components using primeReact
 * import Tailwid from 'primereact/passthrough/tailwind'
 */

import autocomplete from './autocomplete.ui.config'
import avatar from './avatar.ui.config'
import button from './button.ui.config'
import calendar from './calendar.ui.config'
import checkbox from './checkbox.ui.config'
import datatable from './dataTable.ui.config'
import dialog from './dialog.ui.config'
import dropdown from './dropdown.ui.config'
import fileupload from './fileupload.ui.config'
import inputswitch from './inputSwitch.ui.config'
import inputtext from './inputtext.ui.config'
import inputtextarea from './inputtextarea.ui.config'
import menu from './menu.ui.config'
import message from './message.ui.config'
import multiselect from './multiselect.ui.config'
import orderlist from './orderList.ui.config'
import overlaypanel from './overlaypanel.ui.config'
import paginator from './paginator.ui.config'
import progressspinner from './progressspiner.ui.config'
import radiobutton from './radiobutton.ui.config'
import selectbutton from './selectbutton.ui.config'
import sidebar from './sidebar.ui.config'
import skeleton from './skeleton.ui.config'
import steps from './steps.ui.config'
import tag from './tag.ui.config'
import toolbar from './toolbar.ui.config'
import tooltip from './tooltip.ui.config'
import tree from './tree.ui.config'

const CustomTailwindDesignSystem = {
  autocomplete,
  avatar,
  button,
  dropdown,
  skeleton,
  tag,
  inputswitch,
  dialog,
  checkbox,
  multiselect,
  toolbar,
  inputtext,
  inputtextarea,
  overlaypanel,
  message,
  progressspinner,
  tooltip,
  menu,
  sidebar,
  steps,
  tree,
  datatable,
  paginator,
  calendar,
  radiobutton,
  fileupload,
  orderlist,
  selectbutton,
}


export default CustomTailwindDesignSystem
