import { Button } from 'primereact/button'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
const sections = [
  'Project Setup',
  'Forecast',
  'Retail Media Planning',
  'Keyword Relevancy Index',
  'Retail Insights'
]

const NavigationButtons = ({ selectedSection, setSelectedSection, markSectionComplete, label, icon }) => {
  const navigate = useNavigate()

  const handleCancel = e => {
    e.preventDefault()
    navigate('./ListProjects')
  }

  const handleNext = e => {
    e.preventDefault()
    const currentIndex = sections.indexOf(selectedSection)
    const nextIndex = (currentIndex + 1) % sections.length

    setSelectedSection(sections[nextIndex])
    markSectionComplete(selectedSection)
  }

  return (
    <div className='flex justify-between space-x-3'>

      <Button
        aria-label='Cancel'
        label='Cancel'
        severity='secondary'
        onClick={handleCancel} />

      <Button
        aria-label={label}
        icon={icon}
        iconPos='right'
        label={label}
        severity='primary'
        onClick={handleNext}
      />
    </div>
  )
}

NavigationButtons.propTypes = {
  selectedSection: PropTypes.string.isRequired,
  setSelectedSection: PropTypes.func.isRequired,
  markSectionComplete: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default NavigationButtons
