import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'

const DonutChartWithLegend = ({ data, title, subtitle }) => {
  const chartOptions = {
    chart: {
      type: 'pie',
      height: '260px',
      spacing: [0, 0, 0, 0],
      margin:[0, 0, 0, 0],
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        innerSize: '50%',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        borderWidth: 7,
        borderColor: '#ffffff',
      },
    },
    series: [
      {
        name: 'Retailers',
        data: data.map(item => ({
          name: item.label,
          y: item.value,
          color: item.color,
        })),
      }
    ],
  }

  return (
    <div className='flex items-center space-x-6'>
      <div className='w-[18.75rem] border rounded-lg p-4 h-full'>
        <h3 className='font-light'>{title}</h3>
        <p className='text-xs text-gray-600'>{subtitle}</p>
        <div className='space-y-3 mt-4'>
          {data.map((item, index) => (
            <div
              key={index}
              className='flex items-center justify-between '
            >
              <div className='flex items-center gap-3 border py-1 px-2 rounded-sm mr-2 bg-gray-100 shadow-sm w-[15rem]'>
                <span className='size-4'>{item.icon}</span>
                <span className='text-sm font-light'>{item.label}</span>
              </div>
              <div
                className='size-7 rounded-sm flex-none'
                style={{ backgroundColor: item.color }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className='w-3/5'>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  )
}

DonutChartWithLegend.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      icon: PropTypes.element, 
    })
  ).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default DonutChartWithLegend
