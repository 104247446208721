import { useState } from 'react'

import { ReactComponent as AmazonIcon } from '@images/tools/amazon.svg'
import { ReactComponent as AuchanIcon } from '@images/tools/auchan.svg'
import { ReactComponent as CarrefourIcon } from '@images/tools/carrefour.svg'
import { ReactComponent as ELeclercIcon } from '@images/tools/eleclerc.svg'
import { Checkbox } from 'primereact/checkbox'
import PropTypes from 'prop-types'

const IconBar = ({ isBudgetSplitHidden, setIsBudgetSplitHidden }) => {
  const [selectedVendors, setSelectedVendors] = useState({
    amazon: false,
    auchan: false,
    carrefour: false,
    eleclerc: false,
  })

  const handleVendorToggle = vendor => {
    setSelectedVendors(prevState => ({
      ...prevState,
      [vendor]: !prevState[vendor],
    }))
  }

  return (
    <div className='flex items-center justify-between pb-4'>
      <div className='flex space-x-6 items-center'>
        <div className='flex items-center space-x-2'>
          <Checkbox
            checked={selectedVendors.amazon}
            className='size-3 text-black bg-white border-gray-400 rounded-sm'
            inputId='amazon'
            onChange={() => handleVendorToggle('amazon')}
          />
          <AmazonIcon className='size-4' />
          <label className='text-xs' htmlFor='amazon'>Amazon</label>
        </div>
        {/* Auchan */}
        <div className='flex items-center space-x-2'>
          <Checkbox
            checked={selectedVendors.auchan}
            className='size-3 text-black bg-white border-gray-400 rounded-sm'
            inputId='auchan'
            onChange={() => handleVendorToggle('auchan')}
          />
          <AuchanIcon className='size-4' />
          <label className='text-xs' htmlFor='auchan'>Auchan</label>
        </div>
        <div className='flex items-center space-x-2'>
          <Checkbox
            checked={selectedVendors.carrefour}
            className='size-3 text-black bg-white border-gray-400 rounded-sm'
            inputId='carrefour'
            onChange={() => handleVendorToggle('carrefour')}
          />
          <CarrefourIcon className='size-4' />
          <label className='text-xs' htmlFor='carrefour'>Carrefour</label>
        </div>
        <div className='flex items-center space-x-2'>
          <Checkbox
            checked={selectedVendors.eleclerc}
            className='size-3 text-black bg-white border-gray-400 rounded-sm'
            inputId='eleclerc'
            onChange={() => handleVendorToggle('eleclerc')}
          />
          <ELeclercIcon className='size-4' />
          <label className='text-xs' htmlFor='eleclerc'>Eleclerc</label>
        </div>
      </div>
      <div className='flex items-center'>
        <div
          className={`relative inline-block w-8 h-4 rounded-full cursor-pointer transition duration-200 ${isBudgetSplitHidden ? 'bg-black' : 'bg-gray-300'
          }`}
        >
          <span
            className={`absolute left-[3px] top-[3px] size-[10px] bg-white rounded-full shadow transform transition-transform duration-200 ${isBudgetSplitHidden ? 'translate-x-4' : ''
            }`}
            onClick={() => setIsBudgetSplitHidden(!isBudgetSplitHidden)}
          />
        </div>
        <label className='ml-2 text-xs'>Hide Budget Split</label>
      </div>
    </div>
  )
}

IconBar.propTypes = {
  isBudgetSplitHidden: PropTypes.bool.isRequired,
  setIsBudgetSplitHidden: PropTypes.func.isRequired,
}

export default IconBar
