const Footer = () => {
  return (
    <footer className='bg-black text-2xs text-white py-2 px-6 flex items-center justify-between'>
      <div className='text-2xs flex items-center gap-3'>
        <span>All rights reserved</span>
        <span>·</span>

        <a className='underline' href='https://your-support-url.com'>Portal Design Support</a>
      </div>
      <span className='uppercase'>© 2024 HAVAS</span>
    </footer>
  )
}

export default Footer

