
/* eslint-disable complexity */

import clsx from 'clsx'

/*
 *
 *
 * //!Default primreact unstyled mode button styles using tailwindcss
 */

const button = {
  root: ({ props, context }) => ({
    className: clsx(
      'items-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom',
      'transition duration-200 ease-in-out',
      'focus:outline-none focus:outline-offset-0',
      {
        'text-white bg-gray-800 border border-gray-800 hover:bg-gray-800 hover:border-gray-800':
                    !props.link && props.severity === null && !props.text && !props.outlined && !props.plain,
        'text-gray-500 bg-transparent border-transparent':
                    props.link,
      },
      { 'text-white bg-primary border border-black':
                    props.severity === 'primary' && !props.text && !props.outlined && !props.plain,
      'text-neutral-200 bg-transparent':
                    props.severity === 'button',
      'text-black bg-transparent border border-black':
                    props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
      'text-white bg-green-500 border border-green-500 hover:bg-green-600 hover:border-green-600':
                    props.severity === 'success' && !props.text && !props.outlined && !props.plain,
      'text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600':
                    props.severity === 'info' && !props.text && !props.outlined && !props.plain,
      'text-white bg-orange-500 border border-orange-500 hover:bg-orange-600 hover:border-orange-600':
                    props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
      'text-white bg-purple-500 border border-purple-500 hover:bg-purple-600 hover:border-purple-600':
                    props.severity === 'help' && !props.text && !props.outlined && !props.plain,
      'text-lime-500 bg-red-500 border border-red-500 hover:bg-red-600 hover:border-red-600':
                    props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
      'text-gray-500 bg-white border border-white hover:bg-neutral-50 hover:border-neutral-50':
                    props.severity === 'white' && !props.text && !props.outlined && !props.plain,
      'text-neutral-900 bg-neutral-100 border border-neutral-100 hover:bg-neutral-200 hover:border-neutral-200':
                    props.severity === 'light' && !props.text && !props.outlined && !props.plain,
      'text-gray-800 bg-accent border border-accent hover:bg-accent hover:border-accent':
                    props.severity === 'accent' && !props.text && !props.outlined && !props.plain,
      },
      { 'shadow-lg': props.raised },
      { 'rounded': !props.rounded, 'rounded-full': props.rounded },
      {
        'bg-transparent border-transparent': props.text && !props.plain,
        'text-blue-500 hover:bg-blue-300/20': props.text && (props.severity === null || props.severity === 'info') && !props.plain,
        'text-gray-500 hover:bg-gray-300/20': props.text && props.severity === 'secondary' && !props.plain,
        'text-green-500 hover:bg-green-300/20': props.text && props.severity === 'success' && !props.plain,
        'text-orange-500 hover:bg-orange-300/20': props.text && props.severity === 'warning' && !props.plain,
        'text-purple-500 hover:bg-purple-300/20': props.text && props.severity === 'help' && !props.plain,
        'text-red-500 hover:bg-red-300/20': props.text && props.severity === 'danger' && !props.plain,
        'text-white hover:bg-neutral-300/20': props.text && props.severity === 'white' && !props.plain,
        'text-neutral-500 hover:bg-neutral-300/20': props.text && props.severity === 'light' && !props.plain,
        'text-gray-800 hover:bg-accent/20': props.text && props.severity === 'accent' && !props.plain,
      },
      { 'shadow-lg': props.raised && props.text },
      {
        'text-gray-500 hover:bg-gray-300/20': props.plain && props.text,
        'text-gray-500 border border-gray-500 hover:bg-gray-300/20': props.plain && props.outlined,
        'text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600': props.plain && !props.outlined && !props.text,
      },
      {
        'bg-transparent border': props.outlined && !props.plain,
        'text-gray-800 border border-gray-800 hover:bg-gray-300/20': props.outlined && (props.severity === null || props.severity === 'info') && !props.plain,
        'text-gray-500 border border-gray-500 hover:bg-gray-300/20': props.outlined && props.severity === 'secondary' && !props.plain,
        'text-green-500 border border-green-500 hover:bg-green-300/20': props.outlined && props.severity === 'success' && !props.plain,
        'text-orange-500 border border-orange-500 hover:bg-orange-300/20': props.outlined && props.severity === 'warning' && !props.plain,
        'text-purple-500 border border-purple-500 hover:bg-purple-300/20': props.outlined && props.severity === 'help' && !props.plain,
        'text-red-500 border border-red-500 hover:bg-red-300/20': props.outlined && props.severity === 'danger' && !props.plain,
        'text-white border border-white hover:bg-neutral-300/20': props.outlined && props.severity === 'white' && !props.plain,
        'text-neutral-300 border border-neutral-500 hover:border-neutral-300': props.outlined && props.severity === 'light' && !props.plain,
        'text-gray-800 border border-accent hover:bg-accent/20': props.outlined && props.severity === 'accent' && !props.plain,
      },
      { 'text-sm gap-x-2 h-9 xl:h-10': props.size === null, 'text-[0.625rem] gap-x-1 h-6': props.size === 'xsmall', 'text-xs gap-x-2 h-8': props.size === 'small', 'text-base gap-x-3 h-14': props.size === 'large' },
      { 'px-4': props.size === null && props.label, 'px-1.5': props.size === 'xsmall' && props.label, 'px-2.5': props.size === 'small' && props.label, 'px-8': props.size === 'large' && props.label },
      { 'w-10': props.size === null && props.icon && !props.label, 'w-6': props.size === 'xsmall' && props.icon && !props.label, 'w-8': props.size === 'small' && props.icon && !props.label, 'w-14': props.size === 'large' && props.icon && !props.label },
      // { 'flex-column': props.iconPos === 'top' || props.iconPos === 'bottom' },
      { 'justify-center items-center': props.icon && !props.label },
      { 'opacity-60 pointer-events-none cursor-default': context.disabled },
      // Position icon to the right of the label
      { 'flex-row-reverse': props.iconPos === 'right' && props.label }
    ),
  }),
  label: ({ props }) => ({
    className: clsx(
      'flex-1',
      'duration-200',

      /*
       * {
       *   'hover:underline': props.link,
       * },
       */
      { 'hidden': !props.label }
    ),
  }),
  icon: ({ props }) => ({
    className: clsx('mx-0', {
      // 'mr-2': props.iconPos === 'left' && props.label,
      'order-1': props.iconPos === 'right' && props.label,
      'mb-2': props.iconPos === 'top' && props.label,
      'mt-2 order-2': props.iconPos === 'bottom' && props.label,
    }),
  }),
  loadingIcon: ({ props }) => ({
    className: clsx('mx-0', {
      // 'mr-2': props.loading && props.iconPos === 'left' && props.label,
      'order-1': props.loading && props.iconPos === 'right' && props.label,
      'mb-2': props.loading && props.iconPos === 'top' && props.label,
      'mt-2 order-2': props.loading && props.iconPos === 'bottom' && props.label,
    }),
  }),
  badge: ({ props }) => ({
    className: clsx({ 'ml-2 size-4 leading-none flex items-center justify-center': props.badge }),
  }),
}


export default button
