import { useState } from 'react'

import { ReactComponent as ArrowRightIcon } from '@icons/arrow-right.svg'
import { ReactComponent as Check } from '@icons/check.svg'
import { ReactComponent as FileLineChart } from '@icons/file-line-chart.svg'
import { ReactComponent as Map } from '@icons/map.svg'
import { ReactComponent as PieChart } from '@icons/pie-chart.svg'
import { ReactComponent as Podcast } from '@icons/podcast.svg'
import { ReactComponent as Required } from '@icons/required.svg'

import ForecastPage from './ForecastPage'
import NavigationButtons from '../../../components/navigationButton'
import KeywordRelevancyIndex from '../sections/KeywordRelevancyIndex'
import ProjectSetup from '../sections/ProjectSetup'
import RetailInsights from '../sections/RetailInsights'
import RetailMediaPlanning from '../sections/RetailMediaPlanning'


const ContentSettings = () => {
  const [selectedSection, setSelectedSection] = useState('Project Setup')
  const [completedSections, setCompletedSections] = useState(new Set())

  const handleSelectSection = (e, section) => {
    e.preventDefault()
    setSelectedSection(section)
  }

  const markSectionComplete = section => {
    setCompletedSections(new Set([...completedSections, section]))
  }

  const renderSectionContent = () => {
    switch (selectedSection) {
    case 'Project Setup':
      return <ProjectSetup />
    case 'Forecast':
      return <ForecastPage />
    case 'Retail Media Planning':
      return <RetailMediaPlanning />
    case 'Keyword Relevancy Index':
      return <KeywordRelevancyIndex />
    case 'Retail Insights':
      return <RetailInsights />
    default:
      return <ForecastPage />
    }
  }

  return (
    <div className='flex gap-5 xl:gap-8'>
      <aside className='h-full overflow-y-auto flex flex-col w-56 xl:w-[250px] flex-none'>
        <div className='space-y-2'>
          <div>
            <div className='py-3 px-2'>
              <h3 className='text-neutral-500 text-xs leading-none'>SETUP</h3>
            </div>
            <ul className='flex flex-col space-y-2'>
              <li>
                <button
                  className={`flex items-center gap-2 w-full text-left py-2 px-4 rounded-md justify-between 
                    ${selectedSection === 'Project Setup' ? 'bg-primary text-white fill-white' : 'hover:bg-neutral-200'}`}
                  onClick={e => handleSelectSection(e, 'Project Setup')}
                >
                  <span className='text-sm block leading-4 font-light'>Project Setup</span>
                  {completedSections.has('Project Setup') ?
                    <div className='bg-custom-green rounded-full text-white'><Check alt='Completed' className='size-6 p-1' /> </div> : <div className='bg-gray-300 rounded-full text-white'><Required alt='Required' className='size-6' /></div>}
                </button>
              </li>
            </ul>
          </div>
          <div>
            <div className='py-3 px-2'>
              <h3 className='text-neutral-500 text-xs leading-none'>MODULES</h3>
            </div>
            <ul className='flex flex-col space-y-2'>
              <li>
                <button
                  className={`flex items-center space-x-2 w-full text-left py-2 px-4 rounded-md justify-between min-h-12
                    ${selectedSection === 'Forecast' ? 'bg-primary text-white fill-white' : completedSections.has('Project Setup') ? 'hover:bg-neutral-200' : 'opacity-40 hover:bg-none'}`}
                  onClick={e => handleSelectSection(e, 'Forecast')}
                >
                  <div className='flex items-center gap-2'>
                    <Podcast className='w-6 h-6' />
                    <span className='text-sm block leading-4 font-light'>Forecast</span>
                  </div>
                  {completedSections.has('Forecast') ?
                    <div className='bg-custom-green rounded-full text-white'><Check alt='Completed' className='size-6 p-1' /> </div> : <div className='bg-gray-300 rounded-full text-white'><Required alt='Required' className='size-6' /></div>}

                </button>
              </li>
              <li>
                <button
                  className={`flex items-center space-x-2 w-full text-left py-2 px-4 rounded-md justify-between min-h-12
                    ${selectedSection === 'Retail Media Planning' ? 'bg-primary text-white fill-white' : completedSections.has('Project Setup') ? 'hover:bg-neutral-200' : 'opacity-40 hover:bg-none'}`}
                  onClick={e => handleSelectSection(e, 'Retail Media Planning')}
                >
                  <div className='flex items-center gap-2'>
                    <Map className='w-6 h-6' />
                    <span className='text-sm block leading-4 font-light'>Retail Media Planning</span>
                  </div>
                  {completedSections.has('Retail Media Planning') ?
                    <div className='bg-custom-green rounded-full text-white'><Check alt='Completed' className='size-6 p-1' /> </div> : <div className='bg-gray-300 rounded-full text-white'><Required alt='Required' className='size-6' /></div>}


                </button>
              </li>
              <li>
                <button
                  className={`flex items-center space-x-2 w-full text-left py-2 px-4 rounded-md justify-between min-h-12
                    ${selectedSection === 'Keyword Relevancy Index' ? 'bg-primary text-white fill-white' : completedSections.has('Project Setup') ? 'hover:bg-neutral-200' : 'opacity-40 hover:bg-none'}`}
                  onClick={e => handleSelectSection(e, 'Keyword Relevancy Index')}
                >
                  <div className='flex items-center gap-2'>
                    <PieChart className='w-6 h-6' />
                    <span className='text-sm block leading-4 font-light'>Keyword <br /> Relevancy Index</span>
                  </div>
                  {completedSections.has('Keyword Relevancy Index') ?
                    <div className='bg-custom-green rounded-full text-white'><Check alt='Completed' className='size-6 p-1' /> </div> : <div className='bg-gray-300 rounded-full text-white'><Required alt='Required' className='size-6' /></div>}

                </button>
              </li>
              <li>
                <button
                  className={`flex items-center space-x-2 w-full text-left py-2 px-4 rounded-md justify-between min-h-12
                    ${selectedSection === 'Retail Insights' ? 'bg-primary text-white fill-white' : completedSections.has('Project Setup') ? 'hover:bg-neutral-200' : 'opacity-40 hover:bg-none'}`}
                  onClick={e => handleSelectSection(e, 'Retail Insights')}
                >
                  <div className='flex items-center gap-2'>
                    <FileLineChart className='w-6 h-6' />
                    <span className='text-sm block leading-4 font-light'>Retail Insights & Analytics</span>
                  </div>
                  {completedSections.has('Retail Insights') ?
                    <div className='bg-custom-green rounded-full text-white'><Check alt='Completed' className='size-6 p-1' /> </div> : <div className='bg-gray-300 rounded-full text-white'><Required alt='Required' className='size-6' /></div>}

                </button>
              </li>
            </ul>           
          </div>
        </div>
      </aside>

      <div className='grow min-w-0'>
        {renderSectionContent()}
        <div className='py-8 font-light text-sm'> <NavigationButtons icon={<ArrowRightIcon className='size-5' />} label='Save and Next' markSectionComplete={markSectionComplete} selectedSection={selectedSection} setSelectedSection={setSelectedSection} /></div>
      </div>
    </div>
  )
}

export default ContentSettings


