import  { useState } from 'react'

import PropTypes from 'prop-types'

const Pagination = ({ totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const handleClick = page => {
    setCurrentPage(page)
    onPageChange(page) 
  }

  return (
    <div className='flex justify-center space-x-2 mt-4'>
      {[...Array(totalPages)].map((_, index) => {
        const page = index + 1

        return (
          <button
            key={page}
            className={`size-8 xl:size-9 rounded border  text-center text-sm xl:text-base 
              ${currentPage === page ? 'bg-black border-black text-white' : 'bg-white text-black border-neutral-200'}`}
            onClick={() => handleClick(page)}
          >
            {page}
          </button>
        )
      })}
    </div>
  )
}

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

export default Pagination

