import PropTypes from 'prop-types'

const MarketShareChart = ({ title, data, barColor }) => {
  return (
    <div className='bg-white p-6 rounded-lg border border-gray-icon w-full h-full'>
      <h2 className='text-center text-xs stretch-extra-expanded mb-3 pb-2 border-b-2 border-gray-100'>{title}</h2>
      <div className='flex flex-col'>
        {data.map((item, index) => (
          <div key={index} className='flex items-center'>
            <span className='w-1/2 text-xs border-r-2 pb-3'>{item.label}</span>

            <div className='w-1/2 flex items-center '>
              <div
                className='h-[0.625rem] rounded-r-full'
                style={{ width: `${item.value}%`, backgroundColor: barColor }}
              ></div>

              <span className='ml-2 text-xs text-gray-800'>{item.value}%</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

MarketShareChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  barColor: PropTypes.string,
}

MarketShareChart.defaultProps = {
  barColor: '#10AC94',
}

export default MarketShareChart
