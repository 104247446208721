
import PropTypes from 'prop-types'

const FilterGroup = ({ filter, data }) => {
  return (
    <div className='flex border border-neutral-200 rounded text-xs'>
      <span className='bg-neutral-200 py-1 px-2'>{filter}</span>
      <span className='py-1 px-2'>{data}</span>
    </div>
  )
}

FilterGroup.propTypes = {
  filter: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
}

export default FilterGroup