import clsx from 'clsx'

const sidebar = {
  root: ({ props }) => ({
    className: clsx(
      'flex flex-col pointer-events-auto relative transform translate-x-0 translate-y-0 translate-z-0 relative transition-transform duration-300',
      'bg-white text-gray-700 border-0 shadow-lg',
      {
        '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': props.position === 'full',
        'h-full': props.position === 'left' || props.position === 'right',
        'h-40 w-full': props.position === 'top' || props.position === 'bottom',
        'w-80': !props.size || props.size === 'small', 'w-[850px]': props.size === 'large',
      }
    ),
  }),
  header: ({ props }) => ({
    className: clsx(
      'flex items-center justify-between', 'py-6 px-12',
      { 'bg-gray-800 text-white': props.template === 'help' }
    ),
  }),
  closeButton: {
    className: clsx(
      'flex items-center justify-center overflow-hidden relative',
      'w-8 h-8 text-white border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0'
    ),
  },
  closeIcon: 'w-6 h-6 inline-block',
  content: ({ props }) => ({
    className: clsx(
      'p-0 h-full w-full', 'grow overflow-y-auto',
      { 'flex flex-col': props.template === 'help' }
    ),
  }),
  mask: {
    className: clsx('flex pointer-events-auto', 'bg-black bg-opacity-40 transition duration-200 z-20 transition-colors'),
  },
  transition: ({ props }) => props.position === 'top'
    ? {
      enterFromClass: 'translate-x-0 -translate-y-full translate-z-0',
      leaveToClass: 'translate-x-0 -translate-y-full translate-z-0',
    }
    : props.position === 'bottom'
      ? {
        enterFromClass: 'translate-x-0 translate-y-full translate-z-0',
        leaveToClass: 'translate-x-0 translate-y-full translate-z-0',
      }
      : props.position === 'left'
        ? {
          enterFromClass: '-translate-x-full translate-y-0 translate-z-0',
          leaveToClass: '-translate-x-full translate-y-0 translate-z-0',
        }
        : props.position === 'right'
          ? {
            enterFromClass: 'translate-x-full translate-y-0 translate-z-0',
            leaveToClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
          }
          : {
            enterFromClass: 'opacity-0',
            enterActiveClass: 'transition-opacity duration-400 ease-in',
            leaveActiveClass: 'transition-opacity duration-400 ease-in',
            leaveToClass: 'opacity-0',
          },
}


export default sidebar
