import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'
import HighchartsReact from 'highcharts-react-official'

highchartsMore(Highcharts)
solidGauge(Highcharts)

const circleChart = () => {
  const options = {
    chart: {
      type: 'solidgauge',
      height: '90%',
      backgroundColor: 'transparent',
    },
    title: null,
    pane: {
      center: ['50%', '50%'],
      size: '100%',
      startAngle: 0,
      endAngle: 360,
      background: {
        backgroundColor: '#f0f0f0',
        borderWidth: 0,
        innerRadius: '72.3%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: 10,
      stops: [[0.25, '#673ab7']],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 1,
      labels: {
        enabled: false,
      },
    },
    plotOptions: {
      solidgauge: {
        rounded: true,
        innerRadius: '72.3%',
        outerRadius: '100%',
        dataLabels: {
          y: -18,
          borderWidth: 0,
          useHTML: true,
          format: '<div style="text-align:center"><span style="font-size:20px; font-weight:300">{y}</span></div>',  // Fuente más fina
        },
      },
    },
    series: [
      {
        name: 'Performance',
        data: [3.2],
        dataLabels: {
          format: '<div style="text-align:center"><span style="font-size:24px; color: black; font-weight:400">{y}</span></div>',  // Texto más fino para el valor
        },
      }
    ],
    credits: {
      enabled: false,
    },
  }

  return (
    <div className='flex items-center space-x-4'>
      <div className='w-36'>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div>
        <p className='text-sm'>Performance <br /> Score</p>
      </div>
    </div>
  )
}

export default circleChart
