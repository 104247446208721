import { useState } from 'react'

import { ReactComponent as Import } from '@icons/dowload.svg'
import { ReactComponent as Export } from '@icons/upload.svg'
import { Dropdown } from 'primereact/dropdown'
import PropTypes from 'prop-types'


const MenuExportImport = ({ setData, showExport = false, showImport = false }) => {
  const [selectedPlatform, setSelectedPlatform] = useState('All platforms')
  const [selectedAnswer, setSelectedAnswer] = useState('All answers')

  const platforms = [
    { label: 'All platforms', value: 'All platforms' },
    { label: 'ELeclerc', value: 'ELeclerc' },
    { label: 'Auchan', value: 'Auchan' },
    { label: 'Carrefour', value: 'Carrefour' },
    { label: 'Amazon', value: 'Amazon' }
  ]

  const answers = [
    { label: 'All answers', value: 'All answers' },
    { label: 'ELeclerc answers', value: 'ELeclerc answers' },
    { label: 'Auchan answers', value: 'Auchan answers' },
    { label: 'Carrefour answers', value: 'Carrefour answers' },
    { label: 'Amazon answers', value: 'Amazon answers' }
  ]

  const handleImport = event => {
    const fileReader = new FileReader()

    fileReader.readAsText(event.target.files[0], 'UTF-8')
    fileReader.onload = e => {
      const importedData = JSON.parse(e.target.result)
 
      setData(importedData)
    }
  }

  return (
    <div className='flex gap-2 p-1 text-xs border border-gray-200 rounded'>
      <div className='grow xl:w-[8.75rem]'>
        <Dropdown
          options={platforms}
          placeholder='Select Platform'
          size='xsmall'
          value={selectedPlatform}
          onChange={e => setSelectedPlatform(e.value)}
        />
      </div>
      <div className='grow xl:w-[8.75rem]'>
        <Dropdown
          options={answers}
          placeholder='Select Answer'
          size='xsmall'
          value={selectedAnswer}
          onChange={e => setSelectedAnswer(e.value)}
        />
      </div>

      {showExport && (
        <label className='flex items-center gap-2 py-1 px-3 rounded bg-gray-100 cursor-pointer font-medium'>
          Export
          <Export className='w-4 h-4' />
          <input style={{ display: 'none' }} type='file' onChange={handleImport} />
        </label>
      )}

      {showImport && (
        <label className='flex items-center gap-2 py-1 px-3 rounded bg-gray-100 cursor-pointer font-medium'>
          Import
          <Import className='w-4 h-4' />
          <input style={{ display: 'none' }} type='file' onChange={handleImport} />
        </label>
      )}
    </div>
  )
}

MenuExportImport.propTypes = {
  setData: PropTypes.func.isRequired,
  showExport: PropTypes.bool,
  showImport: PropTypes.bool,  
}

export default MenuExportImport
