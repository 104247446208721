import SectionIconSide from '@commons/section-side-icon'
import DesignIcon from '@images/design.svg'
import { ReactComponent as AmazonIcon } from '@images/tools/amazon.svg'
import { ReactComponent as AuchanIcon } from '@images/tools/auchan.svg'
import { ReactComponent as CarrefourIcon } from '@images/tools/carrefour.svg'
import { ReactComponent as ELeclercIcon } from '@images/tools/eleclerc.svg'

import DonutChartWithLegend from './DonutChart'
import PerformanceTable from './tableSplit'

const dataDonut = [
  { label: 'Amazon', value: 40, color: '#f28e2c', icon: <AmazonIcon /> },
  { label: 'Auchan', value: 30, color: '#4c9f70', icon: <AuchanIcon /> },
  { label: 'Carrefour', value: 20, color: '#4e79a7', icon: <CarrefourIcon /> },
  { label: 'ELeclerc', value: 10, color: '#76b7b2', icon: <ELeclercIcon /> }
]
const data = [
  {
    retailer: 'Amazon',
    weightedGrade: 3.5,
    partnership: 'High Level',
    tierMultiplier: '409.23',
    allocation: '17%',
    budget: '32.000.000$',
    proposed: '32.000.000$',
  },
  {
    retailer: 'Auchan',
    weightedGrade: 1.7,
    partnership: 'Agreement',
    tierMultiplier: '234.89',
    allocation: '4%',
    budget: '24.000.000$',
    proposed: '24.000.000$',
  },
  {
    retailer: 'Carrefour',
    weightedGrade: 4.9,
    partnership: 'Agreement',
    tierMultiplier: '112.76',
    allocation: '16%',
    budget: '15.200.000$',
    proposed: '15.200.000$',
  },
  {
    retailer: 'Eleclerc',
    weightedGrade: 3.5,
    partnership: 'Agreement',
    tierMultiplier: '12.50',
    allocation: '18%',
    budget: '460.000$',
    proposed: '460.000$',
  }
]

const BudgetExercise = () => {
  return (
    <SectionIconSide
      description='Allocate and Manage financial effectively for your campaigns.'
      icon={DesignIcon}
      title='Budget Exercise'
    >
      {' '}
      <div className='flex gap-4 mb-4'>
        <div className='space-y-2 py-4 px-6 border border-neutral-200 rounded w-1/3'>
          <h3 className='font-light'>Budget</h3>
          <div className='relative'>
            <input
              readOnly
              className='border border-gray-200 bg-gray-input rounded-md px-4 py-1 w-full stretch-extra-expanded text-2xl'
              type='text'
              value='110.000.000'
            />
            <span className='absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-gray-400 stretch-extra-expanded font-light'>
              EUR
            </span>
          </div>
          <h3 className='pt-2 font-light'> Tiering</h3>
          <div>
            <div className='w-full space-y-2'>
              <div className='border border-neutral-200 rounded flex items-center'>
                <div className='p-[0.625rem] bg-lime rounded-s w-3/4 text-sm'>
                  Total Partneship
                </div>
                <span className='w-1/4 text-center bg-gray-50 p-[0.625rem] text-sm'>6</span>
              </div>
              <div className='border border-neutral-200 rounded flex items-center'>
                <div className='p-[0.625rem] bg-lemon rounded-s w-3/4 text-sm'>
                  Performance Partneship
                </div>
                <span className='w-1/4 bg-gray-50 p-[0.625rem] text-center text-sm'>3</span>
              </div>
              <div className='border border-neutral-200 rounded flex items-center'>
                <div className='p-[0.625rem] bg-orange-palide rounded-s w-3/4 text-sm'>
                  Opportunity Partneship
                </div>
                <span className='w-1/4 text-center bg-gray-50 p-[0.625rem] text-sm'>1</span>
              </div>
            </div>
          </div>
        </div>
        <div className='space-y-2 py-4 px-6 border border-neutral-200 rounded w-2/3'>
          <DonutChartWithLegend
            data={dataDonut}
            subtitle='Retailers'
            title='Chart Legend'
          />
        </div>
      </div>
      <div className='p-4 border border-neutral-200 rounded w-full'>
        <PerformanceTable data={data} />
      </div>
    </SectionIconSide>
  )
}

export default BudgetExercise
