import { ReactComponent as Check } from '@icons/check.svg'
import { ReactComponent as Required } from '@icons/required.svg'
import PropTypes from 'prop-types'

const Row = ({
  iconComponent:IconComponent,
  title,
  modalType,
  openModal,
  markSectionComplete,
  sectionName,
  completedSections,
  buttonIcon: ButtonIcon,
  buttonLabel,
}) => {
  const isCompleted = completedSections.has(sectionName)
  const getButtonClasses = modalType => {
    if (modalType === 'upload') {
      return 'flex items-center gap-1.5 text-xs justify-center border py-1 px-3 border-black rounded font-light min-w-32'
    } else { 
      return 'flex items-center gap-1.5 text-xs justify-center border border-black text-white py-1 px-3 bg-black rounded font-light min-w-32'
    }
  }

  return (
    <div className='flex items-center justify-between py-1.5 px-3 bg-table-gray rounded-lg'>
      <div className='flex gap-3'>
        {IconComponent && <IconComponent className='size-5' />}
        <span className='text-sm'>{title}</span>
      </div>
      <div className='flex flex-row items-center gap-2'>
        <button
          className={getButtonClasses(modalType)}
          onClick={e => {
            e.preventDefault()
            openModal(e, modalType, true)
            markSectionComplete(sectionName)
          }}
        >
          {buttonLabel}
          {ButtonIcon && <ButtonIcon className='size-4' />}
        </button>
        {isCompleted ? 
          <div className='bg-custom-green rounded-full text-white'> <Check alt='Completed' className='size-6 p-1'/></div> : <div className='rounded-full'> <Required alt='Required' /> </div>}
      </div>
    </div>
  )
}

Row.propTypes = {
  iconComponent: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  markSectionComplete: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  completedSections: PropTypes.instanceOf(Set).isRequired,
  buttonIcon: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
}

export default Row