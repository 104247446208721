import './styles/globals.css'

import Footer from '@components/footer'
import Header from '@components/header'
import Login from '@components/login/Login'
import Results from '@components/Results'
import BudgetExercise from '@components/results/design/BudgetExercise'
import IncrementalGrowthPotential from '@components/results/design/IncrementalGrowthPotential'
import MarketPlaceExpansion from '@components/results/design/MarketPlaceExpansion'
import NonMediaOptimisationRoadmap from '@components/results/design/NonMediaOptimisationRoadmap'
import RetailMediaBudgetSplit from '@components/results/design/RetailMediaBudgetSplit'
import KeywordRelevancyIndexResults from '@components/results/intelligence/KeywordRelevancyIndexResults'
import RetailInsightsResults from '@components/results/intelligence/RetailInsightsResults'
import ListProjects from '@components/settings/layout/ListProjects'
import Forecast from '@components/settings/sections/Forecast'
import KeywordRelevancyIndex from '@components/settings/sections/KeywordRelevancyIndex'
import ProjectSetup from '@components/settings/sections/ProjectSetup'
import RetailInsights from '@components/settings/sections/RetailInsights'
import RetailMediaPlanning from '@components/settings/sections/RetailMediaPlanning'
import Settings from '@components/settings/Settings'
import CustomTailwindDesignSystem from '@components/ui-config/CustomTailwindDesignSystem'
import { PrimeReactProvider } from 'primereact/api'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'

// Componente interno que usa useLocation
function AppContent() {
  const location = useLocation()
  const isLoginPage = location.pathname === '/login'

  return (
    <>
      {!isLoginPage && <Header />}
      
      <div className={`flex flex-col bg-gray-50 min-h-screen ${!isLoginPage ? 'pt-14' : ''}`}>
        <main className='flex-grow'>
          <Routes>
            {/* Login */}
            <Route element={<Login />} path='/login' />

            {/* Rutas principales */}
            <Route element={<ListProjects />} path='/list-projects' />

            {/* Detalles de un proyecto (settings) */}
            <Route element={<Settings />} path='/detail/:id/settings' />
            <Route element={<ProjectSetup />} path='/detail/:id/settings/project-setup' />
            <Route element={<Forecast />} path='/detail/:id/settings/forecast' />
            <Route element={<RetailMediaPlanning />} path='/detail/:id/settings/retail-media-planning' />
            <Route element={<KeywordRelevancyIndex />} path='/detail/:id/settings/keyword-relevancy-index' />
            <Route element={<RetailInsights />} path='/detail/:id/settings/retail-insights' />

            {/* Detalles de un proyecto (results) */}
            <Route element={<Results />} path='/detail/:id/results' />
            <Route element={<KeywordRelevancyIndexResults />} path='/detail/:id/results/intelligence/keyword-relevancy-index' />
            <Route element={<RetailInsightsResults />} path='/detail/:id/results/intelligence/retail-insights' />
            
            {/* Sección de diseño dentro de results */}
            <Route element={<IncrementalGrowthPotential />} path='/detail/:id/results/design/incremental-growth-potential' />
            <Route element={<MarketPlaceExpansion />} path='/detail/:id/results/design/market-place-expansion' />
            <Route element={<NonMediaOptimisationRoadmap />} path='/detail/:id/results/design/non-media-optimisation-roadmap' />
            <Route element={<RetailMediaBudgetSplit />} path='/detail/:id/results/design/retail-media-budget-split' />
            <Route element={<BudgetExercise />} path='/detail/:id/results/design/budget-exercise' />
          </Routes>
        </main>

        <Footer />
      </div>
    </>
  )
}

function App() {
  const Tailwind = CustomTailwindDesignSystem

  const primeReactConfig = {
    unstyled: true,
    pt: Tailwind,
  }

  return (
    <PrimeReactProvider value={primeReactConfig}>
      <Router>
        <AppContent />
      </Router>
    </PrimeReactProvider>
  )
}

export default App
