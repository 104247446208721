import { ReactComponent as InfoIcon } from '@icons/info.svg'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'

highchartsMore(Highcharts)
solidGauge(Highcharts)

const KeywordCoverageGauge = ({ title, paidValue, organicValue }) => {

  const createGaugeOptions = (value, label) => ({
    chart: {
      type: 'solidgauge',
      height: '100%',
      backgroundColor: 'transparent',
    },
    title: null,
    pane: {
      center: ['50%', '50%'],
      size: '100%',
      startAngle: 0,
      endAngle: 360,
      background: {
        backgroundColor: '#f0f0f0',
        borderWidth: 0,
        innerRadius: '72%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      min: 0,
      max: 100,
      stops: [[1, '#DF7038']],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 1,
      labels: {
        enabled: false,
      },
    },
    plotOptions: {
      solidgauge: {
        rounded: true,
        innerRadius: '72%',
        outerRadius: '100%',
        dataLabels: {
          borderWidth: 0,
          useHTML: true,
          y: -20,
          format: `
            <div style="text-align:center">
              <span style="font-size:16px; font-weight:500">${value}%</span><br/>
              <span style="font-size:12px; font-weight:400; color:#747474">${label}</span>
            </div>`,
        },
      },
    },
    series: [
      {
        name: 'Coverage',
        data: [value],
      }
    ],
    credits: {
      enabled: false,
    },
  })

  return (
    <div className='h-full px-6 bg-white rounded-lg border border-gray-200 flex flex-row items-center justify-between relative'>
      <div className='flex flex-col items-start'>
        <p className='text-sm text-gray-500 stretch-extra-expanded'>{title}</p>
      </div>

      <div className='flex justify-between items-center'>
        {paidValue !== undefined && (
          <div className='flex flex-col items-center'>
            <div className='size-28'>
              <HighchartsReact
                highcharts={Highcharts}
                options={createGaugeOptions(paidValue, 'Paid')}
              />
            </div>
          </div>
        )}

        {organicValue !== undefined && (
          <div className='flex flex-col items-center mr-3'>
            <div className='size-28'>
              <HighchartsReact
                highcharts={Highcharts}
                options={createGaugeOptions(organicValue, 'Organic')}
              />
            </div>
          </div>
        )}
      </div>

      <span className='cursor-pointer absolute top-4 right-4'>
        <InfoIcon className='size-4' />
      </span>
    </div>
  )
}

KeywordCoverageGauge.propTypes = {
  title: PropTypes.string.isRequired,
  paidValue: PropTypes.number,
  organicValue: PropTypes.number,
}

export default KeywordCoverageGauge
