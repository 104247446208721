import clsx from 'clsx'

/* eslint-disable no-mixed-operators */
const tooltipConfig = ({ type }) => ({
  root: ({ context }) => ({
    className: clsx('absolute', {
      'py-0 px-1': 
        context.right || context.left || 
        !(context.right || context.left) && !(context.top || context.bottom),
      'py-1 px-0': context.top || context.bottom,
    }),
  }),
  arrow: ({ context }) => ({
    className: clsx('absolute w-0 h-0 border-transparent border-solid', {
      '-mt-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-gray-700': context.right,
      '-mt-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-gray-700': context.left,
      '-ml-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-gray-700': context.top,
      '-ml-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-gray-700': context.bottom,
    }),
  }),
  text: {
    className: 'px-2 py-1 text-xs bg-gray-700 text-white rounded whitespace-pre-line break-words',
  },
  content: params => {
    const { seriesName, value, dataIndex } = params

    return `${seriesName && value || dataIndex ? `${seriesName}: ${value}` : ''}`
  },
  followCursor: (type === 'area' || type === 'line') && true || false,
  followPointer: (type === 'area' || type === 'line') && true || false,
})
/* eslint-disable no-mixed-operators */

export default tooltipConfig

