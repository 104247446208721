import { useCallback, useState, lazy, Suspense } from 'react'

import BgLogin from '@images/bg-login.jpg'

import { LoginHeader } from './LoginHeader'
import { LoginLocalAccess } from './LoginLocalAccess'


const LazyLoginCard = lazy(() => import('./LoginCard').then(module => ({ default: module.LoginCard })))

const Login = () => {
  const [isLocalLogin, setIsLocalLogin] = useState(true)
  const toggleLocalLogin = useCallback(() => {
    setIsLocalLogin(prevState => !prevState)
  }, [])

  return (
    <section className='h-100 flex flex-col flex-grow bg-black text-white'>
      <div className='flex flex-grow h-full flex-nowrap flex-col lg:flex-row'>
        <div className='relative flex flex-col lg:flex-grow p-6 lg:p-12 2xl:px-16 2xl:py-14'>
          <div className='absolute inset-0 overflow-hidden bg-black before-line'>
            {/* Línea diagonal*/}
            <div className='hidden lg:block absolute bg-white opacity-50 w-[1px] h-[150%] rotate-[65deg] -top-[45%] left-[20%]' />
            <div className='relative h-full w-full'>
              <img
                alt='Login background'
                className='opacity-40 object-cover object-center h-full w-full'
                src={BgLogin}
              />
            </div>
          </div>
          <div className='relative'>
            <LoginHeader className='max-h-4 lg:max-h-6' />
          </div>
        </div>

        <div className='relative flex flex-col flex-grow max-w-2xl 2xl:max-w-3xl px-10 py-16'>
          {/* form */}
          <div className='flex flex-col gap-6 w-full max-w-md m-auto'>
            {/* box */}
            <Suspense fallback={<div>Loading...</div>}>
              <LazyLoginCard
                isLocalLogin={isLocalLogin}
                toggleLocalLogin={toggleLocalLogin}
              />
            </Suspense>
          </div>
          <LoginLocalAccess
            isLocalLogin={isLocalLogin}
            onClick={toggleLocalLogin}
          />
        </div>
      </div>
    </section>
  )
}

export default Login
