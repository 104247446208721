import clsx from 'clsx'

const skeleton = {
  root: ({ props }) => ({
    className: clsx(
      'overflow-hidden',
      'bg-gray-300',
      'after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:content after:w-full after:h-full after:bg-blue-400 after:left-full after:transform after:translate-x-full after:z-10 after:bg-gradient-to-r after:from-transparent after:via-white after:to-transparent animate-pulse',
      {
        'rounded-md': props.shape !== 'circle',
        'rounded-full': props.shape === 'circle',
      }
    ),
  }),
}

export default skeleton
