/* eslint-disable no-eq-null */
/* eslint-disable no-empty-pattern */
import clsx from 'clsx'

const TRANSITIONS = {
  overlay: {
    timeout: 150,
    classNames: {
      enter: 'opacity-0 scale-75',
      enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
      exit: 'opacity-100',
      exitActive: '!opacity-0 transition-opacity duration-150 ease-linear',
    },
  },
}

const calendar = {
  root: ({ props }) => ({
    className: clsx('inline-flex w-full relative', {
      'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
    }),
  }),
  input: {
    root: ({ parent }) => ({
      className: clsx('w-full font-sans text-sm text-gray-600 bg-white p-2 border  border-custom-shadow-gray transition-colors duration-200 appearance-none', {
        'rounded-lg': !parent.props.showIcon,
        'border-r-0 rounded-l-lg rounded-r-none': parent.props.showIcon,
      }),
    }),
  },
  dropdownButton: {
    root: ({ props }) => ({
      className: clsx({ 'rounded-l-none': props.icon }),
    }),
  },
  panel: ({ props }) => ({
    className: clsx('bg-white', 'w-[400px]', {
      'shadow-md border-0 absolute': !props.inline,
      'inline-block overflow-x-auto border border-gray-300 p-2 rounded-lg': props.inline,
    }),
  }),
  header: {
    className: clsx('flex items-center justify-between', 'p-2 text-gray-700 font-semibold m-0 border-b border-gray-300 rounded-t-lg'),
  },
  previousButton: {
    className: clsx(
      'flex items-center justify-center cursor-pointer overflow-hidden relative',
      'w-6 h-6 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200 '
    ),
  },
  title: 'leading-8 mx-auto',
  monthTitle: {
    className: clsx('text-gray-700 transition duration-200 font-semibold p-2 text-xs', 'mr-2', 'hover:text-blue-500'),
  },
  yearTitle: {
    className: clsx('text-gray-700 transition duration-200 font-semibold p-2 text-xs', 'hover:text-blue-500'),
  },
  nextButton: {
    className: clsx(
      'flex items-center justify-center cursor-pointer overflow-hidden relative',
      'w-6 h-6 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200 '
    ),
  },
  table: {
    className: clsx('border-collapse w-full', 'my-2'),
  },
  tableHeaderCell: 'p-1',
  weekday: 'text-gray-600 text-xs',
  day: 'p-1',
  dayLabel: ({ context }) => ({
    className: clsx(
      'w-6 h-6 rounded-full transition-shadow duration-200 border-transparent border text-xs',
      'flex items-center justify-center mx-auto overflow-hidden relative',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      {
        'opacity-60 cursor-default': context.disabled,
        'cursor-pointer': !context.disabled,
      },
      {
        'text-gray-600 bg-transprent hover:bg-gray-200': !context.selected && !context.disabled,
        'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
      }
    ),
  }),
  monthPicker: 'my-2',
  month: ({ context }) => ({
    className: clsx(
      'w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
      'p-2 transition-shadow duration-200 rounded-lg',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      { 'text-gray-600 bg-transprent hover:bg-gray-200 ': !context.selected && !context.disabled, 'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled }
    ),
  }),
  yearPicker: {
    className: clsx('my-2'),
  },
  year: ({ context }) => ({
    className: clsx(
      'w-1/2 inline-flex items-center justify-center cursor-pointer overflow-hidden relative',
      'p-2 transition-shadow duration-200 rounded-lg',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      {
        'text-gray-600 bg-transprent hover:bg-gray-200 ': !context.selected && !context.disabled,
        'text-blue-700 bg-blue-100 hover:bg-blue-200': context.selected && !context.disabled,
      }
    ),
  }),
  timePicker: {
    className: clsx('flex justify-center items-center', 'border-t-1 border-solid border-gray-300 p-2'),
  },
  separatorContainer: 'flex items-center flex-col px-2',
  separator: 'text-xl',
  hourPicker: 'flex items-center flex-col px-2',
  minutePicker: 'flex items-center flex-col px-2',
  ampmPicker: 'flex items-center flex-col px-2',
  incrementButton: {
    className: clsx(
      'flex items-center justify-center cursor-pointer overflow-hidden relative',
      'w-6 h-6 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200'
    ),
  },
  decrementButton: {
    className: clsx(
      'flex items-center justify-center cursor-pointer overflow-hidden relative',
      'w-6 h-6 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200'
    ),
  },
  groupContainer: 'flex',
  group: {
    className: clsx('flex-1', 'border-l border-gray-300 pr-0.5 pl-0.5 pt-0 pb-0', 'first:pl-0 first:border-l-0'),
  },
  transition: TRANSITIONS.overlay,
}


export default calendar
