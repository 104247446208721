import { ReactComponent as InfoIcon } from '@icons/info.svg'
import PropTypes from 'prop-types'

const RevenueCard = ({ title, value, currency, progress, color }) => {
  return (
    <div className='h-full py-4 px-6 bg-white rounded-lg border border-gray-200 relative flex flex-col justify-between'>
      <span className='cursor-pointer absolute top-4 right-6'><InfoIcon className='size-4'/></span> 
      <div className='flex justify-between items-center'>
        <p className='text-sm text-gray-500 stretch-extra-expanded'>{title}</p>
      </div>

      <div>
        <div className='flex items-baseline justify-between mb-2'>
          <span className='text-3xl stretch-extra-expanded'>{value}</span>
          <span className='text-gray-500 text-lg stretch-extra-expanded'>{currency}</span>
        </div>
        <div className='relative h-2 bg-gray-200 rounded-full'>
          <div
            className='absolute top-0 h-2 rounded-full'
            style={{ width: `${progress}%`, backgroundColor: color }}
          ></div>
        </div>
      </div>
    </div>
  )
}

// Validación de props con PropTypes
RevenueCard.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired, // Título de la tarjeta
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Valor principal numérico o en formato string
  currency: PropTypes.string.isRequired, // Moneda (EUR, USD, etc.)
  progress: PropTypes.number.isRequired, // Progreso de la barra (en porcentaje)
}

export default RevenueCard
