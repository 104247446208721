import SectionIconSide from '@commons/section-side-icon'
import designIcon from '@images/design.svg'

import TableRetailMedia from './components/TableRetailMedia'

const RetailMediaPlanning = () => {
  return (
    <div className='flex flex-col'>
      <SectionIconSide
        description='Retail media planning targets ads on retail platforms using data to boost product visibility and sales.'
        icon={designIcon}
        title='Retail Media Planning'>
      
        <TableRetailMedia />
      </SectionIconSide>
    </div>
  )
}

export default RetailMediaPlanning
