import BarsShow from '../graphics/barsShow'

const Show = () => {
  return (
    <div className='flex flex-row border border-gray-200 justify-between rounded-lg px-6 pb-7'>
      <div className='pt-4'>
        <BarsShow />
      </div>
      <div>
        <div className='border border-gold rounded-md px-4 pt-3 pb-4 mt-8'>
          <h3 className='text-sm mb-2'>Ecommerce Turn over</h3>
          <div className='relative'>
            <input
              readOnly
              className='border border-gray-200 bg-gray-input rounded-md px-4 py-1 w-full stretch-extra-expanded text-2xl'
              type='text'
              value='110.000.000'
            />
            <span className='absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-gray-400 stretch-extra-expanded font-light'>EUR</span>
          </div>
        </div>
        <div className='border border-gray-200 rounded-md px-4 pt-3 pb-4 mt-3 min-w-[430px]'>
          <h3 className='text-sm mb-2'>Potential Ecommerce Turn over</h3>
          <div className='relative'>
            <input
              readOnly
              className='border border-gray-200 bg-gray-input rounded-md px-4 py-1 w-full stretch-extra-expanded text-2xl'
              type='text'
              value='42.600'
            />
            <span className='absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-gray-400 stretch-extra-expanded font-light'>EUR</span>
          </div>
          <h3 className='text-sm mb-2 mt-4'>Potential Ecommerce Turn over</h3>
          <div className='relative'>
            <input
              readOnly
              className='border border-gray-200 bg-gray-input rounded-md px-4 py-1 w-full stretch-extra-expanded text-2xl'
              type='text'
              value='200.800'
            />
            <span className='absolute right-4 top-1/2 transform -translate-y-1/2 text-lg text-gray-400 stretch-extra-expanded font-light'>EUR</span>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Show