import { useState } from 'react'

import PropTypes from 'prop-types'

import MenuExportImport from './MenuExIn'
import Average from '../design/Average'
import Overview from '../design/Overview'
import PainPoints from '../design/PainPoints'
import Strengths from '../design/Strengths'

const OptimizationRoadmap = ({ showExport = false, showImport = false }) => {
  const [selectedSection, setSelectedSection] = useState('Pain Points')

  const handleSelectSection = section => {
    setSelectedSection(section)
  }

  const renderSectionContent = () => {
    switch (selectedSection) {
    case 'Pain Points':
      return <PainPoints />
    case 'Average':
      return <Average />
    case 'Strengths':
      return <Strengths />
    case 'Overview':
      return <Overview />
    default:
      return <PainPoints />
    }
  }

  return (
    <div className='flex flex-col mt-3'>
      <div className='flex flex-col xl:flex-row justify-between gap-2 mb-4'>
        <div className='bg-white border border-gray-200 rounded p-1 text-xs flex gap-2'>
          <button 
            className={`px-2 py-1 rounded ${selectedSection === 'Pain Points' ? 'bg-gray-100 font-medium' : 'text-neutral-400'}`} 
            onClick={e => {
              e.preventDefault()
              handleSelectSection('Pain Points')
            }}>
            Pain Points
          </button>
          <button 
            className={`px-2 py-1 rounded ${selectedSection === 'Average' ? 'bg-gray-100 font-medium' : 'text-neutral-400'}`} 
            onClick={e => {
              e.preventDefault()
              handleSelectSection('Average')
            }}>
            Average
          </button>
          <button 
            className={`px-2 py-1 rounded ${selectedSection === 'Strengths' ? 'bg-gray-100 font-medium' : 'text-neutral-400'}`} 
            onClick={e => {
              e.preventDefault()
              handleSelectSection('Strengths')
            }}>
            Strengths
          </button>
          <button 
            className={`px-2 py-1 rounded ${selectedSection === 'Overview' ? 'bg-gray-100 font-medium' : 'text-neutral-400'}`} 
            onClick={e => {
              e.preventDefault()
              handleSelectSection('Overview')
            }}>
            Overview
          </button>
        </div>
        
        <MenuExportImport showExport={showExport} showImport={showImport} />
      </div>
      {renderSectionContent()}
    </div>
  )
}

OptimizationRoadmap.propTypes = {
  showExport: PropTypes.bool, 
  showImport: PropTypes.bool,  
}

export default OptimizationRoadmap

