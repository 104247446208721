import { useState } from 'react'

import { ReactComponent as IconBookmark } from '@icons/bookmark.svg'
import { ReactComponent as CheckIcon } from '@icons/check.svg'
import settingsIcon from '@icons/settings.svg'
import resultIcon from '@icons/signal.svg'
import { Link, useParams, useLocation } from 'react-router-dom'

import NavigationButtons from '../../navigationButton'

const projects = [
  {
    id: 1,
    name: 'EcoTrend_Fashion 2024',
    author: 'Luca Trovesky',
  },
  {
    id: 2,
    name: 'PureSkin_Revive 2025',
    author: 'Luca Trovesky',
  },
  {
    id: 3,
    name: 'BeautyPlus_Makeup 2023',
    author: 'Luca Trovesky',
  }
]

const MenuResultsSettings = () => {
  const { id } = useParams()
  const location = useLocation()
  const [selectedSection, setSelectedSection] = useState('Project Setup')

  const handleSelectSection = section => {
    setSelectedSection(section)
  }

  const handleCancel = () => {
    console.log('Cancellation handled')
  }

  const isResultsActive = location.pathname.includes(`/detail/${id}/results`)
  const isSettingsActive = location.pathname.includes(`/detail/${id}/settings`)

  const project = projects.find(p => p.id === Number(id))

  return (
    <div className='bg-white'>
      <div className='container'>
        <div className='border-b border-gray-300 flex justify-between items-center mt-2'>
          <div className='flex'>
            <Link className='relative group py-1.5 px-4' to={`/detail/${id}/results`}>
              <span className={`flex items-center text-sm ${isResultsActive ? 'opacity-100' : 'opacity-50 group-hover:opacity-100'}`}>
                <img alt='Result Icon' className='mr-2 w-4 h-4' src={resultIcon} />
                Results
              </span>
              <span className={`absolute -bottom-px left-0 right-0 h-px ${isResultsActive ? 'bg-blue-500' : 'bg-transparent'} transition-all duration-300`} />
            </Link>

            <Link className='relative group py-1.5 px-4' to={`/detail/${id}/settings`}>
              <span className={`flex items-center text-sm ${isSettingsActive ? 'opacity-100' : 'opacity-50 group-hover:opacity-100'}`}>
                <img alt='Settings Item' className='w-4 h-4 mr-2' src={settingsIcon} />Settings
              </span>
              <span className={`absolute -bottom-px left-0 right-0 h-px ${isSettingsActive ? 'bg-blue-500' : 'bg-transparent'} transition-all duration-300`} />
            </Link>
          </div>
        </div>

        <div className='py-6'>
          <div className='flex justify-between items-center'>
            <div>
              <div className='flex items-center gap-2'>
                <h1 className='text-2xl leading-none font-light'> {project ? project.name : 'Project Not Found'} </h1>
                <div className='bg-gray-100 size-6 flex-none flex justify-center items-center rounded'>
                  <IconBookmark className='items-center size-4 flex-none fill-secondary stroke-secondary' />
                </div>
              </div>
              <span className='text-neutral-500 text-sm block leading-tight'>{project ? project.author : 'Author Not Found'}</span>
            </div>
            {isSettingsActive && (
              <div className='gap-3'>
                <NavigationButtons
                  icon={<CheckIcon className='size-5'/>}
                  label='Save'
                  selectedSection={selectedSection}
                  setSelectedSection={handleSelectSection}
                  onCancel={handleCancel}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuResultsSettings
