import { useState } from 'react'

import { ReactComponent as InfoIcon } from '@icons/info.svg'
import { ReactComponent as CloseIcon } from '@icons/x.svg'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import PropTypes from 'prop-types'

const RetailModal = ({ onClose, modalContent, visible }) => {
  const [formData, setFormData] = useState({
    question: '',
  })

  const handleDropdownChange = event => {
    setFormData({ question: event.value })
  }

  const renderFooter = () => (
    <div className='flex justify-end'>
      <Button label='Save Relevancy' onClick={onClose} />
    </div>
  )

  if (!modalContent) {
    return null 
  }

  return (
    <Dialog
      modal
      footer={renderFooter()}
      header={ <div className='flex justify-between items-center'>
        <span>{modalContent.title}</span>
        <CloseIcon 
          className='cursor-pointer size-5 text-gray-600 hover:text-gray-800'
          onClick={onClose}
        />
      </div>}
      style={{ width: '50vw' }}
      visible={visible}
      onHide={onClose}
      
    >
      <div className='overflow-y-auto mt-4'>
        <h3 className='text-sm my-4 font-medium'>{modalContent.question}</h3>
        <div className='p-3 border border-gray-100 rounded'>
          <div className='flex flex-col gap-4'>
            <div className='flex'>
              <span className='w-1/2 text-sm text-gray-700'>Criterias</span>
              <span className='w-1/2 text-sm text-gray-700'>Answer</span>
            </div>

            <div className='flex items-center bg-gray-100 p-2 rounded'>
              <div className='flex items-center w-1/2'>
                <span className='text-sm text-black mr-2'>
                  {modalContent.question}
                  <span className='inline-flex text-sm text-gray-400 ml-2'>
                    (Optional)
                    <InfoIcon className='size-3 text-gray-400 ml-1 mt-1' />
                  </span>
                </span>
              </div>

              <div className='w-1/2'>
                <Dropdown
                  className='w-full'
                  options={modalContent.options}
                  placeholder='Select answer'
                  value={formData.question}
                  onChange={handleDropdownChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

RetailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  modalContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
}

export default RetailModal
