import PropTypes from 'prop-types'

import Separator from './Separator'

const SectionMedium = ({ title, description, children }) => {
  return (
    <div className='bg-white border border-gray-100 rounded-lg px-5 xl:px-8 pt-5 xl:pt-6 pb-6 xl:pb-8'>
      <h3 className='font-medium mb-1 leading-none text-primary'>{title}</h3>
      <p className='text-sm text-gray-text'>
        {description}
      </p>
      <Separator className='my-4' />
      {children}
    </div>
  )
}

SectionMedium.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default SectionMedium