import amazonLogo from '@images/amazon.png'
import auchanLogo from '@images/auchan.png'
import carrefourLogo from '@images/carrefour.png'
import leclercLogo from '@images/eleclerc.png'
import intermarcheLogo from '@images/letDrive.png'

import BudgetCard from './budgetCard'
import MarketShareChart from './marketChart'
import RadarChart from './radarChart'

const RetailMediaBudget = () => {
  const marketShareData = [
    { label: 'Carrefour Drive', value: 13 },
    { label: 'Leclerc Drive', value: 45 },
    { label: 'Intermarché', value: 12 },
    { label: 'Amazon', value: 10 },
    { label: 'Auchan Drive', value: 15 }
  ]

  const radarCategories = ['Carrefour Drive', 'Auchan Drive', 'Amazon', 'Intermarché','Leclerc Drive' ]

  return (
    <div className='p-4 border space-y-4 bg-gray-50 rounded'>
      <div className='grid grid-cols-4 gap-4'>
        <MarketShareChart
          barColor='#10AC94'
          data={marketShareData}
          title='Retail Market Share' 
        />

        <MarketShareChart
          barColor='#10AC94'
          data={marketShareData}
          title='Incremental Growth' 
        />

        <RadarChart
          categories={radarCategories}
          chartColor='#10AC94'
          chartColor2='#C0C0C0'
          series={[5, 3, 4, 2, 1]} 
          series2={[4, 4, 3, 5, 2]}
          title='Retail Relevancy'
        />

        <RadarChart
          categories={radarCategories}
          chartColor='#10AC94'
          chartColor2='#C0C0C0' 
          series={[5, 3, 4, 2, 1]}
          series2={[4, 4, 3, 5, 2]}
          title='Media Madurity Score'
        />
      </div>

      <div className='border border-gray-icon bg-white rounded-md p-4'>
        <p className='text-center stretch-extra-expanded mb-5'>Forecast E-Retail Media Mix 2025</p>
      

        <div className='grid grid-cols-5 gap-4'>
          <BudgetCard
            change={12.4}
            changeColor='#00DCB9'
            changeDirection='up'
            changeText='from last year'
            logo={carrefourLogo}
            percentage={24}
            title='Budget & Expenses'
          />

          <BudgetCard
            change={4.01}
            changeColor='#00DCB9'
            changeDirection='up'
            changeText='from last year'
            logo={leclercLogo}
            percentage={14}
            title='Leclerc Drive'
          />

          <BudgetCard
            change={16.2}
            changeColor='#00DCB9'
            changeDirection='up'
            changeText='from last year'
            logo={auchanLogo}
            percentage={16}
            title='Auchan Drive'
          />

          <BudgetCard
            change={23.5}
            changeColor='#00DCB9'
            changeDirection='up'
            changeText='from last year'
            logo={intermarcheLogo}
            percentage={31}
            title='Intermarché Drive'
          />

          <BudgetCard
            change={-0.24}
            changeColor='#F06565'
            changeDirection='down'
            changeText='from last year'
            logo={amazonLogo}
            percentage={16}
            title='Amazon'
          />
        </div>
      </div>
    </div>
  )
}

export default RetailMediaBudget
