import PropTypes from 'prop-types'

import Separator from './Separator'

const SectionIcon = ({ title, description, icon, children }) => {
  return (
    <div className='bg-white border border-gray-100 rounded-md px-5 xl:px-8 pt-5 xl:pt-6 pb-6 xl:pb-8 w-1/2'>
      {icon && <img alt={title} className='mb-4 h-8' src={icon} />}
      <h3 className='font-medium text-primary'>{title}</h3>
      <p className='font-light text-xs text-gray-icon'>
        {description}
      </p>
      <Separator className='my-4' />
      {children}

    </div>
  )
}

SectionIcon.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
  icon: PropTypes.string,
}

export default SectionIcon