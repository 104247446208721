import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'

highchartsMore(Highcharts)

const RadarChart = ({ title, categories, series, series2, chartColor, chartColor2 }) => {
  const options = {
    chart: {
      polar: true,
      type: 'line',
      height: 170,
      width: 255,
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
    },
    title: {
      text: null, 
    },
    pane: {
      size: '82%',
    },
    xAxis: {
      categories: categories,
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        style: {
          fontSize: '8px',
          color: '#333333',
        },
        distance: 8,
      },
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      gridLineColor: '#d3d3d3', 
      gridLineWidth: 1,
      labels: {
        enabled: false, 
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        marker: {
          enabled: false, 
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        enableMouseTracking: false, 
      },
    },
    series: [
      {
        name: null,
        data: series,
        pointPlacement: 'on',
        color: chartColor,
      },
      {
        name: null, 
        data: series2,
        pointPlacement: 'on',
        color: chartColor2,
        dashStyle: 'ShortDash',
        lineWidth: 1,
      }
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  }

  return (
    <div className='flex flex-col items-center justify-center bg-white pt-4 rounded-lg border border-gray-icon h-full'>
      <h3 className='text-center text-xs stretch-extra-expanded mb-3 pb-2 border-b w-[15.25rem] '>{title}</h3>
      <HighchartsReact className='border-t-2 border-gray-100' highcharts={Highcharts} options={options} />
    </div>
  )
}

RadarChart.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  series: PropTypes.arrayOf(PropTypes.number).isRequired,
  series2: PropTypes.arrayOf(PropTypes.number).isRequired, 
  chartColor: PropTypes.string,
  chartColor2: PropTypes.string,
  title: PropTypes.string.isRequired,
}

RadarChart.defaultProps = {
  chartColor: '#10AC94',
  chartColor2: '#007BFF', 
}

export default RadarChart
