import PropTypes from 'prop-types'

import Separator from './Separator'

const SectionIconSide = ({ title, description, icon, children }) => {
  return (
    <div className='bg-white border border-gray-100 rounded-md px-5 xl:px-8 pt-5 xl:pt-6 pb-6 xl:pb-8'>
      <div className='flex justify-between items-start w-full'>
        <div>
          <h3 className='font-medium mb-1 leading-none'>{title}</h3>
          <p className='text-sm font-light text-neutral-400'>
            {description}
          </p>
        </div>
        {icon && <img alt={title} src={icon} />}
      </div>
      <Separator className='my-4'/>     
      {children}
    </div> 
  )   
  
}

SectionIconSide.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
  icon: PropTypes.string,
}

export default SectionIconSide