import { useState, useEffect, useRef } from 'react'

import FilterGroup from '@commons/FilterGroup'
import { ReactComponent as IconBookmark } from '@icons/bookmark.svg'
import { ReactComponent as IconChevronRight } from '@icons/chevron-right.svg'
import { ReactComponent as FileLineChart } from '@icons/file-line-chart.svg'
import { ReactComponent as Map } from '@icons/map.svg'
import { ReactComponent as PieChart } from '@icons/pie-chart.svg'
import { ReactComponent as Podcast } from '@icons/podcast.svg'
import { ReactComponent as IconUpload } from '@icons/upload.svg'
import { ReactComponent as IconClose } from '@icons/x.svg'
import amazonIcon from '@images/tools/amazon.svg'
import carrefourIcon from '@images/tools/carrefour.svg'
import { ReactComponent as DTC } from '@images/tools/dtc.svg'
import { ReactComponent as Facebook } from '@images/tools/facebook.svg'
import { ReactComponent as Instagram } from '@images/tools/instagram.svg'
import { ReactComponent as Pinteres } from '@images/tools/pinterest.svg'
import { ReactComponent as Snapchat } from '@images/tools/snapchat.svg'
import targetIcon from '@images/tools/target.svg'
import { ReactComponent as TikTok } from '@images/tools/tiktok.svg'
import walmartIcon from '@images/tools/wallmart.svg'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { OverlayPanel } from 'primereact/overlaypanel'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Pagination from './Pagination'


const platformIcons = {
  'Amazon': amazonIcon,
  'Walmart': walmartIcon,
  'Carrefour': carrefourIcon,
  'Target': targetIcon,
}

const socialIcons = {
  'Facebook': Facebook,
  'Instagram': Instagram,
  'Snapchat': Snapchat,
  'TikTok': TikTok,
  'Pinterest': Pinteres,
}

const countryOptions = [
  { label: 'France', value: 'France' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'Argentina', value: 'Argentina' }
]

const categoryOptions = [
  { label: 'Skin Treatment', value: 'Skin Treatment' },
  { label: 'Hair Care', value: 'Hair Care' },
  { label: 'Makeup', value: 'Makeup' },
  { label: 'Perfume', value: 'Perfume' },
  { label: 'Oral Care', value: 'Oral Care' },
  { label: 'Men\'s Grooming', value: 'Mens Grooming' },
  { label: 'Wellness', value: 'Wellness' },
  { label: 'Dietary Supplements', value: 'Dietary Supplements' },
  { label: 'Baby Care', value: 'Baby Care' },
  { label: 'Pet Products', value: 'Pet Products' }
]

const clientOptions = [
  { label: 'Loreal', value: 'Loreal' },
  { label: 'Pantene', value: 'Pantene' },
  { label: 'NYX Cosmetic', value: 'NYX Cosmetic' },
  { label: 'Dior', value: 'Dior' },
  { label: 'Lacer', value: 'Lacer' },
  { label: 'Gillete', value: 'Gillete' },
  { label: 'Basic Fit', value: 'Basic Fit' },
  { label: 'Nature House', value: 'Nature House' },
  { label: 'Dodoot', value: 'Dodoot' },
  { label: 'Wixcas', value: 'Wixcas' }
]

const brandOptions = [
  { label: 'Loreal', value: 'Loreal' },
  { label: 'Pantene', value: 'Pantene' },
  { label: 'NYX Cosmetic', value: 'NYX Cosmetic' },
  { label: 'Dior', value: 'Dior' },
  { label: 'Lacer', value: 'Lacer' },
  { label: 'Gillete', value: 'Gillete' },
  { label: 'Basic Fit', value: 'Basic Fit' },
  { label: 'Nature House', value: 'Nature House' },
  { label: 'Dodoot', value: 'Dodoot' },
  { label: 'Wixcas', value: 'Wixcas' }
]

const platformOptions = [
  { label: 'Amazon', value: 'Amazon' },
  { label: 'eBay', value: 'eBay' },
  { label: 'Walmart', value: 'Walmart' },
  { label: 'AliExpress', value: 'AliExpress' },
  { label: 'Etsy', value: 'Etsy' }
]

const sortByOptions = [
  { label: 'Country Asc', value: 'country-asc' },
  { label: 'Country Desc', value: 'country-desc' },
  { label: 'Category Asc', value: 'category-asc' },
  { label: 'Category Desc', value: 'category-desc' },
  { label: 'Client Asc', value: 'client-asc' },
  { label: 'Client Desc', value: 'client-desc' },
  { label: 'Brand Asc', value: 'brand-asc' },
  { label: 'Brand Desc', value: 'brand-desc' },
  { label: 'Platform Asc', value: 'platform-asc' },
  { label: 'Platform Desc', value: 'platform-desc' }
]

export const TableProjects = ({ projects }) => {
  const [filters, setFilters] = useState({
    country: null,
    project: null,
    category: null,
    client: null,
    brand: null,
    platform: null,
    sortby: null,
  })
  const [filteredProjects, setFilteredProjects] = useState(projects)
  const [activeBookmarks, setActiveBookmarks] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const rowsPerPage = 10
  const opMarket = useRef(null)
  const opSocial = useRef(null)

  useEffect(() => {
    setCurrentPage(1)
  }, [filters])

  const handleSearch = () => {
    let filtered = [...projects]

    if (filters.project) {
      filtered = filtered.filter(p => p.project === filters.project)
    }

    if (filters.country) {
      filtered = filtered.filter(p => p.country === filters.country)
    }
    if (filters.category) {
      filtered = filtered.filter(p => p.category === filters.category)
    }
    if (filters.client) {
      filtered = filtered.filter(p => p.client === filters.client)
    }
    if (filters.brand) {
      filtered = filtered.filter(p => p.brand === filters.brand)
    }
    if (filters.platform) {
      filtered = filtered.filter(p => p.platform === filters.platform)
    }

    if (filters.sortby) {
      const [field, order] = filters.sortby.split('-')

      filtered.sort((a, b) => {
        if (a[field] < b[field]) return order === 'asc' ? -1 : 1
        if (a[field] > b[field]) return order === 'asc' ? 1 : -1

        return 0
      })
    }

    setFilteredProjects(filtered)
  }

  const handleClear = () => {
    setFilters({
      project: null,
      country: null,
      category: null,
      client: null,
      brand: null,
      platform: null,
      sortby: null,
    })
    setFilteredProjects(projects)
    setCurrentPage(1)
  }

  const handleBookmark = projectId => {
    setActiveBookmarks(prev => ({
      ...prev,
      [projectId]: !prev[projectId],
    }))
  }


  const indexOfLastRow = currentPage * rowsPerPage
  const indexOfFirstRow = indexOfLastRow - rowsPerPage
  const currentProjects = filteredProjects.slice(indexOfFirstRow, indexOfLastRow)

  const handlePageChange = newPage => {
    setCurrentPage(newPage)
  }

  const getActiveFilters = () => {
    return Object.entries(filters).filter(([key, value]) => {
      return value !== null && key !== 'sortby' && (key !== 'project' || value !== '')
    })
  }

  return (
    <>
      <div className='container pb-4'>
        <div className='flex gap-2'>
          <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-7 gap-2'>
            <div>
              <p className='flex flex-col text-xs mb-1'>Projects</p>
              <InputText
                placeholder='Title Project'
                value={filters.project}
                onChange={e => setFilters({ ...filters, project: e.target.value })}
              />
            </div>
            <div>
              <p className='flex flex-col text-xs mb-1 '>Country</p>
              <Dropdown
                filter 
                options={countryOptions}
                placeholder='Select an option'
                value={filters.country}
                onChange={e => {
                  setFilters({ ...filters, country: e.value })
                  handleSearch()
                }}
              />
            </div>
            <div>
              <p className='flex flex-col text-xs mb-1'>Category</p>
              <Dropdown
                filter
                options={categoryOptions}
                placeholder='Select an option'
                value={filters.category}
                onChange={e => {
                  setFilters({ ...filters, category: e.value })
                  handleSearch() 
                }}
              />
            </div>
            <div>
              <p className='flex flex-col text-xs mb-1'>Client</p>
              <Dropdown
                filter
                options={clientOptions}
                placeholder='Select an option'
                value={filters.client}
                // showClear
                onChange={e => {
                  setFilters({ ...filters, client: e.value })
                  handleSearch() 
                }}
              />
            </div>
            <div>
              <p className='flex flex-col text-xs mb-1'>Brand</p>
              <Dropdown
                filter
                options={brandOptions}
                placeholder='Select an option'
                value={filters.brand} 
                onChange={e => {
                  setFilters({ ...filters, brand: e.value })
                  handleSearch() 
                }}
              />
            </div>
            <div>
              <p className='flex flex-col text-xs mb-1'>Platform</p>
              <Dropdown
                filter
                options={platformOptions}
                placeholder='Select an option'
                value={filters.platform}
                onChange={e => {
                  setFilters({ ...filters, platform: e.value })
                  handleSearch()
                }}
              />
            </div>
            <div>
              <p className='flex flex-col text-xs mb-1'>Sort By</p>
              <Dropdown
                filter
                options={sortByOptions}
                placeholder='Select an option'
                value={filters.sortby}
                onChange={e => {
                  setFilters({ ...filters, sortby: e.value })
                  handleSearch()
                }}
              />
            </div>
          </div>
          <div className='flex flex-row items-end'>
            <Button
              outlined
              icon={<IconClose className='size-4' />}
              iconPos='right'
              label='Clear'
              onClick={handleClear}
            />
          </div>
        </div>
      </div>

      <div className='bg-neutral-50 grow py-8'>
        <div className='container'>
          {getActiveFilters().length > 0 && (
            <div className='flex items-center gap-2 mb-4'>
              <span className='text-sm font-medium'>Currently filter by</span>
              <div className='flex gap-2'>
                {getActiveFilters().map(([key, value]) => (
                  <FilterGroup 
                    key={key} 
                    data={value} 
                    filter={key.charAt(0).toUpperCase() + key.slice(1)} 
                    onRemove={() => {
                      setFilters(prev => ({ ...prev, [key]: null }))
                      handleSearch()
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className='flex flex-col gap-2'>
            {currentProjects.map(project => (
              <div key={project.id} className='flex items-center bg-white py-2 xl:py-3 rounded-lg border border-neutral-200 shadow-xs'>
                <div className='flex items-center gap-2 xl:gap-4 w-1/4 xl:w-2/5 max-w-[28rem] px-2 xl:px-4 border-r border-neutral-200 flex-none'>
                  <Button
                    aria-label='Bookmark'
                    className='flex-none'
                    icon={<IconBookmark className={`size-4 flex-none ${activeBookmarks[project.id] ? 'fill-secondary stroke-secondary' : 'fill-white stroke-white'}`} />}
                    severity='light'
                    size='xsmall'
                    onClick={() => handleBookmark(project.id)}
                  />
                  <div className='grow flex flex-col min-w-px'>
                    <Link to={`/detail/${project.id}/settings`}>
                      <h6 className='font-medium text-neutral-900 text-xs xl:text-sm cursor-pointer leading-none truncate'>{project.name}</h6>
                    </Link>
                    <small className='text-xs font-light text-neutral-400'>Luca Trovesky</small>
                  </div>
                  <Button
                    aria-label='Upload'
                    className='flex-none'
                    icon={<IconUpload className='size-4' />}
                    severity='light'
                    size='xsmall'
                  />
                </div>

                <div className='grow'>
                  <div className='grid grid-cols-7 gap-1.5 xl:gap-2 w-full pl-4'>
                    <div className='flex flex-col justify-center'>
                      <span className='text-2xs font-light text-neutral-600'>Country</span>
                      <span className='text-xs truncate'>{project.country}</span>
                    </div>
                    <div className='flex flex-col justify-center'>
                      <span className='text-2xs font-light text-neutral-600'>Category</span>
                      <span className='text-xs truncate'>{project.category}</span>
                    </div>
                    <div className='flex flex-col justify-center'>
                      <span className='text-2xs font-light text-neutral-600'>Client & Brand</span>
                      <span className='text-xs truncate'>{project.client} / {project.brand}</span>
                    </div>
                    <div className='flex flex-col justify-center'>
                      <span className='text-2xs font-light text-neutral-600'>Created</span>
                      <span className='text-xs'>2021/01/04</span>
                    </div>
                    <div className='flex flex-col justify-center'>
                      <span className='text-2xs font-light text-neutral-600'>Last Update</span>
                      <span className='text-xs'>2021/01/04</span>
                    </div>
                    <div className='flex flex-col justify-center'>
                      <span className='text-2xs font-light text-neutral-600'>Marketplace</span>
                      <div className='flex space-x-[-6px]'>
                        {(project.platform || []).slice(0, project.platform && project.platform.length > 3 ? 2 : 3).map((platformName, index) => (
                          <img 
                            key={index}
                            alt={platformName} 
                            className='size-5'
                            src={platformIcons[platformName]}
                          />
                        ))}
                        {project.platform && project.platform.length > 3 && (
                          <>
                            <Button 
                              rounded 
                              className='!size-5 !text-2xs !p-0' 
                              label={`+${project.platform.length - 2}`} 
                              type='button' 
                              onClick={e => opMarket.current.toggle(e)} 
                            />
                            <OverlayPanel ref={opMarket}>
                              <ul>
                                {project.platform.map((platformName, index) => (
                                  <li key={index} className='text-sm flex items-center mb-2 last:mb-0'>
                                    <img 
                                      alt={platformName} 
                                      className='size-6 mr-2' 
                                      src={platformIcons[platformName]} 
                                    />
                                    {platformName}
                                  </li>
                                ))}
                              </ul>
                            </OverlayPanel>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col justify-center'>
                      <span className='text-2xs font-light text-neutral-600'>Social</span>
                      <div className='flex space-x-[-6px]'>  
                        {(project.social || []).slice(0, project.social && project.social.length > 3 ? 2 : 3).map((socialName, index) => {
                          const SocialIcon = socialIcons[socialName]

                          return SocialIcon ? <SocialIcon key={index} className='size-5 flex-none' /> : null
                        })}
                        {project.social && project.social.length > 3 && (
                          <>
                            <Button 
                              rounded 
                              className='!size-5 !text-2xs !p-0' 
                              label={`+${project.social.length - 2}`} 
                              type='button' 
                              onClick={e => opSocial.current.toggle(e)} 
                            />
                            <OverlayPanel ref={opSocial}>
                              <ul>
                                {project.social.map((socialName, index) => {
                                  const SocialIcon = socialIcons[socialName]

                                  return (
                                    <li key={index} className='text-sm flex items-center mb-2 last:mb-0'>
                                      {SocialIcon && <SocialIcon className='size-6 mr-2' />}
                                      {socialName}
                                    </li>
                                  )
                                })}
                              </ul>
                            </OverlayPanel>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <span className='text-2xs font-light text-neutral-600'>DTC</span>
                  <DTC className='size-5 flex-none' />
                </div>
                <div className='flex w-1/8 items-center border-l border-gray-200 pl-2 xl:pl-4 ml-2 xl:ml-4'>
                  <div className='flex flex-col border-r border-gray-100 pr-2 xl:pr-4'>
                    <span className='text-2xs font-light text-neutral-600'>Modules</span>
                    <div className='flex gap-x-2'>
                      <Podcast className='size-5 flex-none' />
                      <Map className='size-5 flex-none' />
                      <PieChart className='size-5 flex-none' />
                      <FileLineChart className='size-5 flex-none opacity-20' />
                    </div>
                  </div>
                  <Link className='pl-1 pr-1 xl:pr-2' to={`/detail/${project.id}/settings`}>
                    <IconChevronRight className='text-neutral-900 size-5 flex-none' />
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            totalPages={Math.ceil(filteredProjects.length / rowsPerPage)} 
            onPageChange={handlePageChange} />
        </div>
      </div>
    </>
  )
}

TableProjects.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      client: PropTypes.string.isRequired,
      brand: PropTypes.string.isRequired,
      platform: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default TableProjects


