import ReusableTable from '../components/tableNonMedia'

const PainPoints = () => {

  const data = [
    {
      type: 'Pain Points',
      criteria: 'Share of best seller rank',
      platforms: ['eLeclerc', 'amazon', 'carrefour', 'auchan'],
      answers: {
        eLeclerc: 21,
        auchan: 12,
        carrefour: 35,
        amazon: 24,
      },
    },
    {
      type: 'Pain Points',
      criteria: 'New reviews in last 30 days (in top SKUs)',
      platforms: ['eLeclerc', 'amazon', 'carrefour', 'auchan'],
      answers: {
        eLeclerc: 12,
        auchan: 23,
        carrefour: 14,
        amazon: 26,
      },
    },
    {
      type: 'Pain Points',
      criteria: 'Product range depth',
      platforms: ['eLeclerc', 'auchan'],
      answers: {
        eLeclerc: 4,
        auchan: 21,
        carrefour: 208,
        amazon: 321,
      },
    },
    {
      type: 'Pain Points',
      criteria: 'Buy box with rate',
      platforms: ['auchan', 'carrefour', 'amazon'],
      answers: {
        eLeclerc: 555,
        auchan: 24,
        carrefour: 32,
        amazon: 3,
      },
    },
    {
      type: 'Pain Points',
      criteria: 'Product range depth',
      platforms: ['eLeclerc', 'auchan', 'amazon'],
      answers: {
        eLeclerc: 5,
        auchan: 16,
        carrefour: 234,
        amazon: 24,
      },
    },
    {
      type: 'Pain Points',
      criteria: 'Share of best seller rank',
      platforms: ['eLeclerc', 'carrefour', 'amazon'],
      answers: {
        eLeclerc: 3,
        auchan: 43,
        carrefour: 31,
        amazon: 346,
      },
    },
    {
      type: 'Pain Points',
      criteria: 'Product range depth',
      platforms: ['eLeclerc'],
      answers: {
        eLeclerc: 21,
        auchan: 327,
        carrefour: 536,
        amazon: 601,
      },
    },
    {
      type: 'Pain Points',
      criteria: 'Brand Organic Share of Shelf Marketplace',
      platforms: ['auchan', 'carrefour'],
      answers: {
        eLeclerc: 234,
        auchan: 20,
        carrefour: 21,
        amazon: 300,
      },
    }
  ]


  return (
    <div>

      <ReusableTable data={data}
        title='Pain Points' />
    </div>
  )
}

export default PainPoints