import { useState } from 'react'

import CriteriaRow from '@commons/CriteriaRow'
import SectionMedium from '@commons/section-medium'

const USPPotential = () => {
  const [formData, setFormData] = useState({
    uniqueness: '',
    brandAwareness: '',
  })

  const handleInputChange = (value, field) => {
    setFormData(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const uspData = [
    {
      criteria: 'Uniqueness',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      description: 'Does the brand\'s product portfolio show Unique Selling Points?',
      answerValue: formData.uniqueness,
      answerKey: 'uniqueness',
      performance: '15',
      growthPotential: '50',
      answerOptions: [
        'Same as most products, no uniqueness',
        'Different in colour or taste or size',
        'Not unique nor different',
        'Has its unique signature',
        'Unique and innovative'
      ],
    },
    {
      criteria: 'Brand Awareness',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      description: 'How known is the brand in the market?',
      answerValue: formData.brandAwareness,
      answerKey: 'brandAwareness',
      performance: '85',
      growthPotential: '85',
      answerOptions: [
        'Select Option',
        'New brand or unknown',
        'Low awareness',
        'Good awareness',
        'High awareness',
        'High awareness and leader in the market'
      ],
    }
  ]

  return (
    <div>
      <SectionMedium title='USP Potential'>
        <div>
          <h2 className='font-medium mb-4 text-sm'>Volume</h2>
          <div className='px-3 xl:px-4 pb-3 xl:pb-4 pt-2 border border-gray-border rounded'>
            <CriteriaRow
              criteria='Criterias'
              growthPotentialTitle='Growth Potential'
              isHeader={true}
              isSelectOnly={true} // Pasamos isSelectOnly como true para que el header utilice el ancho de Dropdown
              performanceTitle='Performance'
              valueTitle='Answer'
            />
            {uspData.map((item, index) => (
              <CriteriaRow
                key={index}
                answerKey={item.answerKey}
                answerOptions={item.answerOptions}
                answerValue={item.answerValue}
                criteria={item.criteria}
                description={item.description}
                growthPotential={item.growthPotential}
                isOdd={index % 2 === 0}
                isSelectOnly={true} // Configuración de ancho de Dropdown en cada fila de datos
                performance={item.performance}
                tooltipHelp={item.tooltipHelp}
                tooltipInfo={item.tooltipInfo}
                onAnswerChange={value => handleInputChange(value, item.answerKey)}
              />
            ))}
          </div>
        </div>
      </SectionMedium>
    </div>
  )
}

export default USPPotential
