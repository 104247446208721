import { useState } from 'react'

import Separator from '@commons/Separator'
import amazonIcon from '@images/tools/amazon.svg'
import auchanIcon from '@images/tools/auchan.svg'
import carrefourIcon from '@images/tools/carrefour.svg'
import eLeclercIcon from '@images/tools/eleclerc.svg'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import PropTypes from 'prop-types'

const answers= [
  { label: 'All platforms', value: 'All platforms' },
  { label: 'ELeclerc', value: 'ELeclerc' },
  { label: 'Auchan', value: 'Auchan' },
  { label: 'Carrefour', value: 'Carrefour' },
  { label: 'Amazon', value: 'Amazon' }
]

const platformsIcons = {
  amazon: amazonIcon,
  carrefour: carrefourIcon,
  auchan: auchanIcon,
  eLeclerc: eLeclercIcon,
}

const ReusableTable = ({ data, title }) => {
  const [filter, setFilter] = useState('All platforms')

  const colorMap = {
    'Pain Points': 'bg-red-500',
    'Average': 'bg-orange-300',
    'Strengths': 'bg-green-500',
  }

  const filteredData = filter === 'All platforms' ? data : data.filter(d => d.platforms.includes(filter.toLowerCase()))

  const criteriaBodyTemplate = rowData => (
    <div className='flex items-center gap-2 rounded-s-lg'> {/* Reduced height */}
      <div className={`size-2 rounded-full ${colorMap[rowData.type]}`}></div> {/* Reduced size */}
      <span className='text-xs leading-tight block'>{rowData.criteria}</span>
    </div>
  )

  const platformsBodyTemplate = rowData => (
    <div className='flex gap-1 items-center'> {/* Reduced height */}
      {rowData.platforms.includes('eLeclerc') && <img alt='eLeclerc' className='w-4 h-4' src={platformsIcons.eLeclerc} />} {/* Reduced size */}
      {rowData.platforms.includes('auchan') && <img alt='Auchan' className='w-4 h-4' src={platformsIcons.auchan} />}
      {rowData.platforms.includes('carrefour') && <img alt='Carrefour' className='w-4 h-4' src={platformsIcons.carrefour} />}
      {rowData.platforms.includes('amazon') && <img alt='Amazon' className='w-4 h-4' src={platformsIcons.amazon} />}
    </div>
  )

  const answerBodyTemplate = (rowData, column) => (
    <div className={`text-xs flex items-center ${rowData.platforms.includes(column.field) ? 'text-primary' : 'text-primary opacity-20'} ${column.field === 'amazon' ? 'rounded-e-lg' : ''}`}>
      {rowData.answers[column.field]}
    </div>
  )

  return (
    <div className='overflow-x-auto'>
      <h3 className='flex flex-row justify-between font-medium mb-4'>{title}</h3>
      <Separator className='mb-4' />
      <DataTable   
        className='p-datatable-sm '
        rowClassName={(rowData, index) => index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
        value={filteredData}
      >
        <Column
          body={criteriaBodyTemplate}
          bodyClassName='rounded-s-lg px-4 text-xs font-light'
          field='criteria'
          header={
            <>
              <div className='text-xs font-medium'>Criteria</div>
              <div className='h-7' />
            </>
          }
        />
        <Column
          body={platformsBodyTemplate}
          header={
            <div className='flex flex-col justify-between'>
              <div className='text-xs font-medium text-start'>Platforms</div>
              <Dropdown
                className='min-w-[8rem] w-full text-left'
                options={answers}
                placeholder='Select Platform'
                size='xsmall'
                value={filter}
                onChange={e => setFilter(e.value)}
              />
            </div>
          }
          headerClassName='pb-2' 
        />

        <Column
          body={rowData => answerBodyTemplate(rowData, { field: 'eLeclerc' })}
          field='eLeclerc'
          header={
            <>
              <div className='text-xs font-medium'>Answer</div>
              <div className='text-xs font-light text-primary h-7 flex items-center'>ELeclerc</div>
            </>
          }
          headerClassName='pb-2' 
        />
        <Column
          body={rowData => answerBodyTemplate(rowData, { field: 'auchan' })}
          field='auchan'
          header={
            <>
              <div className='text-xs font-medium'>Answer</div>
              <div className='text-xs font-light text-primary h-7 flex items-center'>Auchan</div>
            </>
          }
          headerClassName='pb-2' 
        />
        <Column
          body={rowData => answerBodyTemplate(rowData, { field: 'carrefour' })}
          field='carrefour'
          header={
            <>
              <div className='text-xs font-medium'>Answer</div>
              <div className='text-xs font-light text-primary h-7 flex items-center'>Carrefour</div>
            </>
          }
          headerClassName='pb-2'
        />
        <Column
          body={rowData => answerBodyTemplate(rowData, { field: 'amazon' })}
          bodyClassName='rounded-e-lg'
          field='amazon'
          header={
            <>
              <div className='text-xs font-medium'>Answer</div>
              <div className='text-xs font-light text-primary h-7 flex items-center'>Amazon</div>
            </>
          }
          headerClassName='pb-2'
        />
      </DataTable>
    </div>
  )
}

ReusableTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['Pain Points', 'Average', 'Strengths', 'Overview']).isRequired,
    criteria: PropTypes.string.isRequired,
    platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    answers: PropTypes.shape({
      eLeclerc: PropTypes.string,
      auchan: PropTypes.string,
      carrefour: PropTypes.string,
      amazon: PropTypes.string,
    }).isRequired,
  })).isRequired,
}

export default ReusableTable
