import SectionIconSide from '@commons/section-side-icon'
import MenuResultsSettings from '@components/settings/layout/MenuResSet'
import DesignIcon from '@images/design.svg'
import { Link } from 'react-router-dom'

import TabSwitcher from './category/tabSwitcher'

const MarketPlaceExpansion = id => {
  return (
    <div className='pb-20'>
      <MenuResultsSettings />
      <div className='container'>
        <div className='my-3 '>
          <Link className='text-xs font-light hover:font-semibold' to={`/detail/${id}/results`}>Results <span className='mx-2'>/</span></Link><span className='text-xs font-medium'> Category Revenue Benchmark</span>
        </div>
        <SectionIconSide
          description='Plan and execute an efficient expansion strategy across multiple marketplaces.'
          icon={DesignIcon}
          title='Category Revenue Benchmark'
        >
          <TabSwitcher />

        </SectionIconSide>
      </div>
    </div>
  )
}

export default MarketPlaceExpansion