import { ReactComponent as IconBell } from '@icons/bell.svg'
import { ReactComponent as IconSetting } from '@icons/settings.svg'
import logoBrand from '@images/logo-market.svg'
import { Button } from 'primereact/button'

const Header = () => {
  return (
    <header className='bg-primary text-white flex justify-between fixed inset-x-0 top-0 h-14 z-10'>
      <div className='flex'>
        <div className='flex items-center bg-black py-2 px-6 border-r border-neutral-400 h-full'>
          <img alt='Havas Market' src={logoBrand} />
        </div>

        <nav className='flex items-center pl-10'>
          <a 
            className='text-white font-medium border-b-2 py-1 border-red-600' 
            href='/'
          >
            HOME
          </a>
        </nav>
      </div>

      <div className='flex items-center gap-2 pr-6 2xl:pr-8'>
        <Button
          rounded
          text
          aria-label='Settings'
          icon={<IconSetting className='size-6' />}
          severity='white'
          type='button'
        />
        <div className='relative'>
          <Button
            rounded
            text
            aria-label='Notifications'
            icon={<IconBell className='size-6' />}
            severity='white'
            type='button'
          />
          <span className='size-3 border-2 border-black rounded-full bg-havas absolute end-[0.625rem] top-[0.25rem]' />
        </div>
        <div className='size-9 bg-neutral-400 rounded-full flex items-center justify-center font-medium text-sm ml-2'>
          U
        </div>
      </div>
    </header>
  )
}

export default Header

