/* eslint-disable no-eq-null */
/* eslint-disable no-empty-pattern */
import clsx from 'clsx'

const radiobutton = {
  root: {
    className: clsx('relative inline-flex cursor-pointer select-none align-bottom', 'w-5 h-5'),
  },
  input: ({ props }) => ({
    className: clsx(
      'absolute inset-0 w-full h-full opacity-0 z-10 cursor-pointer',
      {
        'cursor-default opacity-60': props.disabled,
      }
    ),
  }),
  box: ({ props }) => ({
    className: clsx(
      'bg-white border w-5 h-5 rounded-full absolute inset-0 flex justify-center items-center',
      {
        'border-custom-shadow-gray': !props.checked,
        'border-black': props.checked,
      }
    ),
  }),
  icon: ({ props }) => ({
    className: clsx(
      'flex justify-center items-center border size-[10px] rounded-full transition duration-200 ease-in-out',
      {
        'bg-white border-white': !props.checked,
        'border-black bg-black': props.checked,
        'hover:border-blue-500 focus:outline-none': !props.disabled,
      }
    ),
    innericon: clsx(
      'w-3 h-3 bg-black rounded-full',
      {
        'backface-hidden scale-10 visible bg-black cursor-pointer': !props.checked,
        'transform scale-100 visible cursor-pointer': props.checked,
      }
    ),
  }),
}

export default radiobutton
