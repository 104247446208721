// Importa clsx para combinar clases condicionalmente
import clsx from 'clsx'

// Define las transiciones para el dropdown
const TRANSITIONS = {
  overlay: {
    classNames: {
      enter: 'opacity-0 scale-95',
      enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
      exit: 'opacity-100',
      exitActive: '!opacity-0 transition-opacity duration-150 ease-linear',
    },
  },
}

const dropdown = {
  root: ({ props }) => ({
    className: clsx(
      'items-center cursor-pointer inline-flex relative select-none',
      'transition-colors duration-200 ease-in-out rounded', // Aplica rounded de manera global
      'w-full',
      'focus:outline-none focus:shadow-none',
      { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
      {
        'border border-neutral-200 bg-white': !props.transparent && !props.invested,
        'border border-gray-800 bg-gray-800 hover:border-gray-800': !props.transparent && props.invested,
      },
      { 'h-9 xl:h-10 rounded-md px-3 py-2': !props.size, 'h-7 rounded-sm text-primary px-2 py-1': props.size === 'xsmall', 'h-8 rounded-md px-3 py-2': props.size === 'small', 'h-14 rounded-md px-3 py-4': props.size === 'large' }
    ),
  }),
  input: ({ props }) => ({
    className: clsx(
      'cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap relative grow font-light',
      'bg-transparent',
      'transition duration-200 rounded-md appearance-none font-sans',
      'focus:outline-none',
      'py-0',
      { 'text-sm 2xl:text-sm pl-0': !props.size, 'text-xs pl-0': props.size === 'small', 'text-base': props.size === 'large' },
      {
        'leading-9 xl:leading-10': !props.size && !props.floatlabel,
        'leading-8': props.size === 'small' && !props.floatlabel,
        'leading-14': props.size === 'large' && !props.floatlabel,
      },
      {
        'text-primary text-xs': props.size === 'xsmall',
        'text-primary text-sm': props.size === 'normal', // Aplica text-primary y text-sm en normal
        'text-gray-text': !props.invested,
        'text-white': props.invested,
      },
      { 'placeholder:text-transparent pt-2.5': props.floatlabel }
    ),
  }),
  trigger: ({ props }) => ({
    className: clsx(
      'flex items-center justify-center shrink-0', 'bg-transparent rounded-tr rounded-br',
      { 'size-3': !props.size || props.size === 'xsmall' || props.size === 'small', 'size-6': props.size === 'large' },
      { 'text-slate-400': !props.invested, 'text-primary': props.invested }
    ),
  }),
  panel: ({ props }) => ({
    className: clsx(
      'border rounded-md shadow-lg overflow-hidden', // Aplica rounded-md en el panel
      { 'bg-white border-gray-200 text-gray-700': !props.invested, 'bg-gray-800 border-gray-600 text-white': props.invested },
      { 'text-xs p-2 text-primary': !props.size|| props.size === 'xsmall' }
    ),
  }),
  wrapper: {
    className: clsx(
      'max-h-[12.5rem] overflow-auto',
      'scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100'
    ),
  },
  list: ({ props }) => ({
    className: clsx(
      'flex flex-col gap-y-0.5 list-none m-0',
      {
        'p-1': !props.size || props.size === 'small',
        'p-4': props.size === 'large',
      }
    ),
  }),
  item: ({ context, props }) => ({
    className: clsx(
      'cursor-pointer overflow-hidden relative whitespace-nowrap',
      'transition-colors duration-200 rounded-sm border-b border-neutral-200',
      {
        'text-primary hover:bg-gray-100': !props.invested,
        'text-white hover:bg-gray-700': props.invested,
        'bg-gray-200': context.focused && context.selected && !props.invested,
        'bg-gray-600': context.focused && context.selected && props.invested,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
      },
      {
        'py-1.5 px-2 text-sm font-light': !props.size,
        'py-1 px-2 text-xs font-light': props.size === 'small' || props.size === 'xsmall',
        'py-2 px-4': props.size === 'large',
      }
    ),
  }),
  itemgroup: ({ props }) => ({
    className: clsx(
      'm-0', 'cursor-auto',
      {
        'text-xs px-2 pt-2 pb-0.5': props.size === 'small',
        'text-sm px-4 pt-3 pb-1.5': !props.size || props.size === 'large',
      },
      {
        'text-neutral-500': !props.invested,
        'text-neutral-400': props.invested,
      }
    ),
  }),
  emptyMessage: ({ props }) => ({
    className: clsx({
      'py-1.5 px-2': !props.size,
      'py-1 px-2': props.size === 'small',
      'py-2 px-4': props.size === 'large',
    }),
  }),
  header: ({ props }) => ({
    className: clsx(
      'border-b border-gray-300', 'flex items-center justify-between gap-2',
      { 'px-3 py-2': !props.size, 'py-2 px-3': props.size === 'small', 'pl-8 pr-3 py-3': props.size === 'large' },
      { 'text-gray-700 bg-white': !props.invested, 'text-white bg-gray-800': props.invested }
    ),
  }),
  filterContainer: 'relative w-full',
  filterInput: ({ props }) => ({
    className: clsx(
      'pr-7 -mr-7 rounded',
      'w-full',
      'font-sans border appearance-none',
      'transition-colors duration-200 ease-in-out',
      'focus:outline-none focus:shadow-none',
      {
        '!text-white !border-neutral-500 !bg-gray-800': props.invested,
        'text-gray-700 border border-neutral-200 bg-neutral-100': !props.invested,
      },
      {
        '!text-sm !py-2 !pl-2 h-9 xl:!h-10': !props.size,
        '!text-xs !h-6 ': props.size === 'xsmall',
        '!text-xs !py-1 !pl-2 !h-8': props.size === 'small',
        '!text-base !py-2 !pl-4 !h-14': props.size === 'large',
      }
    ),
  }),
  filterIcon: ({ props }) => ({
    className: clsx(
      'absolute top-1/2 -mt-2 right-2.5 pointer-events-none',
      {
        'text-gray-500': !props.invested,
        'text-neutral-300': props.invested,
      }
    ),
  }),
  clearicon: ({ props }) => ({
    className: clsx(
      'text-gray-500 -mt-1.5 absolute top-1/2 focus:outline-none focus:shadow-none',
      { 'right-8 size-3': !props.size, 'right-6 size-3': props.size === 'small', 'right-10 size-6': props.size === 'large' }
    ),
  }),
  transition: TRANSITIONS.overlay,
}

export default dropdown
