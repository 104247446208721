import PropTypes from 'prop-types'

const Section = ({ title, description,children }) => {
  return (
    <div className='bg-white border border-gray-100 rounded-lg px-5 xl:px-8 pt-5 xl:pt-6 pb-6 xl:pb-8 flex flex-col'>
      <h3 className='font-medium mb-1 leading-none text-primary'>{title}</h3>
      <p className='text-sm text-gray-400 mb-1'>
        {description}
      </p>
      {children}
    </div>
  )
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Section