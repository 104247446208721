import RevenueCard from './averageMontly'
import TopBrandsCard from './topBrands'
import KeywordCoverageGauge from './topKeyword'

const OverviewResults = () => {
  const topBrandsTier1 = ['Amazon', 'Walmart', 'Aluchan', 'Carrefour', 'Eleclerc']
  const topBrandsTier2 = ['Carrefour', 'Walmart', 'Aluchan', 'Amazon', 'Eleclerc']
  const topBrandsTier3 = ['Eleclerc', 'Amazon', 'Aluchan', 'Carrefour', 'Aluchan']

  return (
    <div className='space-y-3'>
      <div>
        <h2 className='text-sm mb-2'>TIER 1</h2>
        <div className='flex flex-row gap-4'>
          <div className='flex grow gap-4'>
            <div className='w-1/2'>
              <RevenueCard
                color='#10AC94'
                currency='EUR'
                progress={85}
                title='Average Monthly Revenue'
                value='2,350,208.09'
              />
            </div>
            <div className='w-1/2'>
              <KeywordCoverageGauge organicValue={20.3} paidValue={9.1} title='Top Keyword Coverage'/>
            </div>
          </div>
          <TopBrandsCard brands={topBrandsTier1} title='Top 5 Brands'/>
        </div>
      </div>

      <div>
        <h2 className='text-sm mb-2'>TIER 2</h2>
        <div className='flex flex-row gap-4'>
          <div className='flex grow gap-4'>
            <div className='w-1/2'>
              <RevenueCard
                color='#7B61FF'
                currency='EUR'
                progress={70}
                title='Average Monthly Revenue'
                value='1,208,09'
              />
            </div>
            <div className='w-1/2'>
              <KeywordCoverageGauge organicValue={20.3} paidValue={9.1} title='Top Keyword Coverage'/>
            </div>
          </div>
          <TopBrandsCard brands={topBrandsTier2} title='Top 5 Brands'/>
        </div>
      </div>

      <div>
        <h2 className='text-sm mb-2'>TIER 3</h2>
        <div className='flex flex-row gap-4'>
          <div className='flex grow gap-4'>
            <div className='w-1/2'>
              <RevenueCard
                color='#E4C360'
                currency='EUR'
                progress={60}
                title='Average Monthly Revenue'
                value='1,462,208.09'
              />
            </div>
            <div className='w-1/2'>
              <KeywordCoverageGauge organicValue={12.3} paidValue={71} title='Top Keyword Coverage'/>
            </div>
          </div>
          <TopBrandsCard brands={topBrandsTier3} title='Top 5 Brands'/>
        </div>
      </div>
    </div>
  )
}

export default OverviewResults
