import SectionMedium from '@commons/section-medium'
import ExternalIcon from '@icons/external.svg'
import { ReactComponent as GlobeIcon } from '@icons/globe.svg'
import { ReactComponent as PowerBiIcon } from '@images/tools/powerbi.svg'
import { InputText } from 'primereact/inputtext'

import CSVUpload from './components/CSVUpload'

const RetailInsights = () => {
  return (
    <div className=' flex flex-col'>
      <form className='space-y-2 flex-grow'>
        <SectionMedium
          description='You can include all or only some of the selected platforms.'
          title='Use a CSV template to upload your data'>
    
          <div className='flex flex-col gap-4'>
            <div>
              <p className='text-xs mb-1'>Upload data from a .csv file for the selected marketplaces</p>
              <CSVUpload className='w-[5.25rem]'/>
            </div>
            <div>
              <p className='text-xs mb-1'>Or use a link to a PowerBi instead.</p>
              <div className='relative'>
                <PowerBiIcon alt='PowerBI Icon' className='absolute left-3 top-1/2 transform -translate-y-1/2 size-4'/>
                <InputText className='pl-8' placeholder='http://...' />
              </div>
            </div>
            <div>
              <div className='flex items-center gap-1 mb-2'>
                <p className='text-sm text-gray-400 '>Add external links if needed</p>
                <img alt='External Icon' className='size-4' src={ExternalIcon}/>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-2'>
                <div className='space-y-1'>
                  <p className='text-xs'>Retail Insight Url</p>
                  <div className='relative'>
                    <GlobeIcon alt='Icon' className='absolute left-3 top-1/2 transform -translate-y-1/2 size-4 text-gray-400'/>
                    <InputText className='pl-8' placeholder='http://...' />
                  </div>
                </div>
                <div className='space-y-1'>
                  <p className='text-xs'>CS Url</p>
                  <div className='relative'>
                    <GlobeIcon alt=' Icon' className='absolute left-3 top-1/2 transform -translate-y-1/2 size-4 text-gray-400'/>
                    <InputText className='pl-8' placeholder='http://...' />
                  </div>
                </div>
                <div className='space-y-1'>
                  <p className='text-xs'>Retail Analytics Url</p>
                  <div className='relative'>
                    <GlobeIcon alt='Icon' className='absolute left-3 top-1/2 transform -translate-y-1/2 size-4 text-gray-400'/>
                    <InputText className='pl-8' placeholder='http://...' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionMedium>
      </form>
    </div>
  )
}

export default RetailInsights

