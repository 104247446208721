/* eslint-disable no-eq-null */
/* eslint-disable no-empty-pattern */
import clsx from 'clsx'

const inputtextarea = {
  root: ({ context }) => ({
    className: clsx(
      'w-full h-[155px] py-3 px-5 font-sans text-sm text-gray-600 p-3 border border-custom-shadow-gray transition-colors duration-200 appearance-none rounded-lg',
      'focus:outline-none focus:outline-offset-0',
      { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled }
    ),
  }),
}


export default inputtextarea
