import { useState } from 'react'

import Row from '@commons/row'
import Section from '@commons/section'
import SectionMedium from '@commons/section-medium'
import Separator from '@commons/Separator'
import { ReactComponent as AddIcon } from '@icons/plus-square.svg'
import { ReactComponent as Uploadicon } from '@icons/upload.svg'
import { ReactComponent as CloseIcon } from '@icons/x.svg'
import { ReactComponent as AmazonIcon } from '@images/tools/amazon.svg'
import { ReactComponent as CarrefourIcon } from '@images/tools/carrefour.svg'
import { ReactComponent as Facebook } from '@images/tools/facebook.svg'
import { ReactComponent as Insta } from '@images/tools/instagram.svg'
import { ReactComponent as TargetIcon } from '@images/tools/target.svg'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'

import CSVUpload from './components/CSVUpload'
import ForecastModal from './components/ForecastModal'
import UploadModal from './components/UploadModal'

const projects = [
  { id: 1, name: 'EcoTrend_Fashion 2024', icons: [CarrefourIcon, TargetIcon], country: 'France', category: 'Skin Treatment', client: 'Loreal', brand: 'Loreal', platform: 'Amazon' },
  { id: 2, name: 'PureSkin_Revive 2025', icons: [Facebook, CarrefourIcon], country: 'Germany', category: 'Hair Care', client: 'Pantene', brand: 'Pantene', platform: 'eBay' },
  { id: 3, name: 'BeautyPlus_Makeup 2023', icons: [Facebook, TargetIcon], country: 'Spain', category: 'Makeup', client: 'NYX Cosmetic', brand: 'NYX Cosmetic', platform: 'Walmart' },
  { id: 4, name: 'Fragrance_Trend 2022', icons: [AmazonIcon, TargetIcon], country: 'United States', category: 'Perfume', client: 'Dior', brand: 'Dior', platform: 'AliExpress' },
  { id: 5, name: 'OralHealth_Care 2022', icons: [Facebook, AmazonIcon], country: 'Canada', category: 'Oral Care', client: 'Lacer', brand: 'Lacer', platform: 'Etsy' }
]

const optionTemplate = option => (
  <span className='flex items-center'>
    <span>{option.label}</span>
    <span className='flex items-center gap-2 ml-auto'>
      {option.icons.map((Icon, index) => 
        <Icon key={index} className='size-5' />
      )}
    </span>
  </span>
)

const selectedOptionTemplate = (option, props) => {
  if (option) {
    return (
      <span className='flex items-center pr-2'>
        <span>{option.label}</span>
        <span className='flex items-center gap-2 ml-auto'>
          {option.icons.map((Icon, index) => 
            <Icon key={index} className='size-5' />
          )}
        </span>
      </span>
    )
  }

  return <span>{props.placeholder}</span>
}

const Forecast = () => {
  const [selectedProject, setSelectedProject] = useState(null)
  const [completedSections, setCompletedSections] = useState(new Set())
  const [modalType, setModalType] = useState(null)
  const [visible, setVisible] = useState(false)
  const [isClosing, setIsClosing] = useState(false)

  const markSectionComplete = section => {
    setCompletedSections(new Set([...completedSections, section]))
  }

  const projectOptions = projects.map(project => ({
    label: project.name,
    icons: project.icons,
    value: project.id,
  }))

  const openModal = (e, type, isVisible) => {
    e.preventDefault()
    setModalType(type)
    setVisible(isVisible)
  }

  const handleHide = () => {
    setIsClosing(true)
    setVisible(false)
    setTimeout(() => {
      setIsClosing(false)
      setModalType(null)
    }, 200)
  }

  const renderModalContent = () => {
    if (!visible && !isClosing) return null

    return modalType === 'forecast' ? <ForecastModal /> : <UploadModal />
  }

  const isSectionCompleted = sectionName => completedSections.has(sectionName)

  return (
    <>
      <form className='space-y-2'>
        <div className='grid grid-cols-2 gap-2 xl:gap-4'>
          <Section description='Use a CSV template, you can include all or only some of the selected platforms.' title='MarketPlace & Category Revenue Benchmark'>
            <div className='flex gap-1'>
              <AmazonIcon alt='Amazon' className='size-5' />
              <TargetIcon alt='Target' className='size-5' />
            </div>
            <Separator className='mt-4 mb-9' />
            <div className='mt-auto'>
              <p className='text-xs mb-2'>Upload data from a .csv</p>
              <CSVUpload className='min-h-25'/>
            </div>
          </Section>
          <Section description='Use a CSV template, you can include all or only some of the selected platforms.' title='Social'>
            <div className='flex gap-2'>
              <Insta alt='Instagram' className='size-5' />
              <Facebook alt='Facebook' className='size-5' />
            </div>
            <Separator className='mt-4 mb-9' />
            <div className='mt-auto'>
              <p className='text-xs mb-2'>Upload data from a .csv</p>
              <CSVUpload className='min-h-25' />
            </div>
          </Section>
        </div>
        <SectionMedium description=' We will use the Marketplaces that match the project configuration.' title='Use an existing Forecast as a data source'>
          <div className='space-y-1'>
            <p className='text-xs'>Use existing Forecast</p>
            <Dropdown
              itemTemplate={optionTemplate}
              options={projectOptions}
              placeholder='Select an option'
              value={selectedProject}
              valueTemplate={selectedOptionTemplate}
              onChange={e => setSelectedProject(e.value)}
            />
            <p className='text-xs'>Only Amazon forecast data will be used in your project.</p>
          </div>
        </SectionMedium>
        <SectionMedium description='These are the platforms you have selected. It is necessary to complete all of them to continue.' title='Selected platforms'>
          <div className='space-y-4'>
            <div>
              <h3 className='text-sm mb-1 font-light'>Market Place</h3>
              <div className='space-y-2'>
                <Row buttonIcon={AddIcon} buttonLabel='Forecast Lite' completedSections={completedSections} iconComponent={AmazonIcon} isCompleted={isSectionCompleted('Forecast Section')} markSectionComplete={markSectionComplete} modalType='forecast' openModal={openModal} sectionName='Forecast Section' title='Amazon' />
                <Row buttonIcon={Uploadicon} buttonLabel='Uploaded data' completedSections={completedSections} iconComponent={TargetIcon} isCompleted={isSectionCompleted('Upload Section')} markSectionComplete={markSectionComplete} modalType='upload' openModal={openModal} sectionName='Upload Section' title='Target' />
              </div>
            </div>
            <div>
              <h3 className='text-sm mb-1 font-light'>Social Commerce</h3>
              <div className='space-y-2'>
                <Row buttonIcon={Uploadicon} buttonLabel='Uploaded data' completedSections={completedSections} iconComponent={Facebook} isCompleted={isSectionCompleted('Upload Section')} markSectionComplete={markSectionComplete} modalType='upload' openModal={openModal} sectionName='Upload Section' title='Facebook' />
                <Row buttonIcon={AddIcon} buttonLabel='Forecast Lite' completedSections={completedSections} iconComponent={Insta} isCompleted={isSectionCompleted('Forecast Section')} markSectionComplete={markSectionComplete} modalType='forecast' openModal={openModal} sectionName='Forecast Section' title='Instagram' />
              </div>
            </div>
            <div>
              <h3 className='text-sm mb-1 font-light'>Category Revenue Benchmark</h3>
              <div className='space-y-2'>
                <Row buttonIcon={Uploadicon} buttonLabel='Uploaded data' completedSections={completedSections} iconComponent={CarrefourIcon} isCompleted={isSectionCompleted('Upload Section')} markSectionComplete={markSectionComplete} modalType='upload' openModal={openModal} sectionName='Upload Section' title='Carrefour' />
              </div>
            </div>
          </div>
        </SectionMedium>
      </form>
      <Dialog
        header={
          <div className='flex justify-between items-center'>
            <div>
              <h2 className='font-medium'>
                {modalType === 'forecast' ? 'Forecast Lite' : 'Please confirm the data that you have uploaded'}
              </h2>
              <p className='text-sm text-neutral-500'>
                {modalType === 'forecast' 
                  ? 'Answerd the questions'
                  : 'It is necessary to complete all the gaps to continue'
                }
              </p>
            </div>
            <Button 
              icon={<CloseIcon className='size-5' />} 
              severity='button'
              onClick={handleHide}
            />
          </div>
        }
        style={{ width: '70vw' }}
        visible={visible}
        onHide={handleHide}
      >
        {renderModalContent()}
      </Dialog>
    </>
  )
}

export default Forecast