import { ReactComponent as AmazonIcon } from '@images/tools/amazon.svg'
import { ReactComponent as AuchanIcon } from '@images/tools/auchan.svg'
import { ReactComponent as CarrefourIcon } from '@images/tools/carrefour.svg'
import { ReactComponent as EleclercIcon } from '@images/tools/eleclerc.svg'

const RetailTable = () => {
  const data = [
    { id: 1, name: 'Amazon', performanceScore: 2.4, growthScore: 3.2, platformWeight: '10%', icon: <AmazonIcon /> },
    { id: 2, name: 'Aluchan', performanceScore: 2.4, growthScore: 3.2, platformWeight: '10%', icon: <AuchanIcon /> },
    { id: 3, name: 'Carrefour', performanceScore: 2.4, growthScore: 3.2, platformWeight: '10%', icon: <CarrefourIcon /> },
    { id: 4, name: 'Eleclerc', performanceScore: 2.4, growthScore: 3.2, platformWeight: '10%', icon: <EleclercIcon /> }
  ]

  return (
    <div className='overflow-x-auto'>
      <table className='min-w-full bg-white border-separate border-spacing-0'>
        <thead>
          <tr className='bg-header-gray text-left font-medium text-xs'>
            <th className='px-3 py-4 font-medium rounded-l-lg'>Retail</th>
            <th className='px-2 py-4 font-medium'>Current Performance Score</th>
            <th className='px-2 py-4 font-medium'>Growth Potential Score</th>
            <th className='px-3 py-4 font-medium rounded-r-lg'>Platform Weight</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={item.id}
              className={`${
                index % 2 === 0 ? 'bg-white' : 'bg-table-gray'
              }`}
            >
              <td className='px-3 py-2 rounded-l-lg'>
                <div className='flex space-x-2'>
                  <span className='size-4'>{item.icon}</span>
                  <span className='text-xs'>{item.name}</span>
                </div>
              </td>
              <td className='p-4 text-xs text-center'>{item.performanceScore}</td>
              <td className='p-4 text-xs text-center'>{item.growthScore}</td>
              <td className='p-4 text-xs rounded-r-lg'>
                <input
                  readOnly
                  className='border border-gray-300 bg-gray-input rounded px-2 py-1 w-[7.875rem] text-center'
                  type='text'
                  value={item.platformWeight}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default RetailTable
