import { ReactComponent as InfoIcon } from '@icons/infosmall.svg'
import { ReactComponent as QuestionIcon } from '@icons/question.svg'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import PropTypes from 'prop-types'

const CriteriaRow = ({
  criteria,
  answerOptions = [], 
  answerValue,
  onAnswerChange,
  performance,
  value,
  growthPotential,
  onPerformanceChange,
  onGrowthPotentialChange,
  isOdd,
  isHeader,
  valueTitle,
  performanceTitle,
  growthPotentialTitle,
  answerKey,
  description,
  isSelectOnly = false,  
  isInputOnly = false, 
  tooltipInfo,
  tooltipHelp,
}) => {

  const renderCriteria = () => {
    if (isHeader) return criteria

    return (
      <>
        {criteria}
        {(tooltipInfo || tooltipHelp) && (
          <span className='ml-1 flex items-center gap-1'>
            {tooltipInfo && (
              <Button
                rounded 
                text
                aria-label='TooltipInfo'
                className='!h-auto w-auto hover:bg-transparent'
                icon={<InfoIcon className='size-4 text-gray-icon' />}
                tooltip={tooltipInfo}
                tooltipOptions={{ position: 'top' }}
              />
            )}
            {tooltipHelp && (
              <Button 
                rounded
                text
                aria-label='Tooltip Help'
                className='!h-auto w-auto hover:bg-transparent'
                icon={<QuestionIcon className='size-4 text-gray-icon' />}
                tooltip={tooltipHelp}
                tooltipOptions={{ position: 'top' }}
              />
            )}
          </span>
        )}
      </>
    )
  }

  return (
    <div className={`flex justify-between gap-8 items-center px-3 py-2 rounded-lg ${isHeader ? 'text-sm whitespace-nowrap' : isOdd ? 'bg-gray-100' : 'bg-white'}`}>
      {/* Columna de Criterios */}
      <div className='grow min-w-0'>
        {isHeader ? criteria : (
          <>
            <div className='text-sm flex items-start leading-tight' htmlFor={answerKey}>
              {renderCriteria()}
            </div>
            {!isHeader && <div className='text-xs text-gray-500 mt-1 truncate'>{description}</div>}
          </>
        )}
      </div>

      {/* Columna de Valores */}
      <div className='flex gap-2'>
        <div className={`text-xs flex-none ${isSelectOnly ? 'w-44 xl:w-60 2xl:w-72' : 'w-[5.5rem]'}`}>
          {isHeader ? (
            <span className='text-center block'>
              {valueTitle}
            </span>
          ) : 
            isSelectOnly ? (
              <Dropdown
                className='!h-9'
                id={answerKey}
                options={answerOptions.map(option => ({ label: option, value: option }))}
                placeholder='Select option'
                value={answerValue}
                onChange={e => onAnswerChange(e.value)}
              />
            ) : isInputOnly ? (
              <InputText
                className='!h-9 text-center'
                id={`value-${answerKey}`}
                placeholder='3'
                size='small'
                type='text'
                value={value}
                onChange={e => onAnswerChange(e.target.value)}
              />
            ) : null
          }
        </div>
      
        {/* Columna de Performance */}
        <div className='text-center text-xs w-[5.5rem] flex-none'>
          {isHeader ? 
            <span>{performanceTitle}</span>
            : (
              <InputText
                className='!h-9 text-center'
                id={`performance-${answerKey}`}
                placeholder='3'
                size='small'
                type='text'
                value={`${performance} %`}
                onChange={e => onPerformanceChange(e.target.value)}
              />
            )}
        </div>

        {/* Columna de Potencial de Crecimiento */}
        <div className='text-xs text-center w-[5.5rem] flex-none'>
          {isHeader ? 
            <span>{growthPotentialTitle}</span>
            : (
              <InputText
                className='!h-9 text-center'
                id={`growth-${answerKey}`}
                placeholder='4'
                size='small'
                type='text'
                value={`${growthPotential} %`}
                onChange={e => onGrowthPotentialChange(e.target.value)}
              />
            )}
        </div>
      </div>
    </div>
  )
}

CriteriaRow.propTypes = {
  criteria: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  answerOptions: PropTypes.arrayOf(PropTypes.string),
  answerValue: PropTypes.string,
  onAnswerChange: PropTypes.func,
  performance: PropTypes.string,
  value: PropTypes.string,
  growthPotential: PropTypes.string,
  onPerformanceChange: PropTypes.func,
  onGrowthPotentialChange: PropTypes.func,
  isOdd: PropTypes.bool,
  isHeader: PropTypes.bool,
  valueTitle: PropTypes.string,
  performanceTitle: PropTypes.string,
  growthPotentialTitle: PropTypes.string,
  answerKey: PropTypes.string,
  description: PropTypes.string,
  isSelectOnly: PropTypes.bool,  
  isInputOnly: PropTypes.bool,  
  tooltipInfo: PropTypes.string,
  tooltipHelp: PropTypes.string,
}

// Exportación del componente
export default CriteriaRow
