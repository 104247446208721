import clsx from 'clsx'

const checkbox = {
  root: {
    className: clsx('cursor-pointer inline-flex relative select-none align-bottom', 'size-3'),
  },
  input: {
    className: clsx('absolute appearance-none top-0 left-0 size-full p-0 m-0 opacity-0 z-10 outline-none cursor-pointer'),
  },
  box: ({ context }) => ({
    className: clsx(
      'flex items-center justify-center',
      'border size-3 rounded-sm text-gray-600 transition-colors duration-200',
      {
        'border-gray-300 bg-[#B9B9B912]': !context.checked,
        'border-gray-300 bg-black text-white': context.checked,
      }
    ),
  }),
  icon: 'size-2 transition-all duration-200 text-white text-base dark:text-white',
}


export default checkbox
