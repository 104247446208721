import PropTypes from 'prop-types'

const BudgetCard = ({ title, percentage, change, changeText, logo, changeDirection, changeColor }) => {
  return (
    <div className='bg-gray-50 px-5 py-4 rounded-lg border border-neutral-200 w-full h-full'>
      <h2 className='text-center text-xs stretch-extra-expanded mb-4 pb-2 border-b border-black '>{title}</h2>

      <div className=' text-5xl stretch-extra-expanded font-semibold text-gray-800 mb-2'>
        {percentage}%
      </div>

      <div className='flex items-center gap-1 mb-4'>
        <span className='text-lg font-light text-black'>
          {changeDirection === 'up' ? '↑' : '↓'}
        </span>
        <span className={' text-xs font-light py-[0.125rem] px-2 rounded-full'} style={{ backgroundColor: changeColor }}>
          {change}%
        </span>
        <span className='text-xs font-light'>{changeText}</span>
      </div>

      <div className='flex bg-white border  w-24 h-7 px-3 py-[0.375rem] rounded-sm justify-end'>
        <img alt='Logo' className='w-24 h-auto' src={logo} />
      </div>
    </div>
  )
}

BudgetCard.propTypes = {
  title: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  change: PropTypes.number.isRequired,
  changeText: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  changeDirection: PropTypes.oneOf(['up', 'down']).isRequired,
  changeColor: PropTypes.string,
}

BudgetCard.defaultProps = {
  changeColor: '#10AC94',
}

export default BudgetCard
