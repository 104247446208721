import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const SpiderChart = () => {
  const options = {
    chart: {
      polar: true,
      type: 'line',
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
    },
    pane: {
      size: '80%',
    },
    xAxis: {
      categories: ['Category Potential', 'Competition Intensity', 'Retail Readiness', 'Ups Potential'],
      tickmarkPlacement: 'on',
      lineWidth: 0,
      marker: {
        enabled: false,
      },
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      max: 5,
      tickInterval: 1,
      marker: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      shared: true,
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Current performance score',
        data: [1, 0, 0.5, 1.5],
        pointPlacement: 'on',
        color: '#673ab7',
      },
      {
        name: 'Growth Potential Score',
        data: [2, 3.5, 1.8, 0.8],
        pointPlacement: 'on',
        color: '#d4af37',
      }
    ],
    credits: {
      enabled: false,
    },
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default SpiderChart
