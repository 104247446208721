import React from 'react'

import PropTypes from 'prop-types'

const Table = ({ headers, data }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='min-w-full bg-white table-auto'>
        <thead>
          <tr className='bg-header-gray'>
            {headers.map((header, index) => (
              <th 
                key={index} 
                className='py-2 pl-2 text-left text-xs font-medium min-w-[124px]'
                style={{ 
                  whiteSpace: 'normal', 
                  overflow: 'hidden',
                  textOverflow: 'ellipsis', 
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => ( 
            <React.Fragment key={rowIndex}>
              <tr
                className={`rounded-s-lg ${
                  rowIndex % 2 === 0 ? 'bg-table-gray' : 'bg-white'
                } `}
              >
                {row.map((cell, cellIndex) => (
                  <td 
                    key={cellIndex} 
                    className='py-2 pl-2 text-xs text-gray-700 min-w-[124px]'
                    style={{ 
                      whiteSpace: 'normal',
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                    }}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired, 
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]) 
    )
  ).isRequired,
}

export default Table
