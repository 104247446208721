import SectionIconSide from '@commons/section-icon'
import MenuResultsSettings from '@components/settings/layout/MenuResSet'
import DesignIcon from '@images/design.svg'
import { Link } from 'react-router-dom'

import MenuCheck from '../components/MenuCheck'


const BudgetExercise = id => {
  return (
    <div className='pb-20'>
      <MenuResultsSettings />
      <div className='container '>
        <div className='my-3'>
          <Link className='text-xs font-light hover:font-semibold' to={`/detail/${id}/results`}>Results <span className='mx-2'>/</span></Link><span className='text-xs font-medium'> Non-Media optimisation roadmap</span>
        </div>
        <SectionIconSide
          description='Identify areas for steady growth through optimisation and controlled expansion'
          icon={DesignIcon}
          title='Incremental growth potential'
        >
          <MenuCheck />
          <div className='flex flex-row'>
            <div className='flex flex-row gap-3'>
            </div>

          </div>
        </SectionIconSide>
      </div>
    </div>
  )
}

export default BudgetExercise

