import { useState } from 'react'

import CriteriaRow from '@commons/CriteriaRow'
import Separator from '@commons/Separator'
import { Button } from 'primereact/button'
import PropTypes from 'prop-types'

const UploadModal = () => {
  const [formData, setFormData] = useState({
    competitionWidth: '',
    fragmentedCompetition: '',
    thirdPartySellers: '',
    organicCompetition: '',
    paidCompetition: '',
    paid3rdPartyMarketplace: '',
    buyBoxWinRate: '',
    authorizedResellers: '',
    unauthorizedResellers: '',
    brandKeywords: '',
    productPageHacking: '',
    brandOrganicShare: '',
    brandPaidShare: '',
    imageQuality: '',
    reviewsPerASIN: '',
    reviewsAverage: '',
    pageRanking: '',
    priceRange: '',
    bestSellerRank: '',
    pageDepth: '',

    performance: {
      competitionWidth: '',
      fragmentedCompetition: '',
      thirdPartySellers: '',
      organicCompetition: '',
      paidCompetition: '',
      paid3rdPartyMarketplace: '',
      buyBoxWinRate: '',
      authorizedResellers: '',
      unauthorizedResellers: '',
      brandKeywords: '',
      productPageHacking: '',
      brandOrganicShare: '',
      brandPaidShare: '',
      imageQuality: '',
      reviewsPerASIN: '',
      reviewsAverage: '',
      pageRanking: '',
      priceRange: '',
      bestSellerRank: '',
      pageDepth: '',
    },
    growthPotential: {
      competitionWidth: '',
      fragmentedCompetition: '',
      thirdPartySellers: '',
      organicCompetition: '',
      paidCompetition: '',
      paid3rdPartyMarketplace: '',
      buyBoxWinRate: '',
      authorizedResellers: '',
      unauthorizedResellers: '',
      brandKeywords: '',
      productPageHacking: '',
      brandOrganicShare: '',
      brandPaidShare: '',
      imageQuality: '',
      reviewsPerASIN: '',
      reviewsAverage: '',
      pageRanking: '',
      priceRange: '',
      bestSellerRank: '',
      pageDepth: '',
    },
  })

  const handleInputChange = (event, key, type = 'answerValue') => {
    const { value } = event.target

    setFormData(prev => {
      if (type === 'performance') {
        return {
          ...prev,
          performance: {
            ...prev.performance,
            [key]: value,
          },
        }
      } else if (type === 'growthPotential') {
        return {
          ...prev,
          growthPotential: {
            ...prev.growthPotential,
            [key]: value,
          },
        }
      } else {
        return {
          ...prev,
          [key]: value,
        }
      }
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    console.log('Form Data Saved:', formData)
  }

  const rowsData = [
    {
      criteria: 'Competition width',
      description: 'How many brands are selling products in the brand\'s category?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.competitionWidth,
      answerKey: 'competitionWidth',
    },
    {
      criteria: 'Fragmented/Concentrated competition',
      description: 'How many brands have more than 5% organic share of voice?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.fragmentedCompetition,
      answerKey: 'fragmentedCompetition',
    },
    {
      criteria: 'Number of 3rd party sellers',
      description: 'How many 3rd party sellers are selling products in this category?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.thirdPartySellers,
      answerKey: 'thirdPartySellers',
    },
    {
      criteria: 'Organic Competition Intensity',
      description: 'Number of brands with more than 50% ORGANIC share of shelf on generic keywords',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.organicCompetition,
      answerKey: 'organicCompetition',
    },
    {
      criteria: 'Paid Competition Intensity',
      description: 'Number of brands with more than 50% PAID share of shelf on generic keywords',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.paidCompetition,
      answerKey: 'paidCompetition',
    },
    {
      criteria: 'Paid entry cost Marketplace, GENERIC',
      description: 'Average recommended minimum CPC?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.paidCompetition,
      answerKey: 'paidEntry',
    },
    {
      criteria: 'Buy box win rate',
      description: 'What is your brand\'s Buy box win rate?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.buyBoxWinRate,
      answerKey: 'buyBoxWinRate',
    }]

  const rowsData2 = [
    {
      criteria: 'Average AUTHORIZED #sellers selling our brand',
      description: 'What is the number of 3rd party sellers selling the brand\'s products?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.authorizedResellers,
      answerKey: 'authorizedResellers',
    },
    {
      criteria: 'Average UNAUTHORIZED #sellers selling our brand',
      description: 'How many non official sellers are selling your brand and products?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.unauthorizedResellers,
      answerKey: 'unauthorizedResellers',
    },
    {
      criteria: 'Brand Keywords hacking',
      description: 'How many brands bid on your brand name?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.brandKeywords,
      answerKey: 'brandKeywords',
    },
    {
      criteria: 'Brand\'s Product Detailed Page hacking',
      description: 'Number of product brand pages hacked',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.productPageHacking,
      answerKey: 'productPageHacking',
    },
    {
      criteria: 'Brand Organic Share of Shelf Marketplace',
      description: 'How is your brand\'s organic visibility when typing generic Keywords?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.brandOrganicShare,
      answerKey: 'brandOrganicShare',
    },
    {
      criteria: 'Brand Paid Share of Shelf Marketplace',
      description: 'How is your brand\'s paid visibility when typing main generic Keywords?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.brandPaidShare,
      answerKey: 'brandPaidShare',
    }
  ]
  const rowData3 = [
    {
      criteria: 'Image & text quality',
      description: 'What is the quality of text and images on the brand\'s Product Detailed Page?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.imageQuality,
      answerKey: 'imageQuality',
    },
    {
      criteria: 'Average review # per ASIN',
      description: 'What is the brand\'s number of reviews per asin in average?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.reviewsPerASIN,
      answerKey: 'reviewsPerASIN',
    },
    {
      criteria: 'Reviews average rating score',
      description: 'What is the brand\'s average rating score?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.reviewsAverage,
      answerKey: 'reviewsAverage',
    },
    {
      criteria: 'Average # of new reviews in the last 30 days (on top SKUs)',
      description: 'How many reviews were posted in the last 30 days in average per asin?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.pageRanking,
      answerKey: 'pageRanking',
    }
  ]
  const rowData4 = [
    {
      criteria: 'A+page',
      description: 'A+ page scoring',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.apage,
      answerKey: 'a+page',
    },
    {
      criteria: 'Price range',
      description: 'How competitive are your Brand\'s prices?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.priceRange,
      answerKey: 'priceRange',
    },
    {
      criteria: 'Share of best seller rank',
      description: 'How many brand\'s SKUs are in the Best sellers?',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.bestSellerRank,
      answerKey: 'bestSellerRank',
    },
    {
      criteria: 'Product range depth',
      description: 'What is the depth of the brand product range sold on the platform? (top 50 SKUs/ASINS)',
      tooltipInfo: 'This is a tooltip info',
      tooltipHelp: 'This is a tooltip help',
      answerValue: formData.pageDepth,
      answerKey: 'pageDepth',
    }
  ]

  return (
    <form>
      <Separator className='my-4' />

      <div className='overflow-y-auto max-h-80'>
        <h2 className='font-medium mb-4 text-sm'>Competition Intensity Amazon</h2>
        <div className='px-4 pb-4 pt-2 border border-gray-border rounded'>
          <CriteriaRow
            criteria='Criterias'
            growthPotentialTitle='Growth Potential'
            isHeader={true}
            isInputOnly={true}
            performanceTitle='Performance'
            valueTitle='Value' 
          />

          {rowsData.map((item, index) => (
            <CriteriaRow
              key={index}
              answerValue={formData[item.answerKey]}
              criteria={item.criteria}
              description={item.description}
              growthPotential={formData.growthPotential[item.answerKey]}
              isInputOnly={true}
              isOdd={index % 2 === 0}
              isSelectOnly={false}
              performance={formData.performance[item.answerKey]}
              tooltipHelp={item.tooltipHelp}
              tooltipInfo={item.tooltipInfo}
              onAnswerChange={e => handleInputChange(e, item.answerKey, 'answerValue')}
              onGrowthPotentialChange={e => handleInputChange(e, item.answerKey, 'growthPotential')}
              onPerformanceChange={e => handleInputChange(e, item.answerKey, 'performance')}
            />
          ))}

          <CriteriaRow
            criteria='Brand Amazon'
            growthPotentialTitle='Growth Potential'
            isHeader={true}
            isInputOnly={true}
            performanceTitle='Performance'
            valueTitle='Value' 
          />

          {rowsData2.map((item, index) => (
            <CriteriaRow
              key={index}
              answerValue={formData[item.answerKey]}
              criteria={item.criteria}
              description={item.description}
              growthPotential={formData.growthPotential[item.answerKey]}
              isInputOnly={true}
              isOdd={index % 2 === 0}
              isSelectOnly={false}
              performance={formData.performance[item.answerKey]}
              tooltipHelp={item.tooltipHelp}
              tooltipInfo={item.tooltipInfo}
              onAnswerChange={e => handleInputChange(e, item.answerKey, 'answerValue')}
              onGrowthPotentialChange={e => handleInputChange(e, item.answerKey, 'growthPotential')}
              onPerformanceChange={e => handleInputChange(e, item.answerKey, 'performance')}
            />
          ))}
        </div>

        <h3 className='text-sm text my-4 px-4 font-medium'>Retail Readiness Amazon</h3>
        <div className='m-2 p-3 border border-gray-100 rounded'>
          <CriteriaRow
            criteria='Content Amazon'
            growthPotentialTitle='Growth Potential'
            isHeader={true}
            isInputOnly={true}
            performanceTitle='Performance'
            valueTitle='Value' 
          />

          {rowData3.map((item, index) => (
            <CriteriaRow
              key={index}
              answerValue={formData[item.answerKey]}
              criteria={item.criteria}
              description={item.description}
              growthPotential={formData.growthPotential[item.answerKey]}
              isInputOnly={true}
              isOdd={index % 2 === 0}
              isSelectOnly={false}
              performance={formData.performance[item.answerKey]}
              tooltipHelp={item.tooltipHelp}
              tooltipInfo={item.tooltipInfo}
              onAnswerChange={e => handleInputChange(e, item.answerKey, 'answerValue')}
              onGrowthPotentialChange={e => handleInputChange(e, item.answerKey, 'growthPotential')}
              onPerformanceChange={e => handleInputChange(e, item.answerKey, 'performance')}
            />
          ))}

          <CriteriaRow
            criteria='Others'
            growthPotentialTitle='Growth Potential'
            isHeader={true}
            isInputOnly={true}
            performanceTitle='Performance'
            valueTitle='Value' 
          />

          {rowData4.map((item, index) => (
            <CriteriaRow
              key={index}
              answerValue={formData[item.answerKey]}
              criteria={item.criteria}
              description={item.description}
              growthPotential={formData.growthPotential[item.answerKey]}
              isInputOnly={true}
              isOdd={index % 2 === 0}
              isSelectOnly={false}
              performance={formData.performance[item.answerKey]}
              tooltipHelp={item.tooltipHelp}
              tooltipInfo={item.tooltipInfo}
              onAnswerChange={e => handleInputChange(e, item.answerKey, 'answerValue')}
              onGrowthPotentialChange={e => handleInputChange(e, item.answerKey, 'growthPotential')}
              onPerformanceChange={e => handleInputChange(e, item.answerKey, 'performance')}
            />
          ))}
        </div>
      </div>

      <div className='flex justify-end mt-4'>
        <Button
          className='bg-black text-white py-2 px-4 rounded'
          icon='pi pi-save' 
          label='Save Data'
          severity='primary'
          type='submit'
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

UploadModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default UploadModal
