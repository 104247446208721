import Separator from '@commons/Separator'
import { ReactComponent as InfoIcon } from '@icons/info.svg'
import PropTypes from 'prop-types'


const TopBrandsCard = ({ title, brands }) => {

  const getOrdinalSuffix = index => {
    const position = index + 1

    if (position === 1) return 'st'
    if (position === 2) return 'nd'
    if (position === 3) return 'rd'

    return 'th'
  }

  return (
    <div className='px-6 py-[0.625rem] bg-white rounded-lg border border-gray-200 relative flex-none w-[22.5rem]'>
      <span className='cursor-pointer absolute top-4 right-6'><InfoIcon className='size-4' /></span>
      
      <div className='flex items-center mb-4 justify-center'>
        <p className='text-sm text-gray-500 stretch-extra-expanded text-center'>{title}</p>
      </div>

      <div className='flex flex-row'>
        {brands.map((brand, index) => (
          <div key={index}>
            <div className='text-center'>
              <span
                className={`inline-block px-2 py-[0.125rem] text-xs font-light rounded-full text-gray-800 ${
                  index === 0 ? 'bg-light-green' : 
                    index === 1 ? 'bg-aqua' : 
                      index === 2 ? 'bg-light-yellow' : 
                        index === 3 ? 'bg-coral' : 
                          'bg-light-pink'
                }`}
              >
                {index + 1}{getOrdinalSuffix(index)}
              </span>
            </div>
            <Separator className='my-2' />
            <div className='items-center w-[64px] mb-2'>
              <p className='text-xs text-center'>{brand}</p>
            </div>
            <Separator />
          </div>
        ))}
      </div>
    </div>
  )
}

TopBrandsCard.propTypes = {
  title: PropTypes.string.isRequired,
  brands: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TopBrandsCard
