import SectionIconSide from '@commons/section-side-icon'
import DesignIcon from '@images/design.svg'

import OptimizationRoadmap from '../../results/components/OptimizationRoadmap'

const NonOptimisation = () => {
  return (
    <SectionIconSide
      description='Create a plan to optimise processes outside traditional media for greater efficiency.'
      icon={DesignIcon}
      title='Non-Media optimisation roadmap'
    >
      <OptimizationRoadmap showExport={true} showImport={true} />
    </SectionIconSide>
  )
}

export default NonOptimisation
