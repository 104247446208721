import { useState, useEffect } from 'react'

import CategoryPotential from './CategoryPotential'
import Forecast from './Forecast'
import NonOptimisation from './NonOptimisation'
import USPPotential from './USPPotential'

const steps = [
  { id: 1, label: 'Setup', tier: '1' },
  { id: 2, label: 'Category Potential', tier: '2' },
  { id: 3, label: 'USP Potential', tier: '3' },
  { id: 4, label: 'Non-Media optimisation roadmap', tier:'4' }
]

const StepsForecast = () => {
  const [activeStep, setActiveStep] = useState(() => {
    return localStorage.getItem('activeStep') || '1'
  })

  useEffect(() => {
    localStorage.setItem('activeStep', activeStep)
  }, [activeStep])

  const getStepClass = tier => {
    if (tier === activeStep) return 'bg-primary text-white border-primary'
    if (parseInt(tier) < parseInt(activeStep)) {
      return 'bg-custom-green text-white border-custom-green' 
    }

    return 'bg-gray-50 text-gray-menu border-gray-menu'
  }

  const getTextClass = tier => {
    if (tier === activeStep || parseInt(tier) < parseInt(activeStep)) {
      return 'text-primary font-medium' // Negro si está en verde o seleccionado
    }

    return 'text-gray-menu' // Gris si no está en verde ni seleccionado
  }

  const renderPage = () => {
    switch (activeStep) {
    case '1':
      return <Forecast />
    case '2':
      return <CategoryPotential />
    case '3':
      return <USPPotential />
    case '4':
      return <NonOptimisation/>
    default:
      return null
    }
  }

  const handleStepChange = (e, tier) => {
    e.preventDefault()
    setActiveStep(tier)
  }

  return (
    <div>
      <div className='grid grid-cols-4 gap-4 relative'>
        {/* Línea de fondo para conectar los pasos */}
        <div className='absolute top-[0.875rem] left-1/2 -translate-x-1/2 w-3/4 h-[1px] bg-gray-menu z-0' />

        {steps.map(step => (
          <div key={step.id} className='flex flex-col gap-1.5 items-center relative z-1'>
            <button
              className={`size-7 rounded-full flex items-center justify-center border text-sm ${getStepClass(step.tier)}`}
              onClick={e => handleStepChange(e, step.tier)}
            >
              {step.id}
            </button>
            <span className={`text-xs text-center leading-none ${getTextClass(step.tier)}`}>{step.label}</span>
          </div>
        ))}
      </div>
      <div className='mt-4'>
        {renderPage()}
      </div>
    </div>

  )
}

export default StepsForecast

