
import clsx from 'clsx'

const TRANSITIONS = {
  overlay: {
    classNames: {
      enter: 'opacity-0 scale-75',
      enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
      exit: 'opacity-100',
      exitActive: '!opacity-0 transition-opacity duration-150 ease-linear',
    },
  },
}

const overlaypanel = {
  root: ({ props }) => ({
    className: clsx(
      'rounded-lg !border !border-gray-100 shadow-lg min-w-56 max-w-60 max-h-[50vh]',
      'z-40 transform origin-center',
      'absolute left-0 top-0',
      { 'bg-white text-gray-700 border-0': !props.invested, 'border bg-gray-800 border-gray-600 text-white': props.invested },
      { 'text-sm': !props.size, 'text-xs': props.size === 'small', 'text-base': props.size === 'large' }
    ),
  }),
  closeButton: 'flex items-center justify-center overflow-hidden absolute top-0 right-0 w-6 h-6',
  content: ({ props }) => ({
    className: clsx({ 'bg-white p-4': !props.size, 'p-1': props.size === 'small' }),
  }),
  transition: TRANSITIONS.overlay,
}

export default overlaypanel
