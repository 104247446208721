import clsx from 'clsx'


const inputswitch = {
  root: ({ props }) => ({
    className: clsx('inline-block relative', 'w-9 h-5', {
      'opacity-60 select-none pointer-events-none cursor-default': props.disabled,
    }),
  }),
  input: {
    className: clsx('absolute appearance-none top-0 left-0 size-full p-0 m-0 opacity-0 z-10 outline-none cursor-pointer'),
  },
  slider: ({ props }) => ({
    className: clsx(
      'absolute cursor-pointer top-0 left-0 right-0 bottom-0',
      'transition-colors duration-200 rounded-2xl',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]',
      'before:absolute before:content-\'\' before:top-1/2 bg-gray-200 before:border before:w-5 before:h-5 before:left-0 before:right-0 before:-mt-2.5 before:rounded-full before:transition-duration-200',
      {
        'before:bg-white': !props.checked,
        'before:transform border-none before:translate-x-4 before:bg-[#3758F9]': props.checked,
      }
    ),
  }),
}

export default inputswitch
